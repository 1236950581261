<template>

  <div class="card p-3">
    <div class="grid">

      <!-- Titulo Oficios -->
      <div class="col-9 xl:col-3 lg:col-3 md:col-4 sm:col-7 p-2 flex justify-content-end">
        <h5 class="p-2 mt-2 tablaTitle">
          <i class="fa-solid fa-pen-nib fa-shake px-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
          Oficios para firmar
        </h5>
      </div>
      <!-- Titulo Oficios -->

      <div class="col-3 xl:col-2 lg:col-7 md:col-8 sm:col-5 flex justify-content-start">
        <dialogAyudaRecepcionOficios />
      </div>

      <div class="col-0 xl:col-7 lg:col-7 md:col-0 sm:col-0 p-2"></div>

      <!-- Tabs -->
      <div class="col-12 p-2">
        <div class="card">

          <TabViewVue class="tabview-custom tabsTablas" :activeIndex="parseInt(selectedTab)">

            <!-- Oficios para Firmar -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirmaTablaC">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmar2"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-pencil fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios para firmar</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmaC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirma" 
                  stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirma['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue 
                    v-for="col of columnasFirma" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->
                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0"
                        label="Oficio aprobado" icon="fa-solid fa-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio adjunto',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'AdjuntoFirmadoPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },
                        ]" />
                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0"
                        label="Oficio aprobado" icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El creado creado fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio creado',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'FirmarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },
                        ]" />
                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->
                      <!-- #########################################################SIN ANEXO################################################################ -->


                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1"
                        label="Oficio aprobado-" icon="fa-solid fa-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio adjunto',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'AdjuntoFirmadoPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },

                          {
                              label: 'Descargar Anexo',
                              icon: 'pi pi-download',
                              command: () => {
                                downloadAnexo(slotProps.data);
                            },
                          }
                        ]" />
                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1"
                        label="Oficio aprobado-" icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El creado creado fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio creado',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'FirmarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },

                          {
                              label: 'Descargar Anexo',
                              icon: 'pi pi-download',
                              command: () => {
                                downloadAnexo(slotProps.data);
                            },
                          }

                        ]" />
                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Oficios para Firmar -->

            <!-- Oficios Firmados -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirmadasTablaC">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmados2"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-clipboard-check fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios firmados</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmadosC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirmados" 
                  stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirmados['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue 
                    v-for="col of columnasFirma" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

               
                      <!-- Firmado Oficio Adjunto  No es Autor -->
                      <SplitButtonVue v-if="slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 1"
                        label="Oficio firmado" 
                        icon="fa-solid fa-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'Oficio adjunto firmado'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },


                        ]" />
                      <!-- Firmado Oficio Adjunto  No es Autor -->

                      <!-- Firmado Oficio Creado  No es Autor -->
                      <SplitButtonVue v-if="slotProps.data.idCaso == 1 && slotProps.data.adjuntado == 2"
                        label="Oficio firmado" 
                        icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'Oficio creado firmado'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
              
                        ]" />
                      <!-- Firmado Oficio Creado  No es Autor -->

                      <!-- Firmado Oficio Adjunto Estado 6 No es Autor Enviado -->
                      <SplitButtonVue v-if="slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 1"
                        label="Oficio firmado enviado" 
                        icon="fa-solid fa-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'Oficio adjunto firmado'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                


                        ]" />
                      <!-- Firmado Oficio Adjunto Estado 6 No es Autor Enviado -->

                      <!-- Firmado Oficio Creado Estado 6 No es Autor Enviado -->
                      <SplitButtonVue v-if="slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 2"
                        label="Oficio firmado enviado" 
                        icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'Oficio creado firmado'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
              
                        ]" />
                      <!-- Firmado Oficio Creado Estado 6 No es Autor Enviado -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Oficios Firmados -->

       
         
          </TabViewVue>

        </div>
      </div>
      <!-- Tabs -->

    </div>
  </div>

  <!-- Oficios Pendientes -->
  <dialogVisualizarPDFBuzon v-if="hasRole('VisualizarPDF')" />
  <!-- Oficios Pendientes -->
  <dialogFirmarPDF v-if="hasRole('FirmarPDF')" />
  <dialogFirmarAdjuntoPDF v-if="hasRole('AdjuntoFirmadoPDF')" />
  <dialogVisualizarAcuse v-if="hasRole('VisualizarAcuse')" />

</template>

<script>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import DataTable from "primevue/datatable";
import useOficiosRecepcion from "../composables/useOficiosRecepcion";
import useOficiosList from "../composables/useOficiosCreacion";
import dialogAyudaRecepcionOficios from "../components/oficiosLibres/dialogAyudaRecepcionOficios";
import dialogDescargarAnexo from '../composables/compDialogs/useDialogDescargarAnexo';
import Swal from "sweetalert2";
import {  useRoute } from "vue-router";

//Tabla Buzon
import useOpenDialogs from "../composables/useOpenDialogs";
//Tabla Buzon


export default {

  components: {
    //OficiosPendientes
    DataTable,
    dialogAyudaRecepcionOficios,
    dialogVisualizarPDFBuzon: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarPDF.vue")
    ),
    dialogFirmarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogFirmarPDF.vue")
    ),
    dialogFirmarAdjuntoPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosAdjuntos/dialogFirmarAdjuntoPDF.vue")
    ),
    dialogVisualizarAcuse: defineAsyncComponent(() =>
    import("../components/oficiosLibres/oficiosCreados/dialogVisualizarAcuse.vue")
   ),

    //OficiosPendientes
  },

  setup() {
    /* const route = useRoute(); */
    /* const { code } = route.query; */
    const route = useRoute();
    const selectedTab = ref(route.params.tab);


    const {
      downloadAnexo
    } = dialogDescargarAnexo();


    const {
      openDialog,
      hasRole,
      toggleDialog
    } = useOpenDialogs();

    //Tabla de para consumir
    const {
      columnasFirma,
      filtersFirma,
      filtersFirmados,
      fichasFirmaR,

      contadorOficiosFirmar2,
      contadorOficiosFirmar2Alt,
      barraOficiosFirmar2,

      contadorOficiosFirmados2,
      contadorOficiosFirmados2Alt,
      barraOficiosFirmados2,



    } = useOficiosRecepcion();

    const {
      getFichasFirmaC,
      getFichasFirmadasC,
      fichasFirmaC,
      fichasFirmadosC,
    } = useOficiosList();

    const messages = ref([]);
    const count = ref(0);
    const addMessages = () => {
      messages.value = [
        {
          icon: 'pi pi-search',
          severity: 'info',
          content: 'Dynamic Info Message',
          id: count.value++
        },
      ]
    };

    const getFichasFirmaTablaC = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios para Firmar.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasFirmaC();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosFirmar2();
      await barraOficiosFirmados2();
    }

    const getFichasFirmadasTablaC = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Firmados.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasFirmadasC();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosFirmar2();
      await barraOficiosFirmados2();
    }



    onMounted(async () => {

      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de los Oficios para Firmar.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      await getFichasFirmaC();
      await getFichasFirmadasC();
      await getFichasFirmadasTablaC();

      Swal.close();

      await barraOficiosFirmar2();
      await barraOficiosFirmados2();
    })

    return {

      columnasFirma,
      filtersFirma,
      filtersFirmados,
      fichasFirmaR,
      getFichasFirmaC,
      getFichasFirmadasC,
      fichasFirmaC,
      fichasFirmadosC,
      getFichasFirmaTablaC,
      getFichasFirmadasTablaC,
   
      addMessages,
      hasRole,
      openDialog,
      toggleDialog,

      dialogAyudaRecepcionOficios,
      downloadAnexo,

      contadorOficiosFirmar2,
      contadorOficiosFirmar2Alt,
      barraOficiosFirmar2,

      contadorOficiosFirmados2,
      contadorOficiosFirmados2Alt,
      barraOficiosFirmados2,
      selectedTab
    }
  },

}
</script>

<style scoped lang="scss">
::v-deep(.titulosPestanasContador){
  font-size: 9px;
  background-color: rgb(18, 95, 168);
  // background-color: #bec3c9;
}
.coloraux1 {
  background: #4a001f;
  font-size: 13px;
  font-weight: lighter;
}

.coloraux2 {
  background: #919191;
  font-size: 12px;
  font-weight: lighter;
}

.buttonAbrirOficio {
  background-color: #4a001f;
}

::v-deep(.cardEntrada) {
  background-color: rgb(185, 185, 185);

  .p-card-title {
    font-size: 14px;
  }

  .p-card-content {
    font-size: 12px;
  }
}

.titleaccordion {
  font-size: 13px
}

::v-deep(.columnasOcultas) {
  font-size: 1px;
  padding-bottom: 1px;
  display: block;
  color: red;

  .p-datatable-thead {
    color: red;
    display: block;
  }
}

::v-deep(.botonAyuda) {
  .p-button-icon {
    font-size: 25px;
    transition: ease 1s;
  }
}

::v-deep(.botonAyuda:hover) {
  .p-button-icon {
    font-size: 25px;
    color: rgb(77, 77, 77);
    transition: ease 1s;
  }
}


@media only screen and (max-width: 600px) {

  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 15px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 15px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 10px;
    transition: ease 1s;

    .p-column-title {
      font-size: 12px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 600px) {

  .tablaTitle {
    font-size: 18px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 85px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 18px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 18px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 768px) {

  .tablaTitle {
    font-size: 14px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 20px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 20px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 992px) {

  .tablaTitle {
    font-size: 16px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 21px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 21px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 1200px) {


  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 50px;
    width: 180px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 22px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 22px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 16px;
    transition: ease 1s;

    .p-column-title {
      font-size: 16px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 1515px) {

  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 25px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}
</style>
import { ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useTitularesLista from "./useTitularesLista";
// import { useRouter } from "vue-router";

const useDialogCrearTitulares = () => {
    const displayModal = ref(false);
    // const router = useRouter();

    const { getTitulares, getTitularesInfo } = useTitularesLista();

    const store = useStore();

    // Funcion para crear Titular
    const crearNuevoTitular = async (form) => {
        const { data: info } = await store.dispatch("admin/crearNuevoTitular", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getTitulares();
            Swal.fire("Titular Registrado Exitosamente", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al Registrar el Titular", message, "error");
        }
    };


    // Funcion para baja Usuario
    const bajaNuevoTitular = async (form) => {
        const { data: info } = await store.dispatch("admin/bajaAltaUsuario", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getTitulares();
            Swal.fire("El Usuario ha sido dado de Baja", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al dar de Baja el Usuario", message, "error");
        }
    };

    // Funcion para alta Usuario
    const altaNuevoTitular = async (form) => {
        const { data: info } = await store.dispatch("admin/bajaAltaUsuario", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getTitulares();
            Swal.fire("El Usuario ha sido dado de Baja", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al dar de Baja el Usuario", message, "error");
        }
    };

    // Funcion para Editar Usuario
    const editarTitular = async (form) => {
        const data = await store.dispatch("admin/editarUsuario", form);
        const info = data
        const { status, message, error } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getTitulares();
            Swal.fire("Se ha Editado el Correo del Usuario", message, "success");
            return true
        } else {
            if (error.data.status === "info") {
                Swal.fire("Se ha Producido un Error al Editar el Usuario", error.data.message, "error");
            } else {
                displayModal.value = false;
                Swal.fire("Se ha Producido un Error al Editar el Usuario", message, "error");
            }
            return false
        }
    };


    // Validar curp usuario
    const validarCurpTitular = async (form) => {
        const data = await store.dispatch("admin/validarCurpTitular", form);
        return data;
    };

    return {
        displayModal,
        validarCurpTitular,
        getTitulares,
        getTitularesInfo,
        crearNuevoTitular,
        editarTitular,
        bajaNuevoTitular,
        altaNuevoTitular
    };
};

export default useDialogCrearTitulares;
export const setTitulares = (state, titulareslista) => {
  state.titulareslista = titulareslista.data;
}
export const getDashboard = (state, dashboard) => {
  state.dashboard = dashboard.data;
}

export const setTitularesInfo = (state, titularesinfo) => {
  state.titularesinfo = titularesinfo.data;
}


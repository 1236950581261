export const getFirmantes = (state) => {
  return state.firmantes;
};

export const getDependenciasVBCCP = (state) => {
  return state.dependenciesVBCCP
}

export const getOficinasVBCCP = (state) => {
  return state.officesVBCCP
}

export const getFichas = (state) => {
  return state.fichas;
};
export const getFichasRecepcionPendientes = (state) => {
  return state.fichasRecepcionPendientes;
};
export const getFichasRecepcionAtencionA = (state) => {
  return state.fichasRecepcionAtencionA;
};
export const getFichasRecepcionTurnados = (state) => {
  return state.fichasRecepcionTurnados;
};
export const getFichasRecepcionArchivados = (state) => {
  return state.fichasRecepcionArchivados;
};
export const getFichasRecepcionArchivadosAtencion = (state) => {
  return state.fichasRecepcionArchivadosAtencion;
};
export const getFichasRecepcionArchivadosTurnados = (state) => {
  return state.fichasRecepcionArchivadosTurnados;
};
export const getFichasRecepcionTurnadosUsuario = (state) => {
  return state.fichasRecepcionTurnadosUsuario;
};
export const getFichasRecepcionTurnadosDestinatario = (state) => {
  return state.fichasRecepcionTurnadosDestinatario;
};
export const getFichasFirmaR = (state) => {
  return state.fichasFirmarR;
};
export const getFichasFirmadasR = (state) => {
  return state.fichasFirmadasR;
};
export const getFichasFirmaC = (state) => {
  return state.fichasFirmarC;
};
export const getFichasFirmadasC = (state) => {
  return state.fichasFirmadasC;
};
export const getVoBoByUsuario = (state) => {
  return state.VoBoyCcp;
};
export const getDependencias = (state) => {
  return state.dependencias;
};

export const getLeyenda = (state) => {
  return state.leyendas;
};

export const getComentariosVB = (state) => {
  return state.comentariosVB;
};

export const getTitulares = (state) => {
  return state.titulares;
};

export const getFuncionarios = (state) => {
  return state.funcionarios;
};

export const getFuncionariosTurnar = (state) => {
  return state.turnados;
}

import { ref, computed } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useStore } from "vuex";
import store from "@/store";
import useOficiosCreacion from "../useOficiosCreacion";
import Swal from "sweetalert2";

const useDialogVByCPP = () => {
  const storage = useStore();

  const { 
          getFichas,
          getFichasFirmadasC,
          barraOficiosNuevos,
          barraOficiosEspera,
          barraOficiosObservacion,
          barraOficiosEnviados,
          barraOficiosFirmar,
          barraOficiosFirmar3,
          barraOficiosFirmados, 
        } = useOficiosCreacion();

  const contadorOficiosVBpendientes = ref(0);
  const contadorOficiosVBpendientesAlt = ref(0);
  const barraOficiosVBpendientes = async () => {
    let arregloOficiosVBpendientes = store.state.oficialia.VoBoyCcp
    // console.log(arregloOficiosVBpendientes);

    const oficiosVBpendientesAlt = limpiarArray => limpiarArray.status == 3 && limpiarArray.statusVoBo == 1;
    const arrayLimpio = arregloOficiosVBpendientes.map(oficiosVBpendientesAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosVBpendientes.value = siHayEstatus;
    contadorOficiosVBpendientesAlt.value = noHayEstatus;

  }

  const contadorOficiosVBatendidos = ref(0);
  const contadorOficiosVBatendidosAlt = ref(0);
  const barraOficiosVBatendidos = async () => {
    let arregloOficiosVBatendidos = store.state.oficialia.VoBoyCcp
    // console.log(arregloOficiosVBpendientes);

    const oficiosVBatendidosAlt = limpiarArray => (limpiarArray.statusVoBo == 3 && limpiarArray.status == 4) || (limpiarArray.statusVoBo == 2 && limpiarArray.status == 5 ) || (limpiarArray.statusVoBo == 2 && limpiarArray.status == 6 ) || (limpiarArray.statusVoBo == 2 && limpiarArray.status == 7 ) || (limpiarArray.statusVoBo == 2 && limpiarArray.status == 10 );
    const arrayLimpio = arregloOficiosVBatendidos.map(oficiosVBatendidosAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosVBatendidos.value = siHayEstatus;
    contadorOficiosVBatendidosAlt.value = noHayEstatus;

  }

  const contadorOficiosCCP = ref(0);
  const contadorOficiosCCPAlt = ref(0);
  const barraOficiosCCP = async () => {
    let arregloOficiosCCP = store.state.oficialia.VoBoyCcp
    // console.log(arregloOficiosVBpendientes);

    const oficiosCCPAlt = limpiarArray => (limpiarArray.statusVoBo == 0 && limpiarArray.vobo_ccp == 1 && limpiarArray.status == 7) || limpiarArray.statusVoBo == 0 && limpiarArray.vobo_ccp == 2 && limpiarArray.status == 7;
    const arrayLimpio = arregloOficiosCCP.map(oficiosCCPAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosCCP.value = siHayEstatus;
    contadorOficiosCCPAlt.value = noHayEstatus;

  }


  const columns = ref([
    { field: "numeroOficio", header: "Número Oficio" },
    { field: "fechaCreacion", header: "Fecha Creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "statusVoBo", header: "statusVoBo", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    {
      field: "dependenciaFirmante",
      header: "dependenciaFirmante",
      hidden: true,
    },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "oficinaFirmante", hidden: true },
    { field: "firmante", header: "firmante", hidden: true },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    { field: "dependenciaDestinatario", header: "Dependencia que Solicita" },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    { field: "oficinaDestinatario", header: "Oficina que Solicita" },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },
    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },
    { field: "vobo_ccp", header: "Visto Bueno", hidden: true },
  ]);

  const columnsVBCPP = ref([
    { field: "id_dep", header: "IdDep", hidden: true },
    { field: "id_oficina", header: "IdOficina", hidden: true },
    { field: "titular", header: "Nombre" },
    { field: "oficina", header: "Oficina" },
    { field: "cargo", header: "Cargo" },
    { field: "dependencia", header: "Dependencia" },
    { field: "subdependencia", header: "Subdependencia" },
    { field: "vo_bo", header: "visto", hidden: true },
    { field: "accion", header: "accion", hidden: true },
  ]);

  const columnsCPP = ref([
    { field: "titular", header: "Nombre"},
    { field: "oficina", header: "Oficina"},
    { field: "cargo", header: "Cargo" },
    { field: "dependencia", header: "Dependencia" },
    { field: "subdependencia", header: "Subdependencia" },
  ]);

  const filtersTablaCCP = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    titular: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficina: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependencia: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependencia: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

  });

  const filtersVBCPP = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependencia: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependencia: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficina: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersVBPendientes = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    stringStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 3, matchMode: FilterMatchMode.EQUALS }],
    },
    statusVoBo: {
      operator: FilterOperator.OR,
      constraints: [{ value: 1, matchMode: FilterMatchMode.EQUALS }],
    },
    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersCCP = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    stringStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 7, matchMode: FilterMatchMode.EQUALS }],
    },
    statusVoBo: {
      operator: FilterOperator.OR,
      constraints: [{ value: 0, matchMode: FilterMatchMode.EQUALS }],
    },
    vobo_ccp: {
      operator: FilterOperator.OR,
      constraints: [
                    { value: 1, matchMode: FilterMatchMode.EQUALS },
                    { value: 2, matchMode: FilterMatchMode.EQUALS } ],
    },
    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersAtendidos = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    stringStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: 4, matchMode: FilterMatchMode.EQUALS },
        { value: 5, matchMode: FilterMatchMode.EQUALS },
        { value: 6, matchMode: FilterMatchMode.EQUALS },
        { value: 7, matchMode: FilterMatchMode.EQUALS },
        { value: 10, matchMode: FilterMatchMode.EQUALS },
      ],
    },

    statusVoBo: {
      operator: FilterOperator.OR,
      constraints: [
        { value: 2, matchMode: FilterMatchMode.EQUALS },
        { value: 3, matchMode: FilterMatchMode.EQUALS },
      ],
    },
    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const getVoBoyCcp = async () => {
    const resp = await storage.dispatch(
      "oficialia/getVoBoyCcp",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getEstructuraFuncionarios = async (idOficio, idOficina, idDependencia) => {                                  
    const datos = new FormData();
    datos.append("idOficio", idOficio)
    datos.append("idOficina", idOficina);
    datos.append("idDependencia", idDependencia);
    datos.append("estructura", 3);
    const { data: resp } = await store.dispatch(
      "oficialia/getEstructuraFuncionarios",
      datos
    );
    const data = resp;
    return data;
  };

  

  const changeVoBoCCP = async (form) => {
    const resp = await store.dispatch("oficialia/changeVoBoCCP", form);
    await barraOficiosVBpendientes();
    await barraOficiosVBatendidos();
    await barraOficiosCCP();
    return resp;
  };

  const obtenerVistosBuenos = async (idOficio) => {
    const resp = await store.dispatch(
      "oficialia/obtenerVistosBuenos",
      idOficio
    );
    return resp;
  };

  const obtenerConCopia = async (idOficio) => {
    const resp = await store.dispatch("oficialia/obtenerConCopia", idOficio);
    return resp;
  };

  const enviarRevisionVoBoyCCP = async (form) => {
    const { data: info } = await store.dispatch(
      "oficialia/enviarRevisionVoBoyCCP",
      form
    );
    const { status, message } = info;
    if (status === "success") {
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Oficio Enviado Correctamente", message, "success");
    } else {
      await getFichas();
      Swal.fire("Error", message, "error");
    }
  };

  const enviarOficioRecepcion = async (form) => {
    const { data: info } = await store.dispatch(
      "oficialia/enviarOficioRecepcion",
      form
    );
    const { status, message } = info;
    if (status === "success") {
      await getFichas();
      await getFichasFirmadasC();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Oficio Enviado Correctamente", message, "success");
    } else {
      await getFichas();
      await getFichasFirmadasC();
      Swal.fire("Error", message, "error");
    }
  };

  const updateVoBo = async (form) => {
    const { data: info } = await store.dispatch("oficialia/updateVoBo", form);
    const { status, message } = info;
    if (status === "success") {
      await getVoBoyCcp();
      await barraOficiosVBpendientes();
      await barraOficiosVBatendidos();
      Swal.fire("Proceso Completado", message, "success");
    } else {
      await getVoBoyCcp();
      await barraOficiosVBpendientes();
      await barraOficiosVBatendidos();
      Swal.fire("Error", message, "error");
    }
  };

  const getDependenciasVBCCP = async (form) => {
    const resp = await store.dispatch("oficialia/getDependenciasVBCCP", form);
    return resp
  }

  const getOficinasVBCCP = async (dependencia) => {
    return await store.dispatch("oficialia/getOficinasVBCCP", dependencia);
  }


  return {
    getEstructuraFuncionarios,
    getVoBoyCcp,
    VoBoyCcp: computed(() => storage.getters["oficialia/getVoBoByUsuario"]),
    funcionariosState: computed(
      () => store.getters["oficialia/getFuncionarios"]
    ),
    getDependenciasVBCCP,
    tablaVBCCP: computed(() => storage.getters["oficialia/getDependenciasVBCCP"]),

    getOficinasVBCCP,
    // tablaOFVBCCP: computed(() => storage.getters["oficialia/getOficinasVBCCP"]),

    columns,
    columnsVBCPP,
    filtersVBCPP,
    filtersVBPendientes,
    filtersCCP,
    filtersAtendidos,
    changeVoBoCCP,
    obtenerVistosBuenos,
    obtenerConCopia,
    enviarRevisionVoBoyCCP,
    enviarOficioRecepcion,
    updateVoBo,

    contadorOficiosVBpendientes,
    contadorOficiosVBpendientesAlt,
    barraOficiosVBpendientes,

    contadorOficiosVBatendidos,
    contadorOficiosVBatendidosAlt,
    barraOficiosVBatendidos,

    contadorOficiosCCP,
    contadorOficiosCCPAlt,
    barraOficiosCCP,

    columnsCPP,
    filtersTablaCCP,
  };
};
export default useDialogVByCPP;

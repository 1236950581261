import api from "@/api/laravelApi";

export const signInUser = async({ commit }, user) => {

  try {

      const { username: email, password } = user;

      const { data } = await api.post("/auth/authenticate", {

          usuario: email,
          password: password,

      });
      
      const { status,data:datos } = data;

      if (status == "error" || status == "info") return { ok: false, message: 'mensaje', status };

    
      const dataUsuario = {
        user: datos.user,
        curp:datos.curp,
        typeUser: datos.typeUser,
        oficina_id:datos.oficina_id,//admin no
        titular:datos.titular, // admin no
        dependencia_id:datos.dependencia_id,
        alias:datos.alias,
        idUsuario:datos.idUsuario,
        statusActivo:datos.statusActivo
      }
      const token = datos.token;
      commit("loginUser", { dataUsuario, token });

      return { ok: true, mensaje: "ok" , tipo: datos.typeUser};

  } catch (err) {
     
      let msg;
      if (err.response) {
          msg = err.response.data.mensaje;
      } else if (err.request) {
          msg = err.request;
      }

      if (!msg) {
          msg = err.mensaje;
      }
      return { ok: false, mensaje: msg };
  }
};

export const verifyAuth = async({ commit }) => {

  const token = localStorage.getItem("token");
    // console.log(token);
    if (!token) {
        commit("logout");
        return { ok: false, mensaje: "No hay token" };
    }else{

        commit("loginUser", { token });
        // console.log(token);
    }

  try {
    const { data } = await api.get("/users/login");
    const { status, data:datos } = data;
    // console.log( data);
      if (status != "success") {
          commit("logout");
          return {ok: false, message: 'mensaje', status };
      }

      const dataUsuario = {
        user: datos.user,
        curp:datos.curp,
        typeUser: datos.typeUser,
        oficina_id:datos.oficina_id,
        titular:datos.titular,
        dependencia_id:datos.dependencia_id,
        alias:datos.alias,
        idUsuario:datos.idUsuario,
        statusActivo:datos.statusActivo
      }

      const token = datos.token;
    //   console.log(token);
      commit("loginUser", { dataUsuario, token });
      return { ok: true, mensaje: "ok" };

  } catch (err) {
      let msg;
      if (err.response) {
          msg = err.response.data.mensaje;
      } else if (err.request) {
          msg = err.request;
      }
      if (!msg) {
          msg = err.mensaje;
      }
      return { ok: false, mensaje: msg };
  }
};


// export const loginMain = async ({ commit }, form) => {

//   try {

//     const { data } = await api.post(`/auth/authenticate`, form);

//     const { data:info, status, mensaje } = data;

//     commit("setUser", { ...info });

//     return {token:info.token, status, mensaje};

//   } catch (err) {

//     let msg;
//     if (err.response) {
//       msg = err.response.message;
//     } else if (err.request) {
//       msg = err.request;
//     } else {
//       msg = err.message;
//     }
//     return { ok: false, message: msg };
//   }
// };
export const loginUser = (state, { dataUsuario, token }) => {
  if(token){
    localStorage.setItem("token", token);
    state.token = token;
  }
  
  state.user = {...dataUsuario};
  state.status = "authenticated";
};

export const logout = (state) => {
  state.user = null;
  state.token = null;
  state.status = "not-authenticated";

  localStorage.removeItem("token");
};

export default {
  component: () => import("@/layouts/MainLayout.vue"),

  props: () => {
    
    return {
      menuItems: [

        /*
        {
          label: 'Oficios',
          icon:'fa-solid fa-file',
          items:[
            {
              label: "Creación de Oficios",
              icon: "fa-solid fa-file-contract",
              name: "oficiosoficialia",
              to: "/oficialia/",
              component: "@/modules/oficialia/views/oficialiaOficios",
            },

            {
              label: "Recepción de Oficios",
              icon: "fa-solid fa-bell-concierge",
              name: "oficiosoficialia",
              to: "/oficialia/recepcion",
            },

            {
              label: "Vistos Buenos y CCP.",
              icon: "fa-solid fa-file-circle-check",
              name: "oficiosoficialia",
              to: "/oficialia/vbccp",
            },

            {
              label: "Oficios Turnados",
              icon: "fa-solid fa-retweet",
              name: "oficiosoficialia",
              to: "/oficialia/turnados",
            },
      
            {
              label: "Oficios Archivados",
              icon: "fa-solid fa-box-archive",
              name: "oficiosoficialia",
              to: "/oficialia/archivados",
            },
          ],
        }
        */

        {
          label: "Creación de Oficios",
          icon: "fa-solid fa-file-contract",
          name: "oficiosoficialia",
          to: "/oficialia/",
          component: "@/modules/oficialia/views/oficialiaOficios",
        },

        {
          label: "Recepción de Oficios",
          icon: "fa-solid fa-bell-concierge",
          name: "oficiosoficialia",
          to: "/oficialia/recepcion",
        },

        {
          label: "Vistos Buenos y CCP.",
          icon: "fa-solid fa-file-circle-check",
          name: "oficiosoficialia",
          to: "/oficialia/vbccp",
        },

        {
          label: "Oficios Turnados",
          icon: "fa-solid fa-retweet",
          name: "oficiosoficialia",
          to: "/oficialia/turnados",
        },
  
        {
          label: "Oficios Archivados",
          icon: "fa-solid fa-box-archive",
          name: "oficiosoficialia",
          to: "/oficialia/archivados",
        },
     

        /*
        {
          label: 'Minutas',
          icon: 'fa-solid fa-people-group',
          items:[
            {
              label: 'Creación de Minutas',
              icon: 'fa-solid fa-chalkboard-user'
            }
          ]
        },
        */
      ],

    };
  },
  children: [
    {
      path: "",
      component: () => import("@/modules/oficialia/views/oficialiaMenu.vue"),
    },
    {
      path: "creacion",
      component: () => import("@/modules/oficialia/views/oficialiaOficios.vue"),
    },
    {
      path: "recepcion",
      component: () => import("@/modules/oficialia/views/oficialiaRecepcionOficios.vue"),
    },
    {
      path: "firmas",
      component: () => import("@/modules/oficialia/views/oficialiaFirmaOficios.vue"),
    },
    {
      path: "vbccp",
      component: () => import("@/modules/oficialia/views/oficialiaVBCCP.vue"),
    },
    {
      path: "turnados",
      component: () => import("@/modules/oficialia/views/oficialiaTurnados.vue"),
    },
    {
      path: "archivados",
      component: () => import("@/modules/oficialia/views/oficialiaArchivados.vue"),
    },
    
  ],
};

<template>
  <ButtonVue 
    label="Crear Ficha del Oficio" 
    icon="fa-solid fa-square-plus fa-spin"
    style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;" 
    class="botonGobColorFicha mt-2"
    @click="openModal" />

  <DialogVue 
    header="Crear Ficha del Oficio" 
    :visible="displayModal" 
    :modal="true" 
    :closable="false"
    class="flex align-items-center justify-content-center surface-0 shadow-8"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }" 
    :style="{ width: '75vw' }">

    <div class="grid mb-6">

      <!-- Divider -->
      <div class="col-12">
        <DividerVue align="center">
          <span class="p-tag coloraux1 mx-2 px-2">
            <i class="fa-solid fa-people-group pr-2"></i>
            Personal del Directorio
          </span>
        </DividerVue>
      </div>
      <!-- Divider -->

      <!-- Formularios -->
      <div class="col-12">
        <TabViewVue class="tabsTablas" ref="tabview4">
          
          <!-- Pestana para Tabla del Directorio Firmante -->
          <TabPanelVue>
            <template #header>
              <div class="grid titulosPestanas">
                <div class="col-12 text-center">
                  <i class="fa-solid fa-user-pen px-2"
                    style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                  <span>Firmante</span>
                </div>
              </div>
            </template>
            <!-- Contenido -->
            <div class="grid mb-8">
              <!-- Form para Dependencia -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">

                  <ChipVue 
                  label="Firmante:" 
                  icon="fa-solid fa-building-flag" 
                  class="chipsForm" />

                  <DropdownVue 
                    v-model="personaFirmante" 
                    :options="firmantes" 
                    optionLabel="oficina"
                    :filterFields="['oficina', 'titularOficina', 'cargo']" 
                    :filter="true" 
                    class="dropdownsResp"
                    placeholder="Selecciona un Firmante">

                    <template #value="slotProps">
                      <div class="" v-if="slotProps.value.titularOficina !== undefined">
                        <div>{{ `${slotProps.value.titularOficina}/${slotProps.value.oficina}` }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>

                    <template #option="slotProps">
                      <div>{{ `${slotProps.option.titularOficina}/${slotProps.option.oficina}` }}</div>
                    </template>

                  </DropdownVue>

                  <InlineMessageVue v-model:hidden="validaFirmante">Firmante es requerido</InlineMessageVue>

                </div>
              </div>
              <!-- Form para Dependencia -->
            </div>
            <!-- Contenido -->

          </TabPanelVue>
          <!-- Pestana para Tabla del Directorio Firmante -->

          <!-- Pestana para Tabla del Directorio Enviado a -->
          <TabPanelVue>
            <template #header>
              <div class="grid titulosPestanas">
                <div class="col-12 text-center">
                  <i class="fa-solid fa-user-tie px-2"
                    style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                  <span>Enviar a:</span>
                </div>
              </div>
            </template>

            <!-- Contenido -->
            <div class="grid mb-8">

              <!-- Form para Dependencia -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">

                  <ChipVue 
                  label="Dependencia:" 
                  icon="fa-solid fa-building-flag" 
                  class="chipsForm" />

                  <DropdownVue 
                    v-model="personaEnviaDependenciaFinal" 
                    :options="dependencias" 
                    optionLabel="nombre"
                    :filter="true" 
                    class="dropdownsResp" 
                    placeholder="Selecciona una Dependencia"
                    @change="obtenerOficinasEnviado(personaEnviaDependenciaFinal.id_dep)">

                    <template #value="slotProps">
                      <div class="" v-if="slotProps.value.nombre !== undefined">
                        <div>
                          {{ slotProps.value.nombre }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>

                    <template #option="slotProps">
                      <div class="">
                        <div>{{ slotProps.option.nombre }}</div>
                      </div>
                    </template>

                  </DropdownVue>

                  <!-- Boton para limpiar Dependencia Enviado A -->
                  <ButtonVue icon="fa-solid fa-xmark" @click="limpiarFormDependencia" severity="secondary"
                    v-tooltip.top="'Quitar Dependencia:'" />
                  <!-- Boton para limpiar Dependencia Enviado A -->

                  <InlineMessageVue v-model:hidden="valiDepenEnvia">Dependencia es requerida</InlineMessageVue>
                </div>
              </div>
              <!-- Form para Dependencia -->

              <!-- Form para Oficina -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">

                  <ChipVue 
                  label="Oficina:" 
                  icon="fa-solid fa-fax" 
                  class="chipsForm" />

                  <DropdownVue 
                    v-model="personaEnviaOficinaFinal" 
                    v-model:disabled="dropDependencia"
                    :options="oficinasSelectEnviado" 
                    optionLabel="titular" 
                    :filter="true"
                    :filterFields="['oficina', 'titular', 'cargo']" 
                    class="dropdownsResp"
                    placeholder="Selecciona una Oficina"
                    @change="obtenerSubDependenciaEnviado(personaEnviaOficinaFinal.id_oficina)">
                    <template #value="slotProps">

                      <div class="" v-if="slotProps.value.length !== 0">
                        <div>
                          {{ `${slotProps.value.titular}/${slotProps.value.oficina}` }}
                        </div>
                      </div>

                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>

                    </template>

                    <template #option="slotProps">
                      <div>{{ `${slotProps.option.titular}/${slotProps.option.oficina}` }}</div>
                    </template>

                  </DropdownVue>

                  <!-- Boton para limpiar Oficina Enviado A -->
                  <ButtonVue icon="fa-solid fa-xmark" @click="limpiarFormOficina" severity="secondary"
                    v-tooltip.top="'Quitar Oficina:'" />
                  <!-- Boton para limpiar Oficina Enviado A -->

                  <InlineMessageVue v-model:hidden="valiOfiEnvia">Oficina es requerida</InlineMessageVue>
                </div>
              </div>
              <!-- Form para Oficina -->

              <!-- Form para SubDependencia -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Subdependencia:" icon="fa-solid fa-building" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="subdependenciasEnviadoSelect.nombre" disabled />
                </div>
              </div>
              <!-- Form para SubDependencia -->

              <!-- Espacio donde aparecera el Enviado -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Enviado a:" icon="fa-solid fa-paper-plane" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="personaEnviaOficinaFinal.titular" disabled />
                </div>
              </div>
              <!-- Espacio donde aparecera el Enviado -->

              <!-- Espacio donde aparecera el Puesto -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Puesto:" icon="fa-solid fa-briefcase" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="personaEnviaOficinaFinal.cargo" disabled />
                </div>
              </div>
              <!-- Espacio donde aparecera el Puesto -->

            </div>
            <!-- Contenido -->

          </TabPanelVue>
          <!-- Pestana para Tabla del Directorio Enviado a-->

          <!-- Pestana para Tabla del Directorio Atencion a-->
          <TabPanelVue>
            <template #header>
              <div class="grid titulosPestanas">
                <div class="col-12 text-center">
                  <i class="fa-solid fa-user-tag px-2"
                    style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                  <span>Atención a (Opcional)</span>
                </div>
              </div>
            </template>

            <!-- Contenido Tabla del Directorio -->
            <div class="grid mb-8">

              <!-- Form para Dependencia -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Dependencia:" icon="fa-solid fa-building-flag" class="chipsForm" />

                  <DropdownVue 
                    v-model="personaAtencionADependenciaFinal" 
                    :options="dependencias" 
                    optionLabel="nombre"
                    :filter="true" 
                    class="dropdownsResp" 
                    placeholder="Selecciona una Dependencia"
                    @change="obtenerOficinasAtencion(personaAtencionADependenciaFinal.id_dep)">
                    <template #value="slotProps">
                      <div class="" v-if="slotProps.value.nombre !== undefined">
                        <div>
                          {{ slotProps.value.nombre }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>

                    <template #option="slotProps">
                      <div class="">
                        <div>{{ slotProps.option.nombre }}</div>
                      </div>
                    </template>
                  </DropdownVue>

                  <!-- Boton para limpiar Dependencia Atencion A -->
                  <ButtonVue icon="fa-solid fa-xmark" @click="limpiarFormDependenciaAtencion" severity="secondary"
                    v-tooltip.top="'Quitar Dependencia Atención A:'" />
                  <!-- Boton para limpiar Dependencia Atencion A -->

                </div>
              </div>
              <!-- Form para Dependencia -->

              <!-- Form para Oficina -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Oficina:" icon="fa-solid fa-fax" class="chipsForm" />
                  <DropdownVue 
                    v-model="personaAtencionAOficinaFinal" 
                    v-model:disabled="dropDependenciaAtencion"
                    :options="oficinasSelectAtencion" 
                    optionLabel="titular"
                    :filterFields="['oficina', 'titular', 'cargo']" 
                    :filter="true" 
                    placeholder="Selecciona una Oficina"
                    class="dropdownsResp"
                    @change="obtenerSubDependenciaAtencion(personaAtencionAOficinaFinal.id_oficina)">
                    <template #value="slotProps">
                      <div class="" v-if="slotProps.value.length !== 0">
                        <div>
                          {{ `${slotProps.value.titular}/${slotProps.value.oficina}` }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>

                    <template #option="slotProps">
                      <div class="">
                        <div> {{ `${slotProps.option.titular}/${slotProps.option.oficina}` }}</div>
                      </div>
                    </template>

                  </DropdownVue>

                  <!-- Boton para limpiar Oficina Atencion A -->
                  <ButtonVue icon="fa-solid fa-xmark" @click="limpiarFormOficinaAtencion" severity="secondary"
                    v-tooltip.top="'Quitar Oficina Atención A:'" />
                  <!-- Boton para limpiar Oficina Atencion A -->

                  <InlineMessageVue v-model:hidden="valiOfiAten">Oficina es requerida</InlineMessageVue>

                </div>
              </div>
              <!-- Form para Oficina -->

              <!-- Form para SubDependencia -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Subdependencia:" icon="fa-solid fa-building" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="subdependenciasAtencionSelect.nombre" disabled />

                </div>
              </div>
              <!-- Form para SubDependencia -->

              <!-- Espacio donde aparecera el Atencion -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Atención a:" icon="fa-solid fa-tag" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="personaAtencionAOficinaFinal.titular" disabled />
                </div>
              </div>
              <!-- Espacio donde aparecera el Atencion -->

              <!-- Espacio donde aparecera el Puesto -->
              <div class="col-12">
                <div class="p-inputgroup mx-1">
                  <ChipVue label="Puesto:" icon="fa-solid fa-briefcase" class="chipsForm" />
                  <InputTextV class="textoForm" type="text" v-model="personaAtencionAOficinaFinal.cargo" disabled />
                </div>
              </div>
              <!-- Espacio donde aparecera el Puesto -->

            </div>
            <!-- Contenido Tabla del Directorio -->

          </TabPanelVue>
          <!-- Pestana para Tabla del Directorio Atencion a-->

        </TabViewVue>

      </div>
      <!-- Formularios -->

      <div class="col-12 mb-3 fixed-top flex align-items-center justify-content-end">
        <ButtonVue 
          icon="fa-solid fa-xmark" 
          @click="closeModal"
          class="p-button-rounded p-button-text p-button-secondary" />
      </div>

      <div class="col-12 mb-8 fixed-bottom flex align-items-center justify-content-center">

        <InlineMessageVue 
          v-model:hidden="validaFirmante"
          class="msgValidaciones m-2"
        >
        <i 
          class="fa-solid fa-circle-exclamation fa-bounce"
          style="--fa-animation-duration: 1.5s;"
        ></i>
        <span class="px-2">Por favor selecciona un firmante para el Oficio</span>
        </InlineMessageVue>

        <InlineMessageVue 
          v-model:hidden="valiDepenEnvia"
          class="msgValidaciones m-2"
        >
        <i 
          class="fa-solid fa-circle-exclamation fa-bounce"
          style="--fa-animation-duration: 1.5s;"
        ></i>
        <span class="px-2">Por favor selecciona una dependencia para el Oficio</span>
        </InlineMessageVue>

        <InlineMessageVue 
          v-model:hidden="valiOfiEnvia"
          class="msgValidaciones m-2"
        >
        <i 
          class="fa-solid fa-circle-exclamation fa-bounce"
          style="--fa-animation-duration: 1.5s;"
        ></i>
        <span class="px-2">Por favor selecciona una oficina de la dependencia para el Oficio</span>
        </InlineMessageVue>

        <InlineMessageVue 
          v-model:hidden="valiOfiAten"
          class="msgValidaciones m-2"
        >
        <i 
          class="fa-solid fa-circle-exclamation fa-bounce"
          style="--fa-animation-duration: 1.5s;"
        ></i>
        <span class="px-2">Por favor selecciona una oficina de la dependencia en el atención para el Oficio</span>
        </InlineMessageVue>

      </div>

      <div class="col-12 mt-3 fixed-bottom flex align-items-center justify-content-center">
        <ButtonVue 
          label="Crear Ficha" 
          icon="fa-solid fa-square-plus" 
          v-model:disabled="isLoading"
          @click="crearNuevaFicha" 
          class="botonGobColorVB scalein animation-duration-1000" />
      </div>

    </div>

  </DialogVue>
</template>

<script>
import { ref, onMounted } from 'vue';
import useDialogCrearFicha from '../../composables/compDialogs/useDialogCrearFicha'
import store from '@/store';

export default {

  setup() {
    const validaFirmante = ref(true);
    const valiDepenEnvia = ref(true);
    const valiOfiEnvia = ref(true);
    const valiOfiAten = ref(true);
    const isLoading = ref(false);//Validar boton submit
    const dropDependencia = ref(true);
    const dropDependenciaAtencion = ref(true);

    //Valida que no puedan elegir una oficina antes que una dependencia
    const validarDependenciaDropEnviadoA = () => {
      if (!personaEnviaDependenciaFinal.value) {
        dropDependencia.value = true;
      } else {
        dropDependencia.value = false;
      }

    }

    //Valida que no puedan elegir una oficina antes que una dependencia
    const validarDependenciaDropAtencionA = () => {
      if (!personaAtencionADependenciaFinal.value) {
        dropDependenciaAtencion.value = true;
      } else {
        dropDependenciaAtencion.value = false;
      }
    }

    /* Funcion importada para creacion de ficha */
    const {
      crearFicha,
      dependencias,
      firmantes,
      getOficinas,
      getSubDependencia,
      getFirmantes,
      getDependencias,
      displayModal,
    } = useDialogCrearFicha();


    /* Datos Retornados de la creacion de Ficha */
    const personaFirmante = ref([]);
    const personaEnviaDependenciaFinal = ref([]);
    const personaEnviaSubDependenciaFinal = ref([]);
    const personaEnviaOficinaFinal = ref([]);
    const personaUser = ref({
      idUser: "",
      idDepUser: "",
      directorio: 1,
    });
    const personaDest = ref({
      idDepDest: "",
      idSubdepDest: "",
      idOficinaDest: "",
      idUserDest: ""
    });
    const personaAtencion = ref({
      idDepAtencion: "",
      idSubdepAtencion: "",
      idOficinaAtencion: "",
      idUserAtencion: ""
    });

    const personaAtencionADependenciaFinal = ref([]);
    const personaAtencionASubDependenciaFinal = ref([]);
    const personaAtencionAOficinaFinal = ref([]);
    const personaAtencionANombreFinal = ref([]);
    const personaAtencionAPuestoFinal = ref([]);
    /* Datos Retornados de la creacion de Ficha */

    /* Arrays de Dependencias */

    const oficinasSelectEnviado = ref([]);
    const oficinasSelectAtencion = ref([]);

    const subdependenciasEnviadoSelect = ref([]);
    const subdependenciasAtencionSelect = ref([]);


    /* Propiedades del Modal */

    const openModal = () => {
      displayModal.value = true;

    };


    //funcion para limpiar datos del formulario
    const limpiarForm = () => {
      validaFirmante.value = true;
      valiOfiEnvia.value = true;
      valiDepenEnvia.value = true;
      valiOfiAten.value = true;
      personaFirmante.value = [];
      personaEnviaDependenciaFinal.value = [];
      subdependenciasEnviadoSelect.value = [];
      personaEnviaOficinaFinal.value = [];
      personaAtencionADependenciaFinal.value = [];
      subdependenciasAtencionSelect.value = [];
      personaAtencionAOficinaFinal.value = [];
    }

    const limpiarFormOficina = () => {
      personaEnviaOficinaFinal.value = [];
      subdependenciasEnviadoSelect.value = [];
      personaEnviaOficinaFinal.value = [];
      personaEnviaOficinaFinal.value = [];
    }

    const limpiarFormDependencia = () => {
      personaEnviaDependenciaFinal.value = [];
      personaEnviaOficinaFinal.value = [];
      subdependenciasEnviadoSelect.value = [];
      personaEnviaOficinaFinal.value = [];
      personaEnviaOficinaFinal.value = [];
      dropDependencia.value = true;
    }

    const limpiarFormOficinaAtencion = () => {
      personaAtencionAOficinaFinal.value = [];
      subdependenciasAtencionSelect.value = [];
      personaAtencionAOficinaFinal.value = [];
      personaAtencionAOficinaFinal.value = [];
    }

    const limpiarFormDependenciaAtencion = () => {
      personaAtencionADependenciaFinal.value = [];
      personaAtencionAOficinaFinal.value = [];
      subdependenciasAtencionSelect.value = [];
      personaAtencionAOficinaFinal.value = [];
      personaAtencionAOficinaFinal.value = [];
      dropDependenciaAtencion.value = true;
    }


    //funcion para cerrar modal
    const closeModal = () => {
      limpiarForm();
      displayModal.value = false;
    }

    //funcion para obtener oficinas
    const obtenerOficinasEnviado = async (idDep) => {
      validarDependenciaDropEnviadoA();
      personaEnviaOficinaFinal.value.oficina = "";
      subdependenciasEnviadoSelect.value = "";
      personaEnviaOficinaFinal.value.titular = "";
      personaEnviaOficinaFinal.value.cargo = "";

      const { data } = await getOficinas(idDep);
      oficinasSelectEnviado.value = data;
    }
    const obtenerOficinasAtencion = async (idDep) => {
      validarDependenciaDropAtencionA();
      personaAtencionAOficinaFinal.value.oficina = "";
      subdependenciasAtencionSelect.value = "";
      personaAtencionAOficinaFinal.value.titular = "";
      personaAtencionAOficinaFinal.value.cargo = "";

      const { data } = await getOficinas(idDep);
      oficinasSelectAtencion.value = data;
    }
    //funcion para obtener subdependencia
    const obtenerSubDependenciaEnviado = async (idOficina) => {
      let formData = new FormData();
      formData.append("idDependencia", personaEnviaDependenciaFinal.value.id_dep);
      formData.append("idOficina", idOficina);
      const { data } = await getSubDependencia(formData);
      subdependenciasEnviadoSelect.value = data;
    }
    const obtenerSubDependenciaAtencion = async (idOficina) => {
      let formData = new FormData();
      formData.append("idDependencia", personaAtencionADependenciaFinal.value.id_dep);
      formData.append("idOficina", idOficina);
      const { data } = await getSubDependencia(formData);
      subdependenciasAtencionSelect.value = data;
    }

    //funcion para validar formulario de alta ficha
    const validaForm = () => {

      if (personaFirmante.value.id_oficina === undefined || personaFirmante.value.id_oficina === "") {
        validaFirmante.value = false;
      }
      else {
        validaFirmante.value = true;
      }

      if (personaEnviaDependenciaFinal.value.id_dep === undefined || personaEnviaDependenciaFinal.value.id_dep === "") {
        valiDepenEnvia.value = false;
        valiOfiEnvia.value = false;
      } else if (personaEnviaOficinaFinal.value.id_oficina === undefined || personaEnviaOficinaFinal.value.id_oficina === "") {
        valiDepenEnvia.value = true;
        valiOfiEnvia.value = false;
      } else {
        valiDepenEnvia.value = true;
        valiOfiEnvia.value = true;
      }

      if (personaAtencionADependenciaFinal.value.id_dep === undefined && personaAtencionAOficinaFinal.value.id_oficina === undefined || personaAtencionADependenciaFinal.value.id_dep === "" && personaAtencionAOficinaFinal.value.id_oficina === undefined) {
        valiOfiAten.value = true;
      }
      else if (personaAtencionADependenciaFinal.value.id_dep !== undefined && personaAtencionAOficinaFinal.value.id_oficina === undefined || personaAtencionADependenciaFinal.value.id_dep !== "" && personaAtencionAOficinaFinal.value.id_oficina === undefined) {
        valiOfiAten.value = false;
      }
    }

    //Validar boton submit
    const liberarBoton = () => {
      isLoading.value = false;
    }
    //Validar boton submit
    const bloquearBoton = () => {
      isLoading.value = true;
    }

    //funcion para crear ficha 
    const crearNuevaFicha = async () => {
      validaForm();

      if (valiOfiEnvia.value === true && valiDepenEnvia.value === true && validaFirmante.value === true) {
        bloquearBoton();//Validar boton
        let formData = new FormData();
        formData.append("directorio", 1);
        formData.append("idUser", store.state.auth.user.idUsuario); //Se tiene que cambiar cuando llave este completado
        formData.append("idOficinaFirmante", personaFirmante.value.id_oficina);
        formData.append("idDepDest", personaEnviaDependenciaFinal.value.id_dep);
        formData.append("idSubdepDest", subdependenciasEnviadoSelect.value.idSubdependencia);
        formData.append("idOficinaDest", personaEnviaOficinaFinal.value.id_oficina);
        formData.append("idUserDest", personaEnviaOficinaFinal.value.id_oficina);

        if (personaAtencionADependenciaFinal.value.id_dep !== undefined && personaAtencionAOficinaFinal.value.id_oficina !== undefined) {
          formData.append("idDepAtencion", personaAtencionADependenciaFinal.value.id_dep);
          formData.append("idSubdepAtencion", subdependenciasAtencionSelect.value.idSubdependencia);
          formData.append("idOficinaAtencion", personaAtencionAOficinaFinal.value.id_oficina);
          formData.append("idUserAtencion", personaAtencionAOficinaFinal.value.id_oficina);
          await crearFicha(formData);
          liberarBoton();
          limpiarForm();
        } else if (personaAtencionADependenciaFinal.value.id_dep !== undefined && personaAtencionAOficinaFinal.value.id_oficina === undefined) {
          valiOfiAten.value = false;
          liberarBoton();
        } else {
          await crearFicha(formData);
          liberarBoton();//Validar boton
          limpiarForm();
        }
      }
    }


    onMounted(async () => {
      const form = { idOficio: store.state.auth.user.oficina_id, idDependencia: store.state.auth.user.dependencia_id };
      await getFirmantes(form);
      await getDependencias();
    });


    return {
      validaFirmante, valiDepenEnvia, valiOfiEnvia, valiOfiAten,
      displayModal, openModal, closeModal,


      oficinasSelectEnviado,
      oficinasSelectAtencion,

      subdependenciasEnviadoSelect,
      subdependenciasAtencionSelect,

      personaFirmante,
      personaEnviaDependenciaFinal,
      personaEnviaSubDependenciaFinal,
      personaEnviaOficinaFinal,
      personaUser,
      personaDest,
      personaAtencion,
      personaAtencionADependenciaFinal,
      personaAtencionASubDependenciaFinal,
      personaAtencionAOficinaFinal,
      personaAtencionANombreFinal,
      personaAtencionAPuestoFinal,

      firmantes,
      dependencias,
      obtenerOficinasEnviado,
      obtenerSubDependenciaEnviado,

      obtenerOficinasAtencion,
      obtenerSubDependenciaAtencion,

      crearNuevaFicha,
      limpiarFormOficina,
      limpiarFormDependencia,
      limpiarFormOficinaAtencion,
      limpiarFormDependenciaAtencion,
      isLoading,
      bloquearBoton,
      liberarBoton,
      dropDependencia,
      validarDependenciaDropEnviadoA,
      dropDependenciaAtencion,
      validarDependenciaDropAtencionA,
    }
  }

}
</script>

<style scoped lang="scss">
.buttonDialog {
  color: gray;
}

::v-deep(.msgValidaciones) {
  .p-inline-message-icon {
    display: none;
  }
}

.p-button {
  margin: 0.3rem .5rem;
  min-width: 10rem;
}

p {
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog .p-button {
  min-width: 6rem;
}

.gobcolor1 {
  color: #ffff;
  background-color: #4a001f;
}

.gobcolor1:hover {
  color: #ffff;
  background-color: #80245f;
}


/* Clases para las Chips de Asistentes */
::v-deep(.vistobuenochip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.vistobuenochip {
  color: #53bdeb;
  background: #005c4b;
}


::v-deep(.concopiaparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.concopiaparachip {
  color: #8e9497;
  background: #005c4b;
}

::v-deep(.firmantechip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

::v-deep(.firmantechipMenu) {
  .p-chip-text {
    font-size: 13px;
  }
}

.firmantechip {
  color: yellow;
  background: #005c4b;
}

::v-deep(.enviadoparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

::v-deep(.enviadoparachipMenu) {
  .p-chip-text {
    font-size: 13px;
  }
}

.enviadoparachip {
  color: rgb(133, 255, 225);
  background: #005c4b;
}

::v-deep(.atencionparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.atencionparachip {
  color: rgb(255, 144, 80);
  background: #005c4b;
}

.fondoparachips {
  background: #0b141a;
}

/* Clases para las Chips de Asistentes */

::v-deep(.asistentestable) {
  .p-datatable {
    color: white;
    background: #0b141a;

    .p-column-title {
      color: white;
      background: #0b141a;
    }

  }
}

::v-deep(.botonasistentes) {
  .p-button-label {
    font-size: 12px;
  }
}

.botonasistentes {
  color: white;
  background: #005c4b;
}


.fondoasistentes {
  background: #202c33;
}

.fondoasistentes2 {
  background: #2a3942;
}

.datosOficioEncabezado {
  color: grey;
  font-size: 12px;
}

.datosOficioEncabezadoContenido {
  color: black;
  font-size: 15px;
}

.datosOficioEncabezadoContenido2 {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.datosOficioEncabezadoContenido3 {
  color: black;
  font-size: 12px;
}

.coloraux1 {
  background: rgb(209, 165, 19);
  font-weight: lighter;
}

.coloraux3 {
  background: rgb(90, 90, 90);
  font-weight: lighter;
}


// Estilos del formulario
::v-deep(.inputGenerales) {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
}

::v-deep(.dropDownVistas) {
  .p-dropdown-label {
    color: black;
    font-size: 13px;
    font-weight: bold;
  }

  .p-dropdown-trigger {
    display: none;
  }
}

.leyendaDocumento {
  color: black;
  opacity: 0.9;
  font-size: 25px;
}

::v-deep(.inputGeneralesCalendar) {
  .p-inputtext {
    color: black;
    font-size: 13px;
    font-weight: bold;
  }
}

::v-deep(.editorCuerpo) {
  .p-editor-content {
    background: rgba(0, 0, 0, 0.5);
    color: black;
    font-size: 18px;
  }
}

//Responsive



@media only screen and (max-width: 600px) {

  .coloraux1 {
    font-size: 8px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }

  ::v-deep(.dropdownsResp) {
    .p-dropdown {
      font-size: 11px !important;
    }

    .p-dropdown-label {
      font-size: 11px !important;
    }
  }

  ::v-deep(.chipsForm) {
    .p-chip-text {
      font-size: 11px !important;
    }
  }

  ::v-deep(.textoForm) {
    font-size: 11px !important;

    .p-inputtext {
      font-size: 11px !important;
    }
  }

}

@media only screen and (min-width: 600px) {

  .coloraux1 {
    font-size: 8px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 768px) {

  .coloraux1 {
    font-size: 10px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 992px) {

  .coloraux1 {
    font-size: 12px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1200px) {

  .coloraux1 {
    font-size: 13px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1515px) {

  .coloraux1 {
    font-size: 15px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

}


/*
@media only screen and (max-width: 600px) {


}

@media only screen and (min-width: 600px) {


}

@media only screen and (min-width: 768px) {


}

@media only screen and (min-width: 992px) {


}

@media only screen and (min-width: 1200px) {


}

@media only screen and (min-width: 1515px) {


}
*/
</style>
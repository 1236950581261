import {ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useProyectosConsejo from "../useOficiosCreacion";
import useOficiosRecepcion from "../useOficiosRecepcion";
import useDialogVByCPP from './useDialogVByCCP';


const useDialogOficiosAdjuntos = () => {
  const displayModal = ref(false);

  const { getFichas,
          barraOficiosNuevos,
          barraOficiosEspera,
          barraOficiosObservacion,
          barraOficiosEnviados,
          barraOficiosFirmar,
          barraOficiosFirmar3,
          barraOficiosFirmados,
        } = useProyectosConsejo();

 const {
    barraOficiosPendientes,
    barraOficiosAtencion,
  } = useOficiosRecepcion();

  const {
      barraOficiosCCP
  } = useDialogVByCPP();

  const store = useStore();
 
  const adjuntarOficio = async (form) => {
    const { data: info } = await store.dispatch("oficialia/adjuntarOficio", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Archivo Guardado Correctamente", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error", message, "error");
    }
  };
 
  const adjuntarOficioModificado = async (form) => {
    const { data: info } = await store.dispatch("oficialia/adjuntarOficioModificado", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Archivo Guardado Correctamente", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error", message, "error");
    }
  };
  const contestarAdjunto = async (form) => {
    const { data: info } = await store.dispatch("oficialia/contestarAdjunto", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
      Swal.fire("Oficio en proceso de contestación, vaya a bandeja de creación de oficios para continuar.", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error", message, "error");
    }
  }

  const adjuntarAnexos = async (form) => {
    const { data: info } = await store.dispatch("oficialia/adjuntarAnexos", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
      Swal.fire("Operación realizada correctamente", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error al realizar la operación", message, "error");
    }
  };

  const descargarArchivoAnexo = async (form) => {
    const info  = await store.dispatch("oficialia/descargarArchivoAnexo", form);
    const { status, data } = info;
    if (status === 200){
      
    return data;
    } else {    
     
    return data;
    }       
};

  return {
    adjuntarOficio,
    adjuntarOficioModificado,
    contestarAdjunto,
    adjuntarAnexos,
    descargarArchivoAnexo,
    displayModal,
  };
};

export default useDialogOficiosAdjuntos;

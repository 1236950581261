<template>

  <ButtonVue 
    icon="fa-solid fa-circle-info fa-bounce"
    style="--fa-animation-iteration-count: 5; --fa-animation-duration: 2.5s;"
    v-tooltip.top="'Ayuda para Archivar los Oficios'"   
    class="p-button-rounded p-button-secondary p-button-text botonAyuda"
    @click="openModal" 
  />

  <DialogVue 
    header="Pasos para archivar oficios" 
    v-model:visible="displayModal" 
    :modal="true" 
    :closable="true"
    class="surface-0 shadow-8"
    :breakpoints="{ '1560px': '75vw', '640px': '90vw' }" 
    :style="{ width: '50vw' }">

    <div class="grid">

    <!-- Tablas -->
    <div class="col-12">
      <TabViewVue
        class="tabview-custom tabsTablas" 
        ref="tabview4"
      >

<!-- Tabla de Archivados -->
<TabPanelVue>
  <template #header>
    <div class="grid titulosPestanas">
      <div class="col-12 text-center">
        <i 
        class="fa-solid fa-folder-open fa-bounce mr-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        <span>Oficios archivados</span>
      </div>
    </div>
  </template>

  <div class="grid">

    <!-- Paso 1 -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
      <DividerVue align="center">
        <span class="pr-2">Oficio para archivar</span>
      </DividerVue>
      
      <ButtonVue
        label="Oficio recibido"
        icon="fa-solid fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio recibido"
        icon="fa-regular fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Dependiendo del estado del oficio, se podrá archivar seleccionando la opción de archivar.
      </p>

    </div>
    <!-- Paso 1 -->

    <!-- Paso Adicional -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso opcional:</span>
      </DividerVue>
      
      <ButtonVue
        label="Oficio archivado"
        icon="fa-solid fa-file fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado15 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio archivado"
        icon="fa-regular fa-file fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado15 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Los oficios archivados se mostrarán en la tabla, se podrán visualizar, pero tambien desarchivar para retomar el flujo del oficio.
      </p>

    </div>
    <!-- Paso Adicional -->
  
  </div>

</TabPanelVue>
<!-- Tabla de Archivados -->

<!-- Tabla de Desarchivados -->
<TabPanelVue>
  <template #header>
    <div class="grid titulosPestanas">
      <div class="col-12 text-center">
        <i 
        class="fa-regular fa-folder-open fa-bounce mr-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        <span>Oficios desarchivados</span>
      </div>
    </div>
  </template>

<div class="grid">

<!-- Paso 1 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Oficio archivado"
        icon="fa-solid fa-file fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado15 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio archivado"
        icon="fa-regular fa-file fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado15 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        En la tabla de oficios Archivados, seleccionamos la opción de desarchivar el oficio y ahora aparecerá en la tabla de oficios desarchivados
      </p>

</div>
<!-- Paso 1 -->

<!-- Paso 2 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Oficio archivado"
    icon="fa-solid fa-folder fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstadoDesarchivado m-2 w-full" 
  />

  <ButtonVue
    label="Oficio archivado"
    icon="fa-regular fa-folder fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstadoDesarchivado m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Se tendrá un registro de los oficios que se desarchivaron y ahora aparecerá en su lugar habitual, se podra visualizar de igual manera.
  </p>

</div>
<!-- Paso 2 -->

<!-- Paso Adicional -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Oficio recibido"
        icon="fa-solid fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio recibido"
        icon="fa-regular fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Ahora el Oficio esta en el lugar habitual donde estaba antes de que se archivara
      </p>

</div>
<!-- Paso Adicional -->

</div>

</TabPanelVue>
<!-- Tabla de Desarchivados -->
      
      </TabViewVue>
    </div>
    <!-- Tablas -->
    </div>

  </DialogVue>

</template>

<script>
import { ref } from 'vue';
export default {

  setup(){
    const displayModal = ref(false);

    const openModal = () => {
        displayModal.value = true;
    };

    const closeModal = () => {
        displayModal.value = false;
    };

  

    return {
      displayModal,
      openModal,
      closeModal,
    }
  }

  
}

</script>

<style scoped lang="scss">

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:13px;
    }
  }
.coloraux1 {
  background: rgb(209, 165, 19);
  font-weight: lighter;
}

::v-deep(.botonGobColor){ 
  background-color:#4a001f;
  border-color: #80245f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColor:hover){ 
  background-color:#80245f;
  border-color: #4a001f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar){ 
  background-color:#6d807f;
  border-color: #454b4b;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar:hover){ 
  background-color:#454b4b;
  border-color: #6d807f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.pasoNumber1){
  background: #6c757d;
}

::v-deep(.textHelp){
  font-size:12px;
}


//Split Button para los Estados
::v-deep(.splitRecepcionEstado7){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #7E51F5 !important;
    border-color: #7E51F5 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #5b3caf!important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado15){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado15:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

@media only screen and (max-width: 600px){
  .titulosPestanas{
  height: 55px;
  width: 93px;
  transition: ease 1s;
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:10.5px;
      transition: ease 1s;
    }
}
}


</style>
<template>
  <footer
    class="text-center footergob fixed-bottom navlogogob"
    style="background-color: #80245f;"
  >
    <div class="container p-1">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="pb-2 text-white">
            <p class="footercopy">
              &copy;
              <span>{{ year }}</span>
              Gobierno Digital | Sistema de Correspondencia V.1.0
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

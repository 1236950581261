<template>

  <div class="card p-3">
    <div class="grid">

      <!-- Titulo Oficios -->
      <div class="col-9 xl:col-3 lg:col-6 md:col-6 sm:col-7 p-2 flex justify-content-end">
        <h5 class="p-2 mt-2 tablaTitle">
          <i class="fa-solid fa-file-circle-check fa-shake px-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
          Vistos buenos
        </h5>
      </div>
      <!-- Titulo Oficios -->

      <div class="col-3 xl:col-2 lg:col-6 md:col-6 sm:col-5 flex justify-content-start">
        <dialogAyudaVBCCPOficios />
      </div>

      <div class="col-0 xl:col-7 lg:col-0 md:col-0 sm:col-0 p-2"></div>

      <!-- Tabs -->
      <div class="col-12 p-2">
        <div class="card">

          <TabViewVue class="tabview-custom tabsTablas" :activeIndex="parseInt(selectedTab)">

            <!-- Tabla de VB -->
            <TabPanelVue>
              <template #header>

                <div class="grid titulosPestanas" @click="getFichasVBPendientes">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosVBpendientes"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-check-double fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Vistos buenos pendientes</span>
                  </div>
                </div>

              </template>

              <div class="card p-1">
                <DataTable 
                  :value="VoBoyCcp" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersVBPendientes" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersVBPendientes['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue v-for="col of columns" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="width: 18rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->
                      <!-- Oficios para Visto Bueno (Adjuntado) Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 1 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0"
                        icon="fa-solid fa-circle-question fa-shake" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado3 w-full" label="Requiere visto bueno"
                        v-tooltip.top="'Oficio adjunto que requiere visto bueno'" :model="[
                          {
                            label: 'Ver oficio para visto bueno adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialogMOficio(
                                'VerDatosOficio',
                                slotProps.data,
                              );
                            },
                          },
                      
                        ]" />
                      <!-- Oficios para Visto Bueno (Adjuntado) Estado 3 -->

                      <!-- Oficios para Visto Bueno (Creado) Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 1 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0"
                        icon="fa-regular fa-circle-question fa-shake" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado3 w-full" label="Requiere visto bueno"
                        v-tooltip.top="'Oficio que requiere visto bueno'" :model="[
                        
                          {
                            label: 'Ver oficio para visto bueno',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialogMOficio(
                                'VerDatosOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios para Visto Bueno (Creado) Estado 3 -->
                      <!-- #########################################################SIN ANEXO################################################################ -->

                      
                      <!-- Oficios para Visto Bueno (Adjuntado) Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 1 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1"
                        icon="fa-solid fa-circle-question fa-shake" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado3 w-full" label="Requiere visto bueno-"
                        v-tooltip.top="'Oficio adjunto que requiere visto bueno'" :model="[
                          {
                            label: 'Ver oficio para visto bueno adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialogMOficio(
                                'VerDatosOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                            {
                              label: 'Descargar Anexo',
                              icon: 'pi pi-download',
                              command: () => {
                                openDialog
                                downloadAnexo(slotProps.data);
                            },
                          }
                        
                        ]" />
                      <!-- Oficios para Visto Bueno (Adjuntado) Estado 3 -->

                      <!-- Oficios para Visto Bueno (Creado) Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 1 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1"
                        icon="fa-regular fa-circle-question fa-shake" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado3 w-full" label="Requiere visto bueno-"
                        v-tooltip.top="'Oficio que requiere visto bueno'" :model="[
                        
                          {
                            label: 'Ver oficio para visto bueno',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialogMOficio(
                                'VerDatosOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(
                                slotProps.data
                              );
                            },
                          }
                        
                        
                        ]" />
                      <!-- Oficios para Visto Bueno (Creado) Estado 3 -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de VB -->

            <!-- Tabla de Atendidos -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasVBAtendidos">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosVBatendidos"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-file-import fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios atendidos</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="VoBoyCcp" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersAtendidos" 
                  stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersAtendidos['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue v-for="col of columns" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="width: 18rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Visto Bueno Rechazado Oficio Adjunto Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 3 && slotProps.data.status == 4 && slotProps.data.adjuntado == 1"
                        label="Visto bueno rechazado" icon="fa-solid fa-rectangle-xmark fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado4 w-full"
                        v-tooltip.top="'El oficio adjunto requiere de una revisión'" :model="[
                        
                          {
                            label: 'Ver oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Rechazado Oficio Adjunto Estado 3 -->

                      <!-- Visto Bueno Rechazado Oficio Creado Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 3 && slotProps.data.status == 4 && slotProps.data.adjuntado == 2"
                        label="Visto bueno rechazado" icon="fa-regular fa-rectangle-xmark fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado4 w-full"
                        v-tooltip.top="'El oficio creado está siendo aprobado por los participantes'" :model="[
                        
                          {
                            label: 'Ver oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Rechazado Oficio Creado Estado 3 -->

                      <!-- Visto Bueno Oficio Adjunto Estado 2 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 5 && slotProps.data.adjuntado == 1"
                        label="Visto bueno aprobado" icon="fa-solid fa-circle-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP. está Listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Adjunto Estado 2 -->

                      <!-- Visto Bueno Oficio Creado Estado 2 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 5 && slotProps.data.adjuntado == 2"
                        label="Visto bueno aprobado" icon="fa-regular fa-circle-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado5 w-full"
                        v-tooltip.top="'El oficio creado fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Creado Estado 2 -->

                      <!-- Visto Bueno Oficio Adjunto Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 6 && slotProps.data.adjuntado == 1"
                        label="Oficio adjunto con V.B. firmado" 
                        icon="fa-solid fa-file-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP, y firmado'" :model="[
                          {
                            label: 'Visualizar oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Adjunto Estado 6 -->

                      <!-- Visto Bueno Oficio Creado Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 6 && slotProps.data.adjuntado == 2"
                        label="Oficio creado con V.B. firmado" 
                        icon="fa-solid fa-file-signature fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio creado fue aprobado por los VB. y CCP, y fue firmado'" :model="[
                          {
                            label: 'Visualizar oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Creado Estado 6 -->

                      <!-- Visto Bueno Oficio Adjunto Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 7 && slotProps.data.adjuntado == 1"
                        label="Oficio con visto bueno enviado" 
                        icon="fa-solid fa-calendar-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP ha sido enviado'" :model="[
                          {
                            label: 'Visualizar oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Adjunto Estado 7 -->

                      <!-- Visto Bueno Oficio Creado Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 7 && slotProps.data.adjuntado == 2"
                        label="Oficio con visto bueno enviado" 
                        icon="fa-regular fa-calendar-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio creado fue enviado'" :model="[
                          {
                            label: 'Visualizar oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Creado Estado 7 -->

                       <!-- Visto Bueno Oficio Adjunto Estado 10 -->
                       <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 10 && slotProps.data.adjuntado == 1"
                        label="Oficio con visto bueno turnado" 
                        icon="fa-solid fa-calendar-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio adjunto fue enviado'" :model="[
                          {
                            label: 'Visualizar oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Adjunto Estado 10 -->

                      <!-- Visto Bueno Oficio Creado Estado 10 -->
                      <SplitButtonVue v-if="slotProps.data.statusVoBo == 2 && slotProps.data.status == 10 && slotProps.data.adjuntado == 2"
                        label="Oficio con visto bueno turnado" 
                        icon="fa-regular fa-calendar-check fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        v-tooltip.top="'El oficio creado ha sido enviado'" :model="[
                          {
                            label: 'Visualizar oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Visto Bueno Oficio Creado Estado 10 -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Atendidos -->


          </TabViewVue>
          
        </div>
      </div>
      <!-- Tabs -->

    </div>
  </div>

  <dialogVisualizarPDFBuzon v-if="hasRole('VisualizarPDF')" />
  <dialogVisualizarAcuse v-if="hasRole('VisualizarAcuse')" />
  <!-- Oficios VB y CCP -->
  <dialogVerDatosOficio v-if="hasRole('VerDatosOficio')" />
  <!-- Oficios VB y CCP -->

</template>

<script>
import { onMounted, defineAsyncComponent, ref } from 'vue';
import DataTable from "primevue/datatable";
import dialogAyudaVBCCPOficios from "../components/oficiosLibres/dialogAyudaVBCCPOficios";
import useDialogVByCPP from '../composables/compDialogs/useDialogVByCCP';
import dialogDescargarAnexo from '../composables/compDialogs/useDialogDescargarAnexo';
import Swal from "sweetalert2";
import { useRoute } from "vue-router";

//Tabla Buzon
import useOpenDialogs from "../composables/useOpenDialogs";
//Tabla Buzon


export default {

  components: {

    //OficiosPendientes
    DataTable,
    dialogAyudaVBCCPOficios,
    dialogVisualizarPDFBuzon: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarPDF.vue")
    ),
    dialogVisualizarAcuse: defineAsyncComponent(() =>
    import("../components/oficiosLibres/oficiosCreados/dialogVisualizarAcuse.vue")
    ),
    //Pendientes
    dialogVerDatosOficio: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosVistosBuenos/dialogVerDatosOficio")
    ),
    //Pendientes

  },

  setup() {

    const route = useRoute();
    const selectedTab = ref(route.params.tab);
   

    const {
      downloadAnexo
    } = dialogDescargarAnexo();

    const {
      openDialog,
      openDialogContestar,
      openDialogMOficio,
      hasRole,
      toggleDialog
    } = useOpenDialogs();

    //Tabla de para consumir
    const {
      columns,
      filtersVBPendientes,

      filtersAtendidos,
      VoBoyCcp,
      getVoBoyCcp,

      contadorOficiosVBpendientes,
      contadorOficiosVBpendientesAlt,
      barraOficiosVBpendientes,

      contadorOficiosVBatendidos,
      contadorOficiosVBatendidosAlt,
      barraOficiosVBatendidos,

    } = useDialogVByCPP();

    const getFichasVBPendientes = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Vistos Buenos Pendientes.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getVoBoyCcp();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosVBpendientes();
      await barraOficiosVBatendidos();
    }


    const getFichasVBAtendidos = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Vistos Buenos Atendidos.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getVoBoyCcp();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosVBpendientes();
      await barraOficiosVBatendidos();
    }


    onMounted(async () => {

      new Swal({
        title: "Cargando...",
        text: "Actualizando Información del Directorio de Vistos Buenos y Con Copia Para",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getVoBoyCcp();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await getVoBoyCcp();
      await barraOficiosVBpendientes();
      await barraOficiosVBatendidos();
    })

    return {
      VoBoyCcp,

      hasRole,
      openDialog,
      openDialogContestar,
      openDialogMOficio,
      toggleDialog,
      dialogAyudaVBCCPOficios,
      columns,
      filtersVBPendientes,

      filtersAtendidos,
      getFichasVBPendientes,
    
      getFichasVBAtendidos,
      downloadAnexo,

      contadorOficiosVBpendientes,
      contadorOficiosVBpendientesAlt,
      barraOficiosVBpendientes,

      contadorOficiosVBatendidos,
      contadorOficiosVBatendidosAlt,
      barraOficiosVBatendidos,
      selectedTab,
    }
  },

}
</script>

<style scoped lang="scss">

::v-deep(.titulosPestanasContador){
  font-size: 9px;
  background-color: rgb(18, 95, 168);
  // background-color: #bec3c9;
}

.coloraux1 {
  background: #4a001f;
  font-size: 13px;
  font-weight: lighter;
}

.coloraux2 {
  background: #919191;
  font-size: 12px;
  font-weight: lighter;
}

.buttonAbrirOficio {
  background-color: #4a001f;
}

::v-deep(.cardEntrada) {
  background-color: rgb(185, 185, 185);

  .p-card-title {
    font-size: 14px;
  }

  .p-card-content {
    font-size: 12px;
  }
}

::v-deep(.tablaOficiosData) {
  font-size: 13px;

  .p-column-title {
    font-size: 13px
  }
}

::v-deep(.tabsTablas) {
  .p-tabview-nav {
    font-size: 13px;
  }
}

.titleaccordion {
  font-size: 13px
}


::v-deep(.columnasOcultas) {
  font-size: 1px;
  padding-bottom: 1px;
  display: block;
  color: red;

  .p-datatable-thead {
    color: red;
    display: block;
  }
}

::v-deep(.botonAyuda) {
  .p-button-icon {
    font-size: 25px;
    transition: ease 1s;
  }
}

::v-deep(.botonAyuda:hover) {
  .p-button-icon {
    font-size: 25px;
    color: rgb(77, 77, 77);
    transition: ease 1s;
  }



}

@media only screen and (max-width: 600px) {
  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 80px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 15px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 15px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 10px;
    transition: ease 1s;

    .p-column-title {
      font-size: 12px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }


  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 600px) {

  .tablaTitle {
    font-size: 18px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 18px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 18px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }


  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 768px) {
  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 20px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 20px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;
      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 992px) {
  .tablaTitle {
    font-size: 16px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 21px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 21px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1200px) {
  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 50px;
    width: 180px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 22px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 22px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 16px;
    transition: ease 1s;

    .p-column-title {
      font-size: 16px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1515px) {

  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 30px;
    width: 225px;
    transition: ease 1s;
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 25px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado16) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6d6d6d !important;
      border-color: #6d6d6d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado16:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #333333 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado3) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #505aaa !important;
      border-color: #505aaa !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado3:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #333a6d !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado4) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado4:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #369609 !important;
      border-color: #369609 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}
</style>
import { createStore } from "vuex";

import auth from "../modules/auth/store";
import oficialia from "../modules/oficialia/store";
import usuarios from "../modules/usuario/store"
import admin from "../modules/admin/store"
// import VuexPersistence from "vuex-persist";
// import createPersistedState from "vuex-persistedstate"

const store = createStore({
  modules: {
    auth,
    oficialia,
    usuarios, 
    admin
  },
  // plugins: [createPersistedState()]
  // plugins: [
  //   new VuexPersistence({
  //     storage: window.localStorage
  //   }).plugin
  // ]
});

export default store;

<template>

  <div class="grid my-4">
    
    <div class="col-12 text-center scalein animation-duration-1000">
      <h2 class="m-3">
        <i 
        class="fa-solid fa-inbox fa-shake px-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        Bienvenido al Buzón de Correspondencia
      </h2>
    </div>

    <!-- Link de Creacion de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000">
          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.8 96.35" class="" >
            <g >
              <g>
                <path class="Mcls-2" d="m60.55,83.26H3.78V3.78h26.49v30.28h30.28v49.2ZM34.06,5.11l25.17,25.17h-25.17V5.11Zm30.28,25.17L34.06,0H0v87.04h64.34V30.28Z"/>
                <circle class="Mcls-1" cx="55.67" cy="77.35" r="18"/>
                <path class="Mcls-3" d="m56.56,67.69v8.68h8.68v1.74h-8.68v8.68h-1.74v-8.68h-8.68v-1.74h8.68v-8.68h1.74Zm-.87-7.82c9.55,0,17.37,7.82,17.37,17.37s-7.82,17.37-17.37,17.37-17.37-7.82-17.37-17.37,7.82-17.37,17.37-17.37Zm0-1.74c-10.6,0-19.11,8.51-19.11,19.11s8.51,19.11,19.11,19.11,19.11-8.51,19.11-19.11-8.51-19.11-19.11-19.11Z"/>
              </g>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios">
            Creación de Oficios
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <div class="col-12">
              <router-link to="/oficialia/creacion/0" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosNuevos }} )</span>
                <i class="fa-solid fa-file fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                </i>
                <span class="">Oficios nuevos</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/creacion/1" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosEspera }} )</span>
                <i class="fa-solid fa-clock fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span class="">Oficios en espera</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/creacion/2" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosObservacion }} )</span>
                <i class="fa-solid fa-triangle-exclamation fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios con observación</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/creacion/3" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosFirmar }} )</span>
                <i class="fa-solid fa-pencil fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Mis oficios en espera de firma</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/creacion/4" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosFirmados }} )</span>
                <i class="fa-solid fa-square-check fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios firmados</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/creacion/5" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosEnviados }} )</span>
                <i class="fa-solid fa-paper-plane fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios enviados</span>
              </router-link>
            </div>

          </div>
          <!-- Contadores -->



          </div>
    </div>
    <!-- Link de Creacion de oficios -->

    <!-- Link de Vistos Buenos de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000">
     
    
          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.8 96.35">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path class="Mcls-2" d="m60.55,83.26H3.78V3.78h26.49v30.28h30.28v49.2ZM34.06,5.11l25.17,25.17h-25.17V5.11Zm30.28,25.17L34.06,0H0v87.04h64.34V30.28Z"/>
                <circle class="Mcls-1" cx="53.67" cy="77.35" r="18"/>
                <path class="Mcls-3" d="m53.69,59.88c9.55,0,17.37,7.82,17.37,17.37s-7.82,17.37-17.37,17.37-17.37-7.82-17.37-17.37,7.82-17.37,17.37-17.37Zm0-1.74c-10.6,0-19.11,8.51-19.11,19.11s8.51,19.11,19.11,19.11,19.11-8.51,19.11-19.11-8.51-19.11-19.11-19.11Zm-8.81,22.26l5.69,4.98c.6.53,1.53.45,2.04-.17l11.81-14.37c.53-.65.39-1.61-.3-2.08h0c-.6-.41-1.41-.3-1.88.25l-9.74,11.37c-.47.55-1.27.66-1.87.26l-3.93-2.63c-.65-.43-1.53-.26-1.97.38l-.09.13c-.41.59-.3,1.4.24,1.87Z"/>
              </g>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios">
            Vistos Buenos
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <div class="col-12">
              <router-link to="/oficialia/vbccp/0" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosVBpendientes }} )</span>
                <i class="fa-solid fa-check-double fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Vistos buenos pendientes</span>
              </router-link>
            </div>

            <div class="col-12">
              <router-link to="/oficialia/vbccp/1" class="linkFix">
                <span class="pr-1">( {{ contadorOficiosVBatendidos }} )</span>
                <i class="fa-solid fa-file-import fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios atendidos</span>
              </router-link>
            </div>

          </div>
          <!-- Contadores -->

          </div>
        

    </div>
    <!-- Link de Vistos Buenos de oficios -->

    <!-- Link de oficios que requiren mi firma de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000" v-if="esTitular">
    
          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.06 95.21">
            <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path class="Mcls-3" d="m80.44,83.26H23.67V3.78h26.49v30.28h30.28v49.2ZM53.95,5.11l25.17,25.17h-25.17V5.11Zm30.28,25.17L53.95,0H19.89v87.04h64.34V30.28Z"/>
              <g>
                <rect class="Mcls-2" x="54.56" y="52.35" width="40" height="40" rx="2.9" ry="2.9"/>
                <g>
                  <path class="Mcls-1" d="m71.41,57.89v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m69.89,57.89c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m76.18,57.89c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m77.7,57.89v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <rect class="Mcls-1" x="78.96" y="56.63" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m70.16,57.89h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="70.16" y="57.89" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m72.4,59.15c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m71.68,59.15c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m72.94,57.89c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <rect class="Mcls-1" x="73.93" y="57.89" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="75.19" y="57.89" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m77.7,58.88c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m70.16,59.41v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m69.89,60.4c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="70.16" y="59.15" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="71.41" y="59.15" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="72.67" y="59.15" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m74.2,59.15h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m70.16,60.4h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m71.41,61.39c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m71.68,60.4h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m72.67,60.67v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m72.67,61.39c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="75.19" y="60.4" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m77.97,60.4c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m79.95,60.4c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <path class="Mcls-1" d="m72.67,62.92v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <rect class="Mcls-1" x="73.93" y="61.66" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m77.43,62.92c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="77.7" y="61.66" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m80.22,62.65c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m70.16,64.18v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <rect class="Mcls-1" x="71.41" y="62.92" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m76.44,62.92h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="76.44" y="62.92" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m78.96,63.91c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m79.23,62.92h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m68.9,65.17c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m71.41,65.17c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m75.19,65.43v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m76.44,64.44v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m76.44,65.17c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m77.97,64.18h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m80.22,65.43v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <rect class="Mcls-1" x="73.93" y="65.43" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m78.96,66.42c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m60.09,66.69h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="60.09" y="66.69" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="61.35" y="66.69" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="62.61" y="66.69" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m63.87,67.95h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m67.64,66.69h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="67.64" y="66.69" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="68.9" y="66.69" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="70.16" y="66.69" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m71.41,67.95h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m73.93,67.68c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m77.7,66.69h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m78.69,66.69c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <path class="Mcls-1" d="m79.95,67.95c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m79.23,67.95c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m81.47,67.95v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m83.99,67.95v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m84.26,67.95c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="85.25" y="66.69" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="87.76" y="66.69" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m61.35,68.22v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m61.08,69.21c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="61.35" y="67.95" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m62.88,67.95h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m66.65,69.21c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m66.38,69.21v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m68.9,68.22v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m68.9,68.94c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="70.16" y="67.95" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m71.68,67.95h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m77.7,68.22v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m77.43,69.21c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="77.7" y="67.95" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="78.96" y="67.95" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="80.22" y="67.95" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m81.74,69.21c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m82.73,68.94c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m84.98,67.95c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <rect class="Mcls-1" x="89.02" y="67.95" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m60.36,69.21c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <rect class="Mcls-1" x="61.35" y="69.21" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m65.13,70.2c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m66.38,70.47h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m70.16,69.47v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m69.89,70.47c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="70.16" y="69.21" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m72.4,70.47c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m71.68,70.47c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m72.94,69.21c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m73.93,70.47h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m76.71,69.21c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m78.96,70.2c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m79.23,69.21h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m80.22,69.47v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m79.95,70.47c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="80.22" y="69.21" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m81.47,70.47h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m83.99,69.47v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m83.99,70.2c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="86.51" y="69.21" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="60.09" y="70.47" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m62.61,71.46c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m68.9,70.47h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="68.9" y="70.47" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="70.16" y="70.47" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="71.41" y="70.47" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="72.67" y="70.47" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m74.2,70.47h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m76.44,71.46c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m77.97,70.47h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m80.22,70.47h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m81.47,71.46c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m81.74,70.47h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m59.83,72.98c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="60.09" y="71.72" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m61.62,71.72h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <rect class="Mcls-1" x="62.61" y="71.72" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m66.38,71.72h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m66.38,72.98h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m68.9,71.99v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m68.9,72.71c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m71.41,72.71c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m71.68,71.72h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m72.67,71.99v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m72.67,72.71c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="75.19" y="71.72" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="77.7" y="71.72" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m81.21,72.98c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m81.74,71.72c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <rect class="Mcls-1" x="82.73" y="71.72" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="83.99" y="71.72" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.25,72.98h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m89.02,71.72h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m89.02,72.98h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m59.1,72.98c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m61.35,73.97c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <rect class="Mcls-1" x="63.87" y="72.98" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m71.15,74.24c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m72.67,74.24v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <rect class="Mcls-1" x="73.93" y="72.98" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m77.7,74.24v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m80.22,72.98h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="80.22" y="72.98" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="81.47" y="72.98" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="82.73" y="72.98" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="83.99" y="72.98" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.52,72.98h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <rect class="Mcls-1" x="58.84" y="74.24" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m60.36,74.24h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m61.62,74.24c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m62.61,75.5h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m66.38,74.24h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m66.38,75.5h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m71.41,74.24h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m72.67,75.23c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m76.44,75.23c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m80.22,74.51v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="80.22" y="74.24" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m82.73,75.23c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m83,74.24h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m83.99,74.51v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m83.99,75.23c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m87.76,74.24h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="87.76" y="74.24" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m90.01,74.24c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <rect class="Mcls-1" x="58.84" y="75.5" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="61.35" y="75.5" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m62.88,75.5h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m62.88,76.75c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="67.64" y="75.5" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m74.2,75.5c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m75.19,76.75h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m79.95,76.75c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="80.22" y="75.5" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m81.74,75.5h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m81.74,76.75c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m87.76,75.76v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m87.76,76.49c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="89.02" y="75.5" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m58.84,77.74c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m61.35,77.74c0,.15.12.27.27.27h.99v-1.26h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="62.61" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="63.87" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="65.13" y="76.75" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m66.38,78.01h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m69.17,76.75c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m70.16,78.01h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m73.93,77.74c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m75.45,76.75h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m78.96,76.75h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="78.96" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="80.22" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="81.47" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="82.73" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="83.99" y="76.75" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.25,78.01h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m89.02,77.02v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="89.02" y="76.75" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="68.9" y="78.01" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m70.42,78.01h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <rect class="Mcls-1" x="71.41" y="78.01" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m78.96,78.28v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="78.96" y="78.01" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m80.48,78.01h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m83.99,78.28v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="83.99" y="78.01" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.52,78.01h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m88.75,79.27c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="89.02" y="78.01" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m68.9,80.26c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m72.4,80.53c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m72.94,79.27c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <path class="Mcls-1" d="m73.93,80.53h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <rect class="Mcls-1" x="76.44" y="79.27" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="78.96" y="79.27" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="81.47" y="79.27" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="83.99" y="79.27" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.52,80.53c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m87.76,79.27h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="87.76" y="79.27" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m90.28,80.26c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m71.68,80.53c-.15,0-.27.12-.27.27v.99h1.26v-1.26h-.99Z"/>
                  <rect class="Mcls-1" x="72.67" y="80.53" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m74.2,80.53h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m74.2,81.78c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="78.96" y="80.53" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m80.48,81.78c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <path class="Mcls-1" d="m83.72,81.78c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="83.99" y="80.53" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m86.24,80.53c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <path class="Mcls-1" d="m70.16,83.04v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m71.15,83.04c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m70.42,83.04c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="71.41" y="81.78" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="72.67" y="81.78" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m74.92,81.78c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <path class="Mcls-1" d="m77.7,83.04v-.99c0-.15-.12-.27-.27-.27h-.72c-.15,0-.27.12-.27.27v.99h1.26Z"/>
                  <path class="Mcls-1" d="m78.69,83.04c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m77.97,83.04c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="78.96" y="81.78" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="80.22" y="81.78" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="81.47" y="81.78" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="82.73" y="81.78" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="83.99" y="81.78" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m86.51,82.77c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <rect class="Mcls-1" x="68.9" y="83.04" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="70.16" y="83.04" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="71.41" y="83.04" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m72.94,83.04h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m73.93,83.31v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="73.93" y="83.04" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m76.18,84.3c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <path class="Mcls-1" d="m75.45,84.3c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="76.44" y="83.04" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="77.7" y="83.04" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m80.22,84.03c0,.15-.12.27-.27.27h-.99v-1.26h1.26v.99Z"/>
                  <path class="Mcls-1" d="m80.48,83.04h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m81.47,83.31v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m81.47,84.03c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m83,83.04h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m83.99,83.31v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <rect class="Mcls-1" x="83.99" y="83.04" width="1.26" height="2.52"/>
                  <path class="Mcls-1" d="m85.52,83.04h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m89.02,83.04h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <path class="Mcls-1" d="m90.01,83.04c.15,0,.27.12.27.27v.99h-1.26v-1.26h.99Z"/>
                  <rect class="Mcls-1" x="68.9" y="84.3" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m70.42,84.3h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m71.41,84.57v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m71.41,85.29c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="73.93" y="84.3" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="75.19" y="84.3" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="76.44" y="84.3" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m77.97,84.3h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m83.72,86.81c.15,0,.27-.12.27-.27v.27h-.27Z"/>
                  <rect class="Mcls-1" x="83.99" y="85.56" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.52,86.81c-.15,0-.27-.12-.27-.27v.27h.27Z"/>
                  <rect class="Mcls-1" x="86.51" y="84.3" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m89.02,84.57v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m89.02,85.29c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="68.9" y="85.56" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m73.93,86.55c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m75.45,85.56h-.27v.27c0-.15.12-.27.27-.27Z"/>
                  <path class="Mcls-1" d="m76.44,85.82v-.27h-.27c.15,0,.27.12.27.27Z"/>
                  <path class="Mcls-1" d="m76.44,86.55c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <path class="Mcls-1" d="m80.22,86.81h-.99c-.15,0-.27.12-.27.27v.72c0,.15.12.27.27.27h.99v-1.26Z"/>
                  <rect class="Mcls-1" x="80.22" y="86.81" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="81.47" y="86.81" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="82.73" y="86.81" width="1.26" height="1.26"/>
                  <rect class="Mcls-1" x="83.99" y="86.81" width="1.26" height="1.26"/>
                  <path class="Mcls-1" d="m85.25,88.07h.99c.15,0,.27-.12.27-.27v-.72c0-.15-.12-.27-.27-.27h-.99v1.26Z"/>
                  <path class="Mcls-1" d="m68.9,87.81c0,.15.12.27.27.27h.72c.15,0,.27-.12.27-.27v-.99h-1.26v.99Z"/>
                  <rect class="Mcls-1" x="75.19" y="86.81" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <rect class="Mcls-1" x="80.22" y="84.3" width="1.26" height="1.26" rx=".27" ry=".27"/>
                  <path class="Mcls-1" d="m66.38,58.04v5.98c0,.09-.07.15-.15.15h-5.98c-.09,0-.15-.07-.15-.15v-5.98c0-.09.07-.15.15-.15h5.98c.09,0,.15.07.15.15Zm-7.29-1.41c-.15,0-.25.09-.25.24v8.3c0,.15.12.27.27.27h8.27c.15,0,.27-.12.27-.27v-8.27c0-.15-.12-.27-.27-.27h-8.29Z"/>
                  <rect class="Mcls-1" x="60.41" y="58.2" width="5.66" height="5.66" rx=".4" ry=".4"/>
                  <path class="Mcls-1" d="m89.02,58.04v5.98c0,.09-.07.15-.15.15h-5.98c-.09,0-.15-.07-.15-.15v-5.98c0-.09.07-.15.15-.15h5.98c.09,0,.15.07.15.15Zm-7.28-1.41c-.15,0-.27.12-.27.27v8.27c0,.15.12.27.27.27h8.27c.15,0,.27-.12.27-.27v-8.27c0-.15-.17-.26-.32-.26h-8.22Z"/>
                  <rect class="Mcls-1" x="83.05" y="58.2" width="5.66" height="5.66" rx=".4" ry=".4"/>
                  <path class="Mcls-1" d="m66.38,80.68v5.98c0,.09-.07.15-.15.15h-5.98c-.09,0-.15-.07-.15-.15v-5.98c0-.09.07-.15.15-.15h5.98c.09,0,.15.07.15.15Zm-7.28-1.41c-.15,0-.27.12-.27.27v8.17c0,.15.2.36.35.36h8.19c.15,0,.27-.12.27-.27v-8.27c0-.15-.12-.27-.27-.27h-8.27Z"/>
                  <rect class="Mcls-1" x="60.41" y="80.84" width="5.66" height="5.66" rx=".4" ry=".4"/>
                </g>
              </g>
              <g>
                <circle class="Mcls-1" cx="22.62" cy="72.68" r="21.77"/>
                <path class="Mcls-4" d="m22.86,51.32c11.61,0,21.03,9.42,21.03,21.03s-9.42,21.03-21.03,21.03S1.83,83.96,1.83,72.35s9.42-21.03,21.03-21.03Zm0-1.83c-12.62,0-22.86,10.24-22.86,22.86s10.24,22.86,22.86,22.86,22.86-10.24,22.86-22.86-10.24-22.86-22.86-22.86Z"/>
                <path class="Mcls-3" d="m22.88,85.53c-2.1,0-3.57-1.2-3.57-3.23s1.47-3.23,3.57-3.23,3.53,1.2,3.53,3.23-1.47,3.23-3.53,3.23Zm.99-8.38h-2.02c-.89,0-1.63-.71-1.65-1.6l-.43-14.66c-.03-.93.72-1.7,1.65-1.7h2.91c.93,0,1.68.77,1.65,1.7l-.46,14.66c-.03.89-.76,1.6-1.65,1.6Z"/>
              </g>
            </g>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios mt-3">
            Oficios Pendientes de Firma
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <router-link to="/oficialia/firmas/0" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosFirmar2 }} )</span>
                <i class="fa-solid fa-pencil fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios para firmar</span>
              </div>
           </router-link>

           <router-link to="/oficialia/firmas/1" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosFirmados2 }} )</span>
                <i class="fa-solid fa-clipboard-check fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Oficios firmados</span>
              </div>
           </router-link>

          </div>
          <!-- Contadores -->

          </div>
        
      
    </div>
    <!-- Link de oficios que requiren mi firma de oficios -->


    <!-- Link de Recepcion de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000">

          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.9 80.93">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path class="Mcls-2" d="m36.26,0c-1.32,0-2.51.82-2.97,2.07-.46,1.24-.1,2.64.91,3.5,6.92,5.94,16.89,14.49,16.89,14.49h-13.96c-1.36,0-2.56.87-3,2.15-.43,1.28,0,2.7,1.08,3.53,5.46,4.17,12.48,9.54,15.82,12.09,1.13.87,2.71.87,3.84,0,3.33-2.55,10.36-7.92,15.82-12.09,1.08-.82,1.51-2.24,1.08-3.53-.44-1.28-1.64-2.15-3-2.15h-13.96s9.97-8.56,16.89-14.49c1-.86,1.37-2.26.91-3.5-.46-1.24-1.64-2.07-2.97-2.07h-33.38Z"/>
                <path class="Mcls-1Alt" d="m100.84,76.06v-33.7h-29.6c-2.17,8.18-9.63,14.44-18.53,14.44s-16.37-6.26-18.53-14.44H4.57v33.7h96.27ZM71.66,11.08h14.02l20.22,26.47v43.37H0v-43.37L20.46,11.08h13.78l6.44,4.81h-19.26L4.57,37.56h33.7c0,7.94,6.5,14.44,14.44,14.44s14.44-6.5,14.44-14.44h33.7l-16.85-21.66h-18.78l6.44-4.81Z"/>
              </g>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios mt-5">
            Recepción de Oficios
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <router-link to="/oficialia/recepcion/0" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosPendientes }} )</span>
                <i class="fa-solid fa-bell fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                </i>
                <span>Oficios pendientes</span>
              </div>
            </router-link>

            <router-link to="/oficialia/recepcion/1" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosAtencion }} )</span>
                <i class="fa-solid fa-tag fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                </i>
                <span>Oficios con atención</span>
              </div>
            </router-link>

            <router-link to="/oficialia/recepcion/2" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosCCP }} )</span>
                <i class="fa-solid fa-check fa-bounce px-2"
                  style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                <span>Con copia para</span>
              </div>
            </router-link>

          </div>
          <!-- Contadores -->

          </div>
        
    </div>
    <!-- Link de Recepcion de oficios -->

    <!-- Link de Turnados de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000">
    
    
          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.98 107.04">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path class="Mcls-1Alt" d="m87.63,17.04h0c1.78,0,3.54.48,4.22.94,1.32.9,3.66,2.84,4.5,5.71,1.14,3.89,1.08,8.59.69,11.43-.31,2.22-.15,4.57.06,6.75.13,1.44.76,4.84,1.74,7.87.42,1.3.9,2.48,1.34,3.46-.41.21-.93.43-1.56.63-3.74,1.17-6.14,1.28-6.13,1.28-2.17.06-3.89,1.83-3.89,4v5.63s0,.08,0,.11c.09,2.98,2.49,8.75,10.97,9.23.05,0,.08,0,.13,0,.05,0,4.92.19,8.35,3.35,1.2,1.11,1.91,2.6,2.32,3.99h-50.58c.42-1.39,1.13-2.88,2.32-3.99,3.42-3.17,8.3-3.35,8.32-3.35.05,0,.1,0,.16,0,3.64-.21,6.43-1.41,8.28-3.57,2.01-2.35,2.11-4.95,2.07-5.89v-5.51c0-1.79-1.19-3.36-2.91-3.85-2.17-.61-5.43-1.79-7.65-2.96.53-1.39,1.1-2.93,1.36-3.84.65-2.23.64-4.69.38-8.74-.03-.54-.08-1.15-.12-1.8-.22-3.09-.58-8.26-.12-10.54.75-3.76,2.5-6.95,3.64-7.57.88-.47,1.93-.56,2.66-.56s1.32.11,1.32.11h0c.32.08.63.12.94.12.81,0,1.58-.26,2.27-.76.02-.01,1.6-1.11,3.72-1.56.18-.04.56-.1,1.19-.1m0-4c-.74,0-1.44.06-2.02.19-3.04.64-5.21,2.21-5.21,2.21,0,0-.91-.2-2.2-.2s-2.99.21-4.54,1.03c-3.1,1.66-5.02,7-5.69,10.31s-.05,9.85.17,13.38c.22,3.53.25,5.71-.23,7.38-.49,1.7-2.38,6.41-2.38,6.41,2.89,2.94,11.42,5.35,11.42,5.35v5.63s.48,4.95-6.58,5.35c0,0-6.32.11-10.97,4.42-4.39,4.06-4.21,10.93-4.21,10.93h59.78s.18-6.86-4.21-10.93c-4.65-4.3-10.97-4.42-10.97-4.42-7.06-.4-7.2-5.35-7.2-5.35v-5.63s2.8-.08,7.22-1.46c4.16-1.3,5.32-3.53,5.32-3.53,0,0-1.43-2.34-2.49-5.61-.91-2.79-1.46-5.87-1.57-7-.15-1.56-.35-3.87-.08-5.84.49-3.53.46-8.74-.82-13.1-1.13-3.84-3.98-6.45-6.09-7.89-1.58-1.08-4.25-1.64-6.48-1.64h0Z"/>
                <path class="Mcls-1Alt" d="m44.4,4c2.17,0,4.08.51,4.67.75.4.17.87.34,1.46.55,5.59,2,6.79,3.21,7.01,3.73.2.71.54,1.17.74,1.44,1.24,1.68,1.8,2.87,2.05,3.57.78,2.19-.11,8.41-.49,11.06-.28,1.98-.41,2.88-.32,3.71h0c.07.6.38,2.11,1.81,3.13-.12.72-.32,1.55-.47,1.97-.44.94-.55,1.93-.67,2.97-.02.17-.04.35-.06.52-.41.28-.83.66-1.2,1.19-.89,1.24-1.06,2.65-1.42,6.56-.11,1.14-.22,2.42-.34,3.07-.08.13-.33.43-.78.68-2.89,1.57-2.89,3.99-2.89,7.34v.5c0,3.34,1.85,6.74,4.24,9.1-2.04,1.14-3.99,2.59-5.58,4.18-2.35,2.35-5.01,5.71-5.88,11.4H10.92c.46-3.24,1.7-7.76,5.04-9.64,7.29-4.1,10.56-5.05,12.94-5.73.51-.15.99-.29,1.45-.43,0,0,.05-.02.05-.02,2.55-.83,7.87-2.55,7.87-8.86v-.46c0-3.37,0-5.8-2.88-7.37-.46-.25-.7-.55-.79-.68-.11-.65-.23-1.93-.34-3.07-.36-3.91-.53-5.32-1.42-6.55-.38-.53-.8-.91-1.21-1.19-.02-.18-.04-.36-.06-.54-.12-1.04-.24-2.02-.67-2.96-.15-.42-.35-1.25-.47-1.97,1.47-1.04,1.76-2.59,1.82-3.14.09-.83-.04-1.72-.32-3.68-.38-2.66-1.28-8.88-.5-11.08.94-2.65,1.61-3.05,2.72-3.71.2-.12.39-.23.58-.35,1.09-.68,1.86-1.55,2.61-2.39.95-1.07,1.84-2.08,3.59-2.95.85-.42,2.05-.64,3.48-.64m0-4c-1.8,0-3.68.28-5.26,1.06-3.86,1.93-4.9,4.51-6.53,5.53-1.62,1.02-3.36,1.63-4.96,6.12-1.53,4.31.74,14.47.61,15.68-.08.74-1.14.12-1.72,1.32-.66,1.35.41,5.26.71,5.87.3.6.3,2.02.61,3.64.3,1.62,1.22,1.01,1.73,1.72.51.71.71,6.23,1.1,8.2.21,1.06,1.16,2.42,2.77,3.29.84.46.8.9.8,4.32s-2.66,4.26-5.15,5.07c-2.66.86-5.98,1.35-15.12,6.49-6.93,3.89-7.34,14.24-7.34,16.63,0,.28.23.5.5.5h42.33c.27,0,.49-.23.5-.5.12-6.25,2.51-9.59,5-12.07,2-2,5-4,8-5,1.24-.41,3.42-.68,5.41-.84.58-.04.63-.87.07-1-2.46-.56-4.43-.96-5.24-1.22-2.48-.8-5.72-4.63-5.72-8.05s-.05-3.86.8-4.32c1.61-.88,2.56-2.24,2.77-3.29.39-1.97.6-7.49,1.11-8.2.51-.71,1.42-.11,1.73-1.72.3-1.62.3-3.04.61-3.64.3-.61,1.37-4.52.71-5.87-.59-1.2-1.65-.57-1.72-1.32-.13-1.21,2.14-11.38.61-15.68-.87-2.44-2.69-4.7-2.71-4.76-1.12-3.95-8.84-6.07-10.77-6.88-1.14-.48-3.6-1.06-6.22-1.06h0Z"/>
                <g>
                  <path class="Mcls-3" d="m25.39,57.92c13.1,0,23.73,10.63,23.73,23.73s-10.63,23.73-23.73,23.73S1.66,94.75,1.66,81.65s10.63-23.73,23.73-23.73Z"/>
                  <path class="Mcls-1" d="m24.96,96.52c-.32,0-.63-.02-.95-.04-.62-.05-1.24-.13-1.85-.26-2.02-.41-3.94-1.25-5.61-2.44-1.8-1.28-3.3-2.96-4.38-4.88-1.02-1.82-1.64-3.84-1.83-5.92-.17-1.93.03-3.9.61-5.75.55-1.78,1.45-3.45,2.62-4.9,1.12-1.39,2.49-2.58,4.03-3.49,1.36-.8,2.84-1.39,4.38-1.72,1.41-.31,2.87-.41,4.32-.3,1.48.11,2.93.45,4.31.99,1.56.62,3.01,1.5,4.28,2.6,1.31,1.13,2.41,2.49,3.26,4,.83,1.49,1.4,3.12,1.68,4.8.09.56.15,1.12.18,1.68.01.25.02.5.02.75l-3.31.02q0-.15,0-.31t-.01-.3c-.04-.63-.12-1.26-.25-1.88-.28-1.29-.78-2.52-1.48-3.64-.71-1.14-1.62-2.16-2.68-2.99-1.03-.81-2.19-1.44-3.43-1.86-1.1-.37-2.25-.57-3.41-.6-1.07-.03-2.15.09-3.19.36-1.14.29-2.23.76-3.22,1.38-1.18.74-2.23,1.69-3.07,2.79-.85,1.11-1.5,2.38-1.9,3.73-.43,1.45-.57,2.98-.42,4.49.16,1.61.67,3.17,1.47,4.57.86,1.49,2.05,2.78,3.46,3.76,1.31.91,2.82,1.55,4.39,1.85.48.09.96.15,1.45.18.25.02.5.02.75.02h.82v3.31c-.34,0-.68,0-1.02,0Z"/>
                  <polygon class="Mcls-1" points="30.11 81.65 45.81 81.65 38.22 94.87 30.11 81.65"/>
                </g>
              </g>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios mt-3">
            Oficios Turnados
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <router-link to="/oficialia/turnados/0" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosTurnados }} )</span>
                <i 
                class="fa-solid fa-arrows-turn-right fa-bounce px-2"
                style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
                ></i>
                <span>Oficios que te turnaron</span>
              </div>
            </router-link>

            <router-link to="/oficialia/turnados/1" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosTurnadosDestinatario }} )</span>
                <i 
                class="fa-solid fa-arrows-turn-to-dots fa-bounce px-2"
                style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
                ></i>
                <span>Oficios que turnaste</span>
              </div>
            </router-link>

          </div>
          <!-- Contadores -->

          </div>
        
     
    </div>
    <!-- Link de Turnados de oficios -->

    <!-- Link de Archivados de oficios -->
    <div class="col-2 flex align-items-center justify-content-center scalein animation-duration-1000">
      
    
          <div class="card px-1 py-3 cardMenu">

          <!-- SVG --> 
          <svg id="link_oficios" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 101.25">
            <g id="Layer_1-2" data-name="Layer 1">
              <path class="Mcls-1Alt" d="m94.28,96.75v-31.5h-27.23c-1.12,8.77-8.77,15.75-17.77,15.75s-16.65-6.98-17.77-15.75H4.27v31.5h90ZM36.55,29.25c1.86,5.23,6.88,9,12.72,9s10.86-3.77,12.72-9h-25.45Zm-11.37,0H4.27v31.5h90v-31.5h-21.23s-6.44,0-6.44,0c-2.03,7.65-9,13.5-17.32,13.5s-15.3-5.85-17.32-13.5h-6.77Zm-20.91-4.5h90l-15.75-20.25H20.02L4.27,24.75Zm31.73,40.5c1.12,6.3,6.75,11.25,13.5,11.25s12.15-4.95,13.27-11.25h-26.77ZM80.1,0l18.9,24.75v74.25c0,1.35-.9,2.25-2.25,2.25H2.25c-1.35,0-2.25-.9-2.25-2.25V24.75L19.12,0h60.97Z"/>
            </g>
          </svg>
          <!-- SVG --> 

          <span class="p-3 labelOficios mt-3">
            Oficios Archivados
          </span>

          <!-- Contadores -->
          <div class="grid contadoresMenu">

            <router-link to="/oficialia/archivados/0" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosArchivadosRecepcion }} )</span>
                <i 
                class="fa-solid fa-folder-open fa-bounce px-2"
                style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
                ></i>
                <span>Oficios archivados de recepción</span>
              </div>
            </router-link>

            <router-link to="/oficialia/archivados/1" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosArchivadosRecepcionAtencion }} )</span>
                <i 
                class="fa-solid fa-folder-open fa-tags px-2"
                style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
                ></i>
                <span>Oficios archivados con atención</span>
              </div>
            </router-link>

            <router-link to="/oficialia/archivados/2" class="linkFix">
              <div class="col-12">
                <span class="pr-1">( {{ contadorOficiosArchivadosRecepcionTurnados }} )</span>
                <i 
                class="fa-solid fa-arrows-turn-right fa-bounce px-2"
                style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
                ></i>
                <span>Oficios archivados turnados</span>
              </div>
            </router-link>

          </div>
          <!-- Contadores -->

          </div>
        
   
    </div>
    <!-- Link de Archivados de oficios -->
  </div>


</template>

<script>
import { ref, onMounted, onBeforeMount } from 'vue';
import useOficiosList from "../composables/useOficiosCreacion";
import useOficiosRecepcion from "../composables/useOficiosRecepcion";
import useDialogVByCPP from '@/modules/oficialia/composables/compDialogs/useDialogVByCCP';
import Swal from "sweetalert2";
import store from "@/store";


export default{

  setup(){


    const {

      getFichas,
      getFichasFirmaC,
      getFichasFirmadasC,

      contadorOficiosNuevos,
      contadorOficiosNuevosAlt,
      barraOficiosNuevos,

      contadorOficiosEspera,
      contadorOficiosEsperaAlt,
      barraOficiosEspera,

      contadorOficiosObservacion,
      contadorOficiosObservacionAlt,
      barraOficiosObservacion,

      contadorOficiosFirmar,
      contadorOficiosFirmarAlt,
      barraOficiosFirmar,

      contadorOficiosFirmar3,
      contadorOficiosFirmar3Alt,
      barraOficiosFirmar3,

      contadorOficiosFirmados,
      contadorOficiosFirmadosAlt,
      barraOficiosFirmados,

      contadorOficiosEnviados,
      contadorOficiosEnviadosAlt,
      barraOficiosEnviados,

    } = useOficiosList();

    const {

      getFichasFirmadasR,
      getFichasRecepcionPendientes,
      getFichasRecepcionAtencionA,
      getFichasRecepcionTurnadosDestinatario,
      getFichasRecepcionTurnadosUsuario,
      getFichasRecepcionArchivados,
      getFichasRecepcionArchivadosAtencion,
      getFichasRecepcionArchivadosTurnados,

      contadorOficiosFirmar2,
      contadorOficiosFirmar2Alt,
      barraOficiosFirmar2,

      contadorOficiosFirmados2,
      contadorOficiosFirmados2Alt,
      barraOficiosFirmados2,

      contadorOficiosPendientes,
      contadorOficiosPendientesAlt,
      barraOficiosPendientes,
      
      contadorOficiosAtencion,
      contadorOficiosAtencionAlt,
      barraOficiosAtencion,

      contadorOficiosTurnados,
      contadorOficiosTurnadosAlt,
      barraOficiosTurnados,

      contadorOficiosTurnadosDestinatario,
      contadorOficiosTurnadosDestinatarioAlt,
      barraOficiosTurnadosDestinatario,

      contadorOficiosArchivadosRecepcion,
      contadorOficiosArchivadosRecepcionAlt,
      barraOficiosArchivadosRecepcion,
      
      contadorOficiosArchivadosRecepcionAtencion,
      contadorOficiosArchivadosRecepcionAtencionAlt,
      barraOficiosArchivadosRecepcionAtencion,

      contadorOficiosArchivadosRecepcionTurnados,
      contadorOficiosArchivadosRecepcionTurnadosAlt,
      barraOficiosArchivadosRecepcionTurnados,

    } = useOficiosRecepcion();

    const {
        getVoBoyCcp,

        contadorOficiosVBpendientes,
        contadorOficiosVBpendientesAlt,
        barraOficiosVBpendientes,

        contadorOficiosVBatendidos,
        contadorOficiosVBatendidosAlt,
        barraOficiosVBatendidos,

        contadorOficiosCCP,
        contadorOficiosCCPAlt,
        barraOficiosCCP,
      
    } = useDialogVByCPP();

    //checar si es titular
    const esTitular = ref();
    const noesTitular = ref();

    const validarTitular = async () => {
      if (store.state.auth.user.titular == 1) {
        esTitular.value = true;
        noesTitular.value = false;

      } else {
        esTitular.value = false;
        noesTitular.value = true;

      }
    }

    const cargarTodo = async () =>{

      new Swal({
        title: "Cargando...",
        text: "Actualizando información de todos los oficios, por favor espere.",
        allowOutsideClick: false,
      });
      Swal.showLoading();

      // const { ok, message } = await getFichas();
      await Promise.all([
       validarTitular(), 

         getFichas(), 
         getFichasFirmaC(), 
         getFichasFirmadasC(), 
         getFichasFirmadasR(), 
         getFichasRecepcionPendientes(),  
         getFichasRecepcionAtencionA(), 
         getFichasRecepcionTurnadosDestinatario(),
         getFichasRecepcionTurnadosUsuario(), 
         getFichasRecepcionArchivados(),
         getFichasRecepcionArchivadosAtencion(),
         getFichasRecepcionArchivadosTurnados(),
         getVoBoyCcp(),

          // barraOficiosEspera(),
          // barraOficiosNuevos(),
          // barraOficiosObservacion(),
          // barraOficiosFirmar(),
          // barraOficiosFirmar3(),
          // barraOficiosFirmados(),
          // barraOficiosEnviados(),
          // barraOficiosFirmar2(),
          // barraOficiosFirmados2(),
          // barraOficiosPendientes(),
          // barraOficiosAtencion(),
          // barraOficiosTurnados(),
          // barraOficiosTurnadosDestinatario(),
          // barraOficiosArchivadosRecepcion(),
          // barraOficiosArchivadosRecepcionAtencion(),
          // barraOficiosArchivadosRecepcionTurnados(),
          // barraOficiosVBpendientes(),
          // barraOficiosVBatendidos(),
          // barraOficiosCCP(),
        ]);

      // if (!ok) Swal.fire("Error", message, "error");
      // else {
      //   Swal.close();
      // }

      // aqui se cargaran las tablas para los contadores
      // await validarTitular();
      // await getFichasFirmaC();
      
      // await getFichasFirmadasC();
      // await getFichasFirmadasR();
      // await getFichasRecepcionPendientes();
      // await getFichasRecepcionAtencionA();
      // await getFichasRecepcionTurnadosDestinatario();
      // await getFichasRecepcionTurnadosUsuario();
      // await getFichasRecepcionArchivados();
      // await getFichasRecepcionArchivadosAtencion();
      // await getFichasRecepcionArchivadosTurnados();
      // await getVoBoyCcp();

      // aqui se ejecutan los contadores
        await barraOficiosEspera();
        await barraOficiosNuevos();
        await barraOficiosObservacion();
        await barraOficiosFirmar();
        await barraOficiosFirmar3();
        await barraOficiosFirmados();
        await barraOficiosEnviados();
        await barraOficiosFirmar2();
        await barraOficiosFirmados2();
        await barraOficiosPendientes();
        await barraOficiosAtencion();
        await barraOficiosTurnados();
        await barraOficiosTurnadosDestinatario();
        await barraOficiosArchivadosRecepcion();
        await barraOficiosArchivadosRecepcionAtencion();
        await barraOficiosArchivadosRecepcionTurnados();
        await barraOficiosVBpendientes();
        await barraOficiosVBatendidos();
        await barraOficiosCCP();

      Swal.close();
    }

 
    onBeforeMount(async () => {
    
    });

    onMounted(async () => {
      
      await cargarTodo();
    })

    return{
      getFichas,
      getFichasFirmaC,
      getFichasFirmadasC,
      esTitular,

      contadorOficiosNuevos,
      contadorOficiosNuevosAlt,
      barraOficiosNuevos,

      contadorOficiosEspera,
      contadorOficiosEsperaAlt,
      barraOficiosEspera,

      contadorOficiosObservacion,
      contadorOficiosObservacionAlt,
      barraOficiosObservacion,

      contadorOficiosFirmar,
      contadorOficiosFirmarAlt,
      barraOficiosFirmar,

      contadorOficiosFirmar3,
      contadorOficiosFirmar3Alt,
      barraOficiosFirmar3,

      contadorOficiosFirmados,
      contadorOficiosFirmadosAlt,
      barraOficiosFirmados,

      contadorOficiosEnviados,
      contadorOficiosEnviadosAlt,
      barraOficiosEnviados,

      getFichasFirmadasR,
      getFichasRecepcionPendientes,
      getFichasRecepcionAtencionA,
      getFichasRecepcionTurnadosDestinatario,
      getFichasRecepcionTurnadosUsuario,
      getFichasRecepcionArchivados,
      getFichasRecepcionArchivadosAtencion,
      getFichasRecepcionArchivadosTurnados,

      contadorOficiosFirmar2,
      contadorOficiosFirmar2Alt,
      barraOficiosFirmar2,

      contadorOficiosFirmados2,
      contadorOficiosFirmados2Alt,
      barraOficiosFirmados2,

      contadorOficiosPendientes,
      contadorOficiosPendientesAlt,
      barraOficiosPendientes,
      
      contadorOficiosAtencion,
      contadorOficiosAtencionAlt,
      barraOficiosAtencion,

      contadorOficiosTurnados,
      contadorOficiosTurnadosAlt,
      barraOficiosTurnados,

      contadorOficiosTurnadosDestinatario,
      contadorOficiosTurnadosDestinatarioAlt,
      barraOficiosTurnadosDestinatario,

      contadorOficiosArchivadosRecepcion,
      contadorOficiosArchivadosRecepcionAlt,
      barraOficiosArchivadosRecepcion,
      
      contadorOficiosArchivadosRecepcionAtencion,
      contadorOficiosArchivadosRecepcionAtencionAlt,
      barraOficiosArchivadosRecepcionAtencion,

      contadorOficiosArchivadosRecepcionTurnados,
      contadorOficiosArchivadosRecepcionTurnadosAlt,
      barraOficiosArchivadosRecepcionTurnados,

      getVoBoyCcp,

      contadorOficiosVBpendientes,
      contadorOficiosVBpendientesAlt,
      barraOficiosVBpendientes,

      contadorOficiosVBatendidos,
      contadorOficiosVBatendidosAlt,
      barraOficiosVBatendidos,

      contadorOficiosCCP,
      contadorOficiosCCPAlt,
      barraOficiosCCP,

    }
  }

}


</script>

<style scoped lang="scss">

.Mcls-1 {
  fill: white;
}

.Mcls-1Alt {
  fill: #6f1d46;
}

.Mcls-2, .Mcls-3, .Mcls-4 {
  fill: #6f1d46;
}

.Mcls-3 {
  fill-rule: evenodd;
}

.linkFix{
  text-decoration: none;
}

.labelOficios{
  font-size: 15px;
  color:#6f1d46;
  // color:#8d949e;
  text-decoration: none;
  transition: ease 0.5s;
  margin-right: auto;
  margin-left: auto;
}
.labelOficios:hover{
  font-size: 16px;
  color:#6f1d46;
  // color:#8d949e;
  text-decoration: none;
  transition: ease 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.contadoresMenu{
  font-size: 13px;
  // color:#46132c;
  color:#8d949e;
}

.cardMenu{
  height: 400px;
}


</style>
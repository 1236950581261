import api from "@/api/laravelApi";

export const getFirmantes = async ({ commit }) => {
  try {
    
    const { data: info } = await api.get(
      `/directorio/getTitulares`
    );
    const { data } = info;
    commit("setFirmantes", { data });
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getDependenciasVBCCP = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/directorio/getDependencies`);
    const { data } = info;
    commit("setDependenciesVBCCP", { data });
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getOficinasVBCCP = async (_, dependencia) => {
  try {
    const { data: info } = await api.get(
      `/directorio/getOffices/${dependencia}`
    );
    const { data } = info;
    // commit("setOfficesVBCCP", { data });
    return { data }
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichas = async ({ commit }) => {
  try {
    const { data: info } = await api.post(`/oficios/getGeneralData`);
    const { status, data, message } = info;
    commit("setFichas", { data });
    if (status != "success") return { ok: false, message };
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasFirmaC = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosPorFirmar`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasFirmaC", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasFirmadasC = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosFirmados`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasFirmadasC", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasFirmaR = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosPorFirmar`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasFirmaR", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasFirmadasR = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosFirmados`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasFirmadasR", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasRecepcionPendientes = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosRecepcion/1`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionPendientes", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasRecepcionAtencionA = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosRecepcion/2`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionAtencionA", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getFichasRecepcionTurnados = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/oficios/getOficiosRecepcion/3`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionTurnados", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasRecepcionArchivados = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getOficiosArchivados/1`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionArchivados", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasRecepcionArchivadosAtencion = async (
  { commit }
) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getOficiosArchivados/2`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionArchivadosAtencion", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasRecepcionArchivadosTurnados = async (
  { commit }
) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getOficiosArchivados/3`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionArchivadosTurnados", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasRecepcionTurnadosUsuario = async (
  { commit }
) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getOficiosTurnados/1`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionTurnadosUsuario", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getFichasRecepcionTurnadosDestinatario = async (
  { commit }
) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getOficiosTurnados/2`
    );
    const { status, data, message } = info;

    if (status != "success") return { ok: false, message };
    commit("setFichasRecepcionTurnadosDestinatario", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getVoBoyCcp = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getVoBoByUsuario`
    );
    const { status, data, message } = info;
    if (status != "success") return { ok: false, message };
    commit("setVoBoByUsuario", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getDependencias = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/directorio/getDependencies`);
    const { data } = info;
    commit("setDependencias", { data });
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getOficinas = async (_, dependencia) => {
  try {
    const { data: info } = await api.get(
      `/directorio/getOffices/${dependencia}`
    );
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const getSubDependencia = async (_, form) => {
  try {
    const { data: info } = await api.post(
      `/directorio/getSubdependencia`,
      form
    );
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const crearFicha = async (_, form) => {
  try {
    const data = await api.post(`/oficios/storeFicha`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const sendEFirma = async (_, form) => {
  try {
    const data = await api.post(`/oficios/firmarOficios`, form);
    
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.response.data.message;
    }
    return { ok: false, message: msg };
  }
};

export const getTitulares = async ({ commit }) => {
  try {
    const { data: info } = await api.get(
      `/directorio/getTitulares`
    );
    const { status, data, message } = info;
    if (status != "success") return { ok: false, message };
    commit("setTitulares", data);
    return { ok: true, message, data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getLeyenda = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/catalogos/getLeyenda`);
    const { data } = info;
    commit("setLeyenda", { data });
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getComentariosVB = async ({ commit }, idOficio) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getComentariosVoBoByOficio/${idOficio}`
    );
    const { data } = info;
    commit("setComentariosVB", { data });
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const crearOficio = async (_, form) => {
  try {
    const data = await api.post(`/oficios/generate`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const modificarOficio = async (_, form) => {
  try {
    const data = await api.post(`/oficios/updateOficio`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const enviarOficioSinVB = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/aceptarOficioSinVoBo`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const archivarOficiosR = async (_, form) => {
  try {
    const data = await api.post(`/oficios/archivarOficio`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const archivarOficiosTurnados = async (_, form) => {
  try {
    const data = await api.post(`/oficios/archivarOficioTurnado`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const turnarOficio = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/turnarOficio`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getPreviewPDF = async (_, id_oficio) => {
  try {
    const { data: info } = await api.post(`rutas/retornarArchivo`, id_oficio);
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getPreviewAcuse = async (_, id_oficio) => {
  try {
    const { data: info } = await api.post(`oficios/getOficioAcuse`, id_oficio);
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const editarFicha = async (_, form) => {
  try {
    const data = await api.post(`/oficios/updateFicha`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const adjuntarOficio = async (_, form) => {
  try {
    const data = await api.post(`/oficios/generate`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const adjuntarOficioModificado = async (_, form) => {
  try {
    const data = await api.post(`/oficios/updateOficioAdjunto`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const adjuntarAnexos = async (_, form) => {
  try {
    const data = await api.post(`/oficios/anexoAgregar`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const changeVoBoCCP = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/changeVoBoCCP`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.response.data.message;
    }
    return { ok: false, message: msg };
  }
};

export const contestarAdjunto = async (_, form) => {
  try {
    const data = await api.post(`/oficios/oficioGenerateContestacion`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const contestarCreacion = async (_, form) => {
  try {
    const data = await api.post(`/oficios/oficioGenerateContestacion`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getEstructuraFuncionarios = async ({ commit }, form) => {
  try {
    const { data: info } = await api.post(
      `/catalogos/getEstructuraFuncionarios`,
      form
    );
    const { data } = info;
    commit("setFuncionarios", { data });
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};


export const getFuncionariosTurnar = async ({ commit }, form) => {
  try {
    const { data: info } = await api.post(
      `/correspondencia/getFuncionariosTurnar`,
      form
    );
    const { data } = info;
    commit("setFuncionariosTurnar", { data });
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const obtenerVistosBuenos = async (_, idOficio) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getVoBoByOficio/${idOficio}`
    );
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const obtenerConCopia = async (_, idOficio) => {
  try {
    const { data: info } = await api.get(
      `/correspondencia/getCCPByOficio/${idOficio}`
    );
    const { data } = info;
    return { data };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};



export const enviarRevisionVoBoyCCP = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/enviarRevision`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const enviarOficioRecepcion = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/envioRecepcionOficio`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
export const updateVoBo = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/updateVoBo`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const reenviarOficio = async (_, idOficio) => {
  const formData = new FormData();
  formData.append("idOficio", idOficio);
  try {
    const data = await api.post(`correspondencia/enviarRevision`, formData);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};


export const cerrarSesion = async (_, form) => {
  try {
    const data = await api.post(`/users/logout`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const descargarArchivoAnexo = async (_, form) => {
  try {
    const data = await api.post(`/oficios/download`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getTextoCCP = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/getSiglasCCP`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getTextoVB = async (_, form) => {
  try {
    const data = await api.post(`/correspondencia/getSiglasPDF`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useProyectosConsejo from "../useOficiosCreacion";

const useDialogCrearFicha = () => {
  const displayModal = ref(false);

  const { 
          getFichas,
          barraOficiosNuevos,
          barraOficiosEspera,
          barraOficiosObservacion,
          barraOficiosEnviados,
          barraOficiosFirmar,
          barraOficiosFirmar3,
          barraOficiosFirmados,
   } = useProyectosConsejo();

  const store = useStore();
  /* const { toogleDialog } = useOpenDialogs(); */

  // funcion para obtener Datos del usuario
  const getFirmantes = async (form) => {
    const resp = await store.dispatch("oficialia/getFirmantes",form);
    return resp;
  };

  //funcion para obtener el catalogo de dependencias
  const getDependencias = async () => {
    const resp = await store.dispatch("oficialia/getDependencias");
    return resp;
  };

  //funcion para obtener las oficinas de la dependencia
  const getOficinas = async (dependencia) => {
    return await store.dispatch("oficialia/getOficinas", dependencia);
  };

  //funcion para obtener las subdependencias de la oficina
  const getSubDependencia = async (form) => {
    return await store.dispatch("oficialia/getSubDependencia", form);
  };

  const crearFicha = async (form) => {
    const { data: info } = await store.dispatch("oficialia/crearFicha", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Ficha creada", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error", message, "error");
    }
  };

  const editarFicha = async (form) => {
    const { data: info } = await store.dispatch("oficialia/editarFicha", form);
    const { status, message } = info;
    if (status === "success") {
      displayModal.value = false;
      await getFichas();
      await getFichas();
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosEnviados();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      Swal.fire("Ficha Editada", message, "success");
    } else {
      displayModal.value = false;
      Swal.fire("Error", message, "error");
    }
  };


  return {
    crearFicha,
    editarFicha,
    getFirmantes,
    firmantes: computed(() => store.getters["oficialia/getFirmantes"]),
    getDependencias,
    dependencias: computed(() => store.getters["oficialia/getDependencias"]),
    getOficinas,
    getSubDependencia,
    displayModal,

  };
};

export default useDialogCrearFicha;

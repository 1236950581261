<template>
  <ButtonVue label="Añadir Usuario" icon="fa-solid fa-square-plus fa-spin"
    style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;" class="botonGobColorFicha mt-2"
    @click="openModal" />

  <DialogVue header="Creación del Usuario" :visible="displayModal" :modal="true" :closable="false"
    class="flex align-items-center justify-content-center surface-0 shadow-8"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '650px' }">

    <div class="grid mb-6">

      <!-- Divider -->
      <div class="col-12 mb-1">
        <DividerVue align="center">
          <span class="p-tag colorauxLogin mx-2 px-2">
            <i class="fa-solid fa-user-plus pr-2"></i>
            Datos del Usuario
          </span>
        </DividerVue>
      </div>
      <!-- Divider -->

      <!-- Form del Nombre -->
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 p-1 flex align-items-center justify-content-center">
        <ChipVue :label="'Nombre: ' + usuarioNombreRef" icon="fa-solid fa-person-rays" class="chipsForm" />
      </div>
      <!-- Form del Nombre -->

      <!-- Form del A. Paterno -->
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 p-1 flex align-items-center justify-content-center">
        <ChipVue :label="'Apellido Paterno: ' + usuarioAPaternoRef" icon="fa-solid fa-person" class="chipsForm" />
      </div>
      <!-- Form del A. Paterno -->

      <!-- Form del A. Materno -->
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 p-1 flex align-items-center justify-content-center">
        <ChipVue :label="'Apellido Materno: ' + usuarioAMaternoRef" icon="fa-solid fa-person-dress" class="chipsForm" />
      </div>
      <!-- Form del A. Materno -->

      <!-- Form del CURP -->
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 px-5 py-2 mt-3">
        <div class="p-inputgroup">
          <ChipVue label="Curp:" icon="fa-solid fa-clipboard-user" class="chipsForm" />
          <InputTextV type="text" v-model="usuarioCurpRef" maxlength="18" style="text-transform:uppercase" id="stringCurp"
            v-on:keyup="validarCurpUsuarioForm()" />
        </div>

      </div>
      <!-- Form del CURP -->

      <!-- Form del Correo -->
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 px-5 py-2 mb-8">
        <div class="p-inputgroup">
          <ChipVue label="Correo:" icon="fa-solid fa-square-envelope" class="chipsForm" />
          <InputTextV type="text" v-model="usuarioCorreoRef" />
        </div>


      </div>
      <!-- Form del Correo -->

      <div class="col-12 mb-3 fixed-top flex align-items-center justify-content-end">
        <ButtonVue icon="fa-solid fa-xmark" @click="closeModal"
          class="p-button-rounded p-button-text p-button-secondary" />
      </div>

      <div class="col-12 mb-8 fixed-bottom flex align-items-center justify-content-center">
        <InlineMessageVue v-model:hidden="validaCorreo" class="msgValidaciones">
          <i class="fa-solid fa-circle-exclamation fa-bounce" style="--fa-animation-duration: 1.5s;"></i>
          <span class="px-2">Por favor Ingresa el Correo del Usuario</span>
        </InlineMessageVue>

        <InlineMessageVue v-model:hidden="validaCurp" class="msgValidaciones">
          <i class="fa-solid fa-circle-exclamation fa-bounce" style="--fa-animation-duration: 1.5s;"></i>
          <span class="px-2">Por favor Ingresa el CURP del Usuario</span>
        </InlineMessageVue>

        <InlineMessageVue v-model:hidden="validaCurpInvalida" class="msgValidaciones">
          <i class="fa-solid fa-circle-exclamation fa-bounce" style="--fa-animation-duration: 1.5s;"></i>
          <span class="px-2">La CURP no es Valida</span>
        </InlineMessageVue>
      </div>

      <!-- Boton para crear el Usuario -->
      <div class="col-12 mt-3 fixed-bottom flex align-items-center justify-content-center">
        <ButtonVue label="Crear Usuario" icon="fa-solid fa-square-plus" v-model:disabled="isLoading"
          @click="crearNuevoUsuarioForm" class="botonCrearTitular" />
      </div>
      <!-- Boton para crear el Usuario -->

    </div>


  </DialogVue>
</template>
  
<script>
import { ref } from 'vue';
import useDialogCrearUsuarios from '../composables/useDialogCrearUsuarios';

//import store from '@/store'; 


export default {

  setup() {

    const displayModal = ref(false);

    /* Propiedades del Modal */
    const openModal = () => {
      displayModal.value = true;

    };

    //funcion para cerrar modal
    const closeModal = () => {
      limpiarForm();
      displayModal.value = false;
    }

    //Datos para el Form
    const usuarioCurpRef = ref();
    const usuarioCorreoRef = ref();

    const usuarioNombreRef = ref("");
    const usuarioAPaternoRef = ref("");
    const usuarioAMaternoRef = ref("");


    //Validacion
    const validaCurp = ref(true);
    const validaCorreo = ref(true);

    const validaCurpInvalida = ref(true);

    const {
      crearNuevoUsuario,
      validarCurpUsuario,

    } = useDialogCrearUsuarios();

    const limpiarForm = () => {
      validaCurp.value = true;
      validaCorreo.value = true;

      usuarioCurpRef.value = [];
      usuarioCorreoRef.value = [];
      usuarioNombreRef.value = "";
      usuarioAPaternoRef.value = "";
      usuarioAMaternoRef.value = "";

    }



    const validarForm = () => {

      if (usuarioCurpRef.value === undefined || usuarioCurpRef.value === "") {
        validaCurp.value = false;
      } else {
        validaCurp.value = true;
      }

      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if ((usuarioCorreoRef.value === undefined || usuarioCorreoRef.value === "") && regex.test(usuarioCorreoRef.value)) {
        validaCorreo.value = false;
      } else {
        validaCorreo.value = true;
      }

    }

    const isLoading = ref(false);//Variable para bloquear el submit

    const liberarBoton = () => {
      isLoading.value = false;
    }

    const bloquearBoton = () => {
      isLoading.value = true;
    }

    const validarCurpUsuarioForm = async () => {

      if (usuarioCurpRef.value.length == 18) {
        let formData = new FormData();
        formData.append("curp", usuarioCurpRef.value.toUpperCase());
        //await validarCurpUsuario(formData);

        let uValidar = await validarCurpUsuario(formData);
        // console.log(uValidar);

        if ((uValidar.data.data.nombre == undefined || uValidar.data.data.nombre == null || uValidar.data.data.nombre == "") && (uValidar.data.data.apellidoPaterno == undefined || uValidar.data.data.apellidoPaterno == null || uValidar.data.data.apellidoPaterno == "") && (uValidar.data.data.apellidoMaterno == undefined || uValidar.data.data.apellidoMaterno == null || uValidar.data.data.apellidoMaterno == "")) {
          validaCurpInvalida.value = false;
          usuarioNombreRef.value = "Curp Invalida"
          usuarioAPaternoRef.value = "Curp Invalida"
          usuarioAMaternoRef.value = "Curp Invalida"
          // console.log(uValidar);
        } else {

          validaCurpInvalida.value = true;
          usuarioNombreRef.value = uValidar.data.data.nombre;
          usuarioAPaternoRef.value = uValidar.data.data.apellidoPaterno;
          usuarioAMaternoRef.value = uValidar.data.data.apellidoMaterno;
        }

      } else {
        validaCurpInvalida.value = false;
        usuarioNombreRef.value = "..."
        usuarioAPaternoRef.value = "..."
        usuarioAMaternoRef.value = "..."
      }

    }

    const crearNuevoUsuarioForm = async () => {
      validarForm();
      if (validaCurp.value === true && validaCorreo.value === true) {
        bloquearBoton();//Validar boton
        let formData = new FormData();
        //formData.append("idOficina", store.state.auth.user.oficina_id);
        //formData.append("curpTitular", store.state.auth.user.curp);
        usuarioCurpRef.value = usuarioCurpRef.value.toUpperCase();
        formData.append("curpUsuario", usuarioCurpRef.value);
        formData.append("email", usuarioCorreoRef.value);

        await crearNuevoUsuario(formData)
        liberarBoton();//Validar boton
        closeModal();
      }
    }

    return {
      displayModal, openModal, closeModal,
      usuarioCurpRef,
      usuarioCorreoRef,
      usuarioNombreRef,
      usuarioAPaternoRef,
      usuarioAMaternoRef,

      validaCurp,
      validaCorreo,
      validaCurpInvalida,
      validarCurpUsuarioForm,
      validarCurpUsuario,

      crearNuevoUsuarioForm,

      isLoading,
      bloquearBoton,
      liberarBoton,

    }
  }

}
</script>
  
<style scoped lang="scss">
.buttonDialog {
  color: gray;
}

.p-button {
  margin: 0.3rem .5rem;
  min-width: 10rem;
}

p {
  margin: 0;
}

::v-deep(.msgValidaciones) {
  .p-inline-message-icon {
    display: none;
  }

  background-color: #fff1d4;
  color:rgb(187, 0, 0);
  font-size: 10px;

}


.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep(.chipsForm) {
  .p-chip-text {
    font-size: 15px !important;
  }
}

.p-dialog .p-button {
  min-width: 6rem;
}

.gobcolor1 {
  color: #ffff;
  background-color: #4a001f;
}

.gobcolor1:hover {
  color: #ffff;
  background-color: #80245f;
}


/* Clases para las Chips de Asistentes */
::v-deep(.vistobuenochip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.vistobuenochip {
  color: #53bdeb;
  background: #005c4b;
}


::v-deep(.concopiaparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.concopiaparachip {
  color: #8e9497;
  background: #005c4b;
}

::v-deep(.firmantechip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

::v-deep(.firmantechipMenu) {
  .p-chip-text {
    font-size: 13px;
  }
}

.firmantechip {
  color: yellow;
  background: #005c4b;
}

::v-deep(.enviadoparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

::v-deep(.enviadoparachipMenu) {
  .p-chip-text {
    font-size: 13px;
  }
}

.enviadoparachip {
  color: rgb(133, 255, 225);
  background: #005c4b;
}

::v-deep(.atencionparachip) {
  .p-chip-text {
    color: white;
    font-size: 13px;
  }
}

.atencionparachip {
  color: rgb(255, 144, 80);
  background: #005c4b;
}

.fondoparachips {
  background: #0b141a;
}

/* Clases para las Chips de Asistentes */

::v-deep(.asistentestable) {
  .p-datatable {
    color: white;
    background: #0b141a;

    .p-column-title {
      color: white;
      background: #0b141a;
    }

  }
}

::v-deep(.botonasistentes) {
  .p-button-label {
    font-size: 12px;
  }
}

.botonasistentes {
  color: white;
  background: #005c4b;
}


.fondoasistentes {
  background: #202c33;
}

.fondoasistentes2 {
  background: #2a3942;
}

.datosOficioEncabezado {
  color: grey;
  font-size: 12px;
}

.datosOficioEncabezadoContenido {
  color: black;
  font-size: 15px;
}

.datosOficioEncabezadoContenido2 {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.datosOficioEncabezadoContenido3 {
  color: black;
  font-size: 12px;
}

.coloraux1 {
  background: rgb(209, 165, 19);
  font-weight: lighter;
}

.coloraux3 {
  background: rgb(90, 90, 90);
  font-weight: lighter;
}


// Estilos del formulario
::v-deep(.inputGenerales) {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
}

::v-deep(.dropDownVistas) {
  .p-dropdown-label {
    color: black;
    font-size: 13px;
    font-weight: bold;
  }

  .p-dropdown-trigger {
    display: none;
  }
}

.leyendaDocumento {
  color: black;
  opacity: 0.9;
  font-size: 25px;
}

::v-deep(.inputGeneralesCalendar) {
  .p-inputtext {
    color: black;
    font-size: 13px;
    font-weight: bold;
  }
}

::v-deep(.editorCuerpo) {
  .p-editor-content {
    background: rgba(0, 0, 0, 0.5);
    color: black;
    font-size: 18px;
  }
}

//Responsive



@media only screen and (max-width: 600px) {

  .coloraux1 {
    font-size: 8px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }

  ::v-deep(.dropdownsResp) {
    .p-dropdown {
      font-size: 11px !important;
    }

    .p-dropdown-label {
      font-size: 11px !important;
    }
  }

  ::v-deep(.chipsForm) {
    .p-chip-text {
      font-size: 15px !important;
    }
  }

  ::v-deep(.textoForm) {
    font-size: 11px !important;

    .p-inputtext {
      font-size: 11px !important;
    }
  }

}

@media only screen and (min-width: 600px) {

  .coloraux1 {
    font-size: 8px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 768px) {

  .coloraux1 {
    font-size: 10px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 992px) {

  .coloraux1 {
    font-size: 12px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1200px) {

  .coloraux1 {
    font-size: 13px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1515px) {

  .coloraux1 {
    font-size: 15px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

}


/*
  @media only screen and (max-width: 600px) {
  
  
  }
  
  @media only screen and (min-width: 600px) {
  
  
  }
  
  @media only screen and (min-width: 768px) {
  
  
  }
  
  @media only screen and (min-width: 992px) {
  
  
  }
  
  @media only screen and (min-width: 1200px) {
  
  
  }
  
  @media only screen and (min-width: 1515px) {
  
  
  }
  */
</style>
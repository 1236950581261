export const setFirmantes = (state, firmantes) => {
  state.firmantes = firmantes.data;
};

export const setDependenciesVBCCP = (state, dependenciesVBCCP) => {
  state.dependenciesVBCCP = dependenciesVBCCP.data;
}

// export const setOfficesVBCCP = (state, officesVBCCP) => {
//   state.officesVBCCP = officesVBCCP.data;
// }

export const setFichas = (state, fichas) => {
  state.fichas = fichas.data;
};
export const setFichasRecepcionPendientes = (state, fichasRecepcionPendientes) => {
  state.fichasRecepcionPendientes = fichasRecepcionPendientes.data;
};
export const setFichasRecepcionAtencionA= (state, fichasRecepcionAtencionA) => {
  state.fichasRecepcionAtencionA = fichasRecepcionAtencionA.data;
};
export const setFichasRecepcionTurnados= (state, fichasRecepcionTurnados) => {
  state.fichasRecepcionTurnados = fichasRecepcionTurnados.data;
};
export const setFichasRecepcionArchivados = (state, fichasRecepcionArchivados) => {
  state.fichasRecepcionArchivados = fichasRecepcionArchivados.data;
};
export const setFichasRecepcionArchivadosAtencion = (state, fichasRecepcionArchivadosAtencion) => {
  state.fichasRecepcionArchivadosAtencion = fichasRecepcionArchivadosAtencion.data;
};
export const setFichasRecepcionArchivadosTurnados = (state, fichasRecepcionArchivadosTurnados) => {
  state.fichasRecepcionArchivadosTurnados = fichasRecepcionArchivadosTurnados.data;
};
export const setFichasRecepcionTurnadosUsuario = (state, fichasRecepcionTurnadosUsuario) => {
  state.fichasRecepcionTurnadosUsuario = fichasRecepcionTurnadosUsuario.data;
};
export const setFichasRecepcionTurnadosDestinatario = (state, fichasRecepcionTurnadosDestinatario) => {
  state.fichasRecepcionTurnadosDestinatario = fichasRecepcionTurnadosDestinatario.data;
};
export const setFichasFirmaR = (state, fichasFirmarR) => {
  state.fichasFirmarR = fichasFirmarR.data;
};
export const setFichasFirmadasR = (state,fichasFirmadasR) => {
  state.fichasFirmadasR = fichasFirmadasR.data;
};
export const setFichasFirmaC = (state, fichasFirmarC) => {
  state.fichasFirmarC = fichasFirmarC.data;
};
export const setFichasFirmadasC = (state,fichasFirmadasC) => {
  state.fichasFirmadasC = fichasFirmadasC.data;
};
export const setVoBoByUsuario = (state, VoBoyCcp) => {
  state.VoBoyCcp = VoBoyCcp.data;
};
export const setDependencias = (state, dependencias) => {
  state.dependencias = dependencias.data;
};

export const setLeyenda = (state, leyenda) => {
  state.leyendas = leyenda.data;
};

export const setComentariosVB = (state, comentariosVB ) =>{
  state.comentariosVB = comentariosVB.data;
};

export const setTitulares = (state, titulares) => {
  state.titulares = titulares;
};
export const setFuncionarios = (state, funcionarios) => {
  state.funcionarios = funcionarios.data;
};

export const setFuncionariosTurnar = (state, turnados) => {
  state.turnados = turnados.data;
}

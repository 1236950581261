import api from "@/api/laravelApi";


export const getTitulares = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/superAdmin/listarUsuarios`);

    const { status, data, message  } = info;

    if (status != "success") return { ok: false, message };
    commit("setTitulares", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getDashboard = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/superAdmin/listado`);

    const { status, data, message  } = info;

    if (status != "success") return { ok: false, message };
    commit("getDashboard", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const validarCurpTitular = async (_, form) => {
  try {
    const data = await api.post(`/admin/validateCurpAdmin`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const crearNuevoTitular = async (_, form) => {
  try {
    const data = await api.post(`/auth/registroTitular`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const bajaAltaUsuario = async (_, form) => {
  try {
    const data = await api.post(`/superAdmin/habilitarUsuario`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const editarUsuario = async (_, form) => {
  try {
    const data = await api.post(`/superAdmin/editarUsuario`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg, error:err.response };
  }
};



// export const validarCurpUsuario = async (_, form) => {
//   try {
//     const data = await api.post(`/admin/validateCurpAdmin`, form);
//     return data;
//   } catch (err) {
//     let msg;
//     if (err.response) {
//       msg = err.response.message;
//     } else if (err.request) {
//       msg = err.request;
//     } else {
//       msg = err.message;
//     }
//     return { ok: false, message: msg };
//   }
// };





import { computed, ref } from "vue";
import { useStore } from "vuex";

import store from "@/store";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useProyectosConsejoList = () => {
  const storage = useStore();
  const fichaM = ref();

    //Funciones para los contadores de firma oficios
    const contadorOficiosFirmar = ref(0);
    const contadorOficiosFirmarAlt = ref(0);
    const barraOficiosFirmar = async () => {

      let arregloOficiosFirmar = store.state.oficialia.fichasFirmarC
        // console.log(arregloOficiosFirmar);

      const oficiosFirmarAlt = limpiarArray => limpiarArray.status == 5 ;
      const arrayLimpio = arregloOficiosFirmar.map(oficiosFirmarAlt);
        //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmar.value = siHayEstatus;
      contadorOficiosFirmarAlt.value = noHayEstatus;

    }

    const contadorOficiosFirmar2 = ref(0);
    const contadorOficiosFirmar2Alt = ref(0);
    const barraOficiosFirmar2 = async () => {

      let arregloOficiosFirmar2 = store.state.oficialia.fichasFirmarC
        // console.log(arregloOficiosFirmar);

      const oficiosFirmar2Alt = limpiarArray => limpiarArray.status == 5 && limpiarArray.idCaso == 1;
      const arrayLimpio = arregloOficiosFirmar2.map(oficiosFirmar2Alt);
        //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmar2.value = siHayEstatus;
      contadorOficiosFirmar2Alt.value = noHayEstatus;

    }

    const contadorOficiosFirmados = ref(0);
    const contadorOficiosFirmadosAlt = ref(0);
    const barraOficiosFirmados = async () => {
      let arregloOficiosFirmados = store.state.oficialia.fichasFirmadasC
      // console.log(arregloOficiosVBpendientes);

      const oficiosFirmadosAlt = limpiarArray => limpiarArray.idCaso == 1 || limpiarArray.idCaso == 2;
      const arrayLimpio = arregloOficiosFirmados.map(oficiosFirmadosAlt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmados.value = siHayEstatus;
      contadorOficiosFirmadosAlt.value = noHayEstatus;

    }

    const contadorOficiosFirmados2 = ref(0);
    const contadorOficiosFirmados2Alt = ref(0);
    const barraOficiosFirmados2 = async () => {
      let arregloOficiosFirmados2 = store.state.oficialia.fichasFirmadasC
      // console.log(arregloOficiosVBpendientes);

      const oficiosFirmados2Alt = limpiarArray => limpiarArray.idCaso == 1 || limpiarArray.idCaso == 2;
      const arrayLimpio = arregloOficiosFirmados2.map(oficiosFirmados2Alt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmados2.value = siHayEstatus;
      contadorOficiosFirmados2Alt.value = noHayEstatus;

    }
  //Funciones para los contadores de firma oficios

  //Funciones para los contadores de recepcion oficios
  const contadorOficiosPendientes = ref(0);
  const contadorOficiosPendientesAlt = ref(0);
  const barraOficiosPendientes = async () => {
    let arregloOficiosPendientes = store.state.oficialia.fichasRecepcionPendientes
    // console.log(arregloOficiosVBpendientes);

    const oficiosPendientesAlt = limpiarArray => limpiarArray.status == 7;
    const arrayLimpio = arregloOficiosPendientes.map(oficiosPendientesAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosPendientes.value = siHayEstatus;
    contadorOficiosPendientesAlt.value = noHayEstatus;

  }

  const contadorOficiosAtencion = ref(0);
  const contadorOficiosAtencionAlt = ref(0);
  const barraOficiosAtencion = async () => {
    let arregloOficiosAtencion = store.state.oficialia.fichasRecepcionAtencionA
    // console.log(arregloOficiosVBpendientes);

    const oficiosAtencionAlt = limpiarArray => limpiarArray.status == 7;
    const arrayLimpio = arregloOficiosAtencion.map(oficiosAtencionAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosAtencion.value = siHayEstatus;
    contadorOficiosAtencionAlt.value = noHayEstatus;

  }
  //Funciones para los contadores de recepcion oficios

  //Funciones para oficios turnados
  const contadorOficiosTurnados = ref(0);
  const contadorOficiosTurnadosAlt = ref(0);
  const barraOficiosTurnados = async () => {
    let arregloOficiosTurnados = store.state.oficialia.fichasRecepcionTurnadosUsuario
    // console.log(arregloOficiosVBpendientes);

    const oficiosTurnadosAlt = limpiarArray => limpiarArray.status == 7;
    const arrayLimpio = arregloOficiosTurnados.map(oficiosTurnadosAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosTurnados.value = siHayEstatus;
    contadorOficiosTurnadosAlt.value = noHayEstatus;

  }

  const contadorOficiosTurnadosDestinatario = ref(0);
  const contadorOficiosTurnadosDestinatarioAlt = ref(0);
  const barraOficiosTurnadosDestinatario = async () => {
    let arregloOficiosTurnadosDestinatario = store.state.oficialia.fichasRecepcionTurnadosDestinatario
    // console.log(arregloOficiosVBpendientes);

    const oficiosTurnadosDestinatarioAlt = limpiarArray => limpiarArray.status == 7;
    const arrayLimpio = arregloOficiosTurnadosDestinatario.map(oficiosTurnadosDestinatarioAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosTurnadosDestinatario.value = siHayEstatus;
    contadorOficiosTurnadosDestinatarioAlt.value = noHayEstatus;

  }
  //Funciones para oficios turnados

  //Funciones para oficios archivados
  const contadorOficiosArchivadosRecepcion = ref(0);
  const contadorOficiosArchivadosRecepcionAlt = ref(0);
  const barraOficiosArchivadosRecepcion = async () => {
    let arregloOficiosArchivadosRecepcion = store.state.oficialia.fichasRecepcionArchivados
    // console.log(arregloOficiosVBpendientes);

    const oficiosArchivadosRecepcionAlt = limpiarArray => limpiarArray.status == 7 ;
    const arrayLimpio = arregloOficiosArchivadosRecepcion.map(oficiosArchivadosRecepcionAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosArchivadosRecepcion.value = siHayEstatus;
    contadorOficiosArchivadosRecepcionAlt.value = noHayEstatus;

  }

  const contadorOficiosArchivadosRecepcionAtencion = ref(0);
  const contadorOficiosArchivadosRecepcionAtencionAlt = ref(0);
  const barraOficiosArchivadosRecepcionAtencion = async () => {
    let arregloOficiosArchivadosRecepcionAtencion = store.state.oficialia.fichasRecepcionArchivadosAtencion
    // console.log(arregloOficiosVBpendientes);

    const oficiosArchivadosRecepcionAtencionAlt = limpiarArray => limpiarArray.status == 7 ;
    const arrayLimpio = arregloOficiosArchivadosRecepcionAtencion.map(oficiosArchivadosRecepcionAtencionAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosArchivadosRecepcionAtencion.value = siHayEstatus;
    contadorOficiosArchivadosRecepcionAtencionAlt.value = noHayEstatus;

  }

  const contadorOficiosArchivadosRecepcionTurnados = ref(0);
  const contadorOficiosArchivadosRecepcionTurnadosAlt = ref(0);
  const barraOficiosArchivadosRecepcionTurnados = async () => {
    let arregloOficiosArchivadosRecepcionTurnados = store.state.oficialia.fichasRecepcionArchivadosTurnados
    // console.log(arregloOficiosVBpendientes);

    const oficiosArchivadosRecepcionTurnadosAlt = limpiarArray => limpiarArray.status == 10 ;
    const arrayLimpio = arregloOficiosArchivadosRecepcionTurnados.map(oficiosArchivadosRecepcionTurnadosAlt);
    //  console.log(arrayLimpio);

    let arrayLimpioAlt = Object.values(arrayLimpio);
    //  console.log(arrayLimpioAlt);
    let siHayEstatus = 0;
    let noHayEstatus = 0;
    for(let i = 0; i < arrayLimpioAlt.length; i++){
  
      if( arrayLimpioAlt[i] == true){
       siHayEstatus = siHayEstatus + 1;
      }else{
       noHayEstatus = noHayEstatus + 1;
      }
    }

    contadorOficiosArchivadosRecepcionTurnados.value = siHayEstatus;
    contadorOficiosArchivadosRecepcionTurnadosAlt.value = noHayEstatus;

  }

  //Funciones para oficios archivados

  const columnasPendientes = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    { field: "dependenciaFirmante", header: "Dependencia que envia" },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "Oficina que envia" },
    { field: "firmante", header: "Te envio" },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    {
      field: "dependenciaDestinatario",
      header: "Dependencia Destinatario",
      hidden: true,
    },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    {
      field: "oficinaDestinatario",
      header: "Oficina Destinatario",
      hidden: true,
    },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },

    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },
    { field: "anexo", header: "Anexo Oficio", hidden: true },
  ]);

  const columnasAtencion = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    { field: "dependenciaFirmante", header: "Dependencia que envia" },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "Oficina que envia" },
    { field: "firmante", header: "Te Envio" },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    {
      field: "dependenciaDestinatario",
      header: "Dependencia Destinatario",
      hidden: true,
    },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    {
      field: "oficinaDestinatario",
      header: "Oficina Destinatario",
      hidden: true,
    },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },

    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },
    { field: "anexo", header: "Anexo Oficio", hidden: true },
  ]);

  const columnasFirma = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },
    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },
    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },
    {
      field: "dependenciaFirmante",
      header: "dependenciaFirmante",
      hidden: true,
    },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "oficinaFirmante", hidden: true },
    { field: "firmante", header: "firmante", hidden: true },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    { field: "dependenciaDestinatario", header: "Dependencia destinatario" },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    { field: "oficinaDestinatario", header: "Oficina Destinatario" },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },
    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },
    { field: "stringStatus", header: "Estado", hidden: true },
    { field: "vobo_ccp", header: "Visto Bueno", hidden: true },
    { field: "idCaso", header: "idCaso", hidden: true },
  ]);

  const columnasArchivados = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    { field: "dependenciaFirmante", header: "Dependencia que envia" },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "Oficina que envia" },
    { field: "firmante", header: "Te envio" },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    {
      field: "dependenciaDestinatario",
      header: "Dependencia Destinatario",
      hidden: true,
    },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    {
      field: "oficinaDestinatario",
      header: "Oficina Destinatario",
      hidden: true,
    },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },

    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },
    { field: "anexo", header: "Anexo Oficio", hidden: true },
  ]);

  const columnasTurnados = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },
    { field: "razonTurnante", header: "Razón" },
    { field: "dependenciaTurnante", header: "Dependencia que turnó" },
    { field: "oficinaTurnante", header: "Oficina que turnó"},
    { field: "nombreTurnante", header: "Te turnó" },
  ]);


  const columnasTurnados2 = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },
    { field: "razonPersonaQueTurna", header: "Razón" },
    { field: "dependenciaTurnadoA", header: "Dependencia que turnaste" },
    { field: "oficinaTurnadoA", header: "Oficina que turnaste" },
    { field: "nombreTurnadoA", header: "Turnaste a" },
  ]);


  const columnsVB = ref([
    { field: "numeroOficio", header: "Número oficio", hidden: true },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    { field: "dependenciaFirmante", header: "Dependencia que envia" },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "Oficina que envia" },
    { field: "firmante", header: "Te envio" },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    {
      field: "dependenciaDestinatario",
      header: "Dependencia Destinatario",
      hidden: true,
    },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    {
      field: "oficinaDestinatario",
      header: "Oficina Destinatario",
      hidden: true,
    },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },

    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },

  ]);

  const filtersPendientes = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersAtencionA = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersTurnados = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [
        [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      ],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersTurnados2 = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [
        [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      ],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersArchivados = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    recepcion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersFirma = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idCaso: {
      operator: FilterOperator.OR,
      constraints: [{ value: 1, matchMode: FilterMatchMode.EQUALS }],
    }

  });

  const filtersFirmados = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idCaso: {
      operator: FilterOperator.OR,
      constraints: [{ value: 1, matchMode: FilterMatchMode.EQUALS },
                    { value: 2, matchMode: FilterMatchMode.EQUALS },
                   ],
    }
  });

  const filtersVB = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 3, matchMode: FilterMatchMode.EQUALS }],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersCCP = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 16, matchMode: FilterMatchMode.EQUALS }],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersSalida = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: 7, matchMode: FilterMatchMode.EQUALS },
        { value: 10, matchMode: FilterMatchMode.EQUALS },
      ],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const filtersOficiosAtendidos = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    fechaOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ciudadFecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expediente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idLeyenda: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cuerpo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: 5, matchMode: FilterMatchMode.EQUALS },
        { value: 4, matchMode: FilterMatchMode.EQUALS },
      ],
    },

    idOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    adjuntado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoFirmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subdependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    destinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    DependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubdependenciaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    OficinaAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    atencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    cargoAtencionA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });


  const getFichasRecepcionPendientes = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionPendientes",
      store.state.auth.user.oficina_id
    );
    return resp;
  };
  const getFichasRecepcionAtencionA = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionAtencionA",
      store.state.auth.user.oficina_id
    );
    return resp;
  };
  const getFichasRecepcionTurnados = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionTurnados",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasRecepcionArchivados = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionArchivados",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasRecepcionArchivadosAtencion = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionArchivadosAtencion",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasRecepcionArchivadosTurnados = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionArchivadosTurnados",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasRecepcionTurnadosUsuario = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionTurnadosUsuario",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasRecepcionTurnadosDestinatario = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasRecepcionTurnadosDestinatario",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasFirmaR = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasFirmaR",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasFirmadasR = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasFirmadasR",
      store.state.auth.user.oficina_id
    );
    return resp;
  };



  return {
    getFichasRecepcionTurnadosDestinatario,
    getFichasRecepcionTurnadosUsuario,
    getFichasRecepcionPendientes,
    getFichasRecepcionAtencionA,
    getFichasRecepcionTurnados,
    getFichasRecepcionArchivados,
    getFichasRecepcionArchivadosAtencion,
    getFichasRecepcionArchivadosTurnados,
    getFichasFirmaR,
    getFichasFirmadasR,
    fichasRecepcionPendientes: computed(
      () => storage.getters["oficialia/getFichasRecepcionPendientes"]
    ),
    fichasRecepcionAtencion: computed(
      () => storage.getters["oficialia/getFichasRecepcionAtencionA"]
    ),
    fichasRecepcionTurnados: computed(
      () => storage.getters["oficialia/getFichasRecepcionTurnados"]
    ),
    fichasRecepcionArchivados: computed(
      () => storage.getters["oficialia/getFichasRecepcionArchivados"]
    ),
    fichasRecepcionArchivadosAtencion: computed(
      () => storage.getters["oficialia/getFichasRecepcionArchivadosAtencion"]
    ),
    fichasRecepcionArchivadosTurnados: computed(
      () => storage.getters["oficialia/getFichasRecepcionArchivadosTurnados"]
    ),

    fichasFirmaR: computed(() => storage.getters["oficialia/getFichasFirmaR"]),
    fichasFirmadosR: computed(() => store.getters["oficialia/getFichasFirmadasR"]),
    
    fichasRecepcionTurnadosUsuario: computed(
      () => storage.getters["oficialia/getFichasRecepcionTurnadosUsuario"]
    ),
    fichasRecepcionTurnadosDestinatario: computed(
      () => storage.getters["oficialia/getFichasRecepcionTurnadosDestinatario"]
    ),

    filtersPendientes,
    filtersAtencionA,
    filtersTurnados,
    filtersTurnados2,
    filtersFirma,
    filtersFirmados,
    filtersVB,
    filtersCCP,
    filtersOficiosAtendidos,
    filtersSalida,
    filtersArchivados,
    columnasTurnados,
    columnasTurnados2,
    columnasPendientes,
    columnasAtencion,
    columnasFirma,
    columnasArchivados,
    columnsVB,
    fichaM,

    contadorOficiosFirmar,
    contadorOficiosFirmarAlt,
    barraOficiosFirmar,

    contadorOficiosFirmados,
    contadorOficiosFirmadosAlt,
    barraOficiosFirmados,

    contadorOficiosFirmar2,
    contadorOficiosFirmar2Alt,
    barraOficiosFirmar2,

    contadorOficiosFirmados2,
    contadorOficiosFirmados2Alt,
    barraOficiosFirmados2,

    contadorOficiosPendientes,
    contadorOficiosPendientesAlt,
    barraOficiosPendientes,
    
    contadorOficiosAtencion,
    contadorOficiosAtencionAlt,
    barraOficiosAtencion,

    contadorOficiosTurnados,
    contadorOficiosTurnadosAlt,
    barraOficiosTurnados,

    contadorOficiosTurnadosDestinatario,
    contadorOficiosTurnadosDestinatarioAlt,
    barraOficiosTurnadosDestinatario,

    contadorOficiosArchivadosRecepcion,
    contadorOficiosArchivadosRecepcionAlt,
    barraOficiosArchivadosRecepcion,

    contadorOficiosArchivadosRecepcionAtencion,
    contadorOficiosArchivadosRecepcionAtencionAlt,
    barraOficiosArchivadosRecepcionAtencion,

    contadorOficiosArchivadosRecepcionTurnados,
    contadorOficiosArchivadosRecepcionTurnadosAlt,
    barraOficiosArchivadosRecepcionTurnados,

  };
};

export default useProyectosConsejoList;

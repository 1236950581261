import { ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useUsuariosLista from "./useUsuariosLista";
import { useRouter } from "vue-router";

const useDialogCrearUsuarios = () => {
    const displayModal = ref(false);
    const router = useRouter();

    const { getUsuarios, getUsuariosInfo, usuariosinfo } = useUsuariosLista();

    const store = useStore();

    // Funcion para crear Usuario
    const crearNuevoUsuario = async (form) => {
        const { data: info } = await store.dispatch("usuarios/crearNuevoUsuario", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getUsuarios();
            Swal.fire("Usuario Registrado Exitosamente", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al Registrar el Usuario", message, "error");
        }
    };

    // Funcion para crear Titular
    const crearNuevoTitular = async (form) => {
        const { data: info } = await store.dispatch("usuarios/crearNuevoTitular", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            Swal.fire("Titular Registrado Exitosamente", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al Registrar el Titular", message, "error");
        }
    };

    const recuperarContrasenaTitular = async (form) => {
        const data = await store.dispatch("usuarios/recuperarContrasenaTitular", form);
        const info = data
        const { status, message, error } = info;
        if (status === 'success') {
            displayModal.value = false;
            Swal.fire("Contraseña enviada al correo", message, "success");
            return true
        } else {
            if (error.status === "info") {
                if (error.message.email.length !== 0) {
                    // displayModal.value = false;
                    Swal.fire("Se ha producido un error", "Escribe un correo válido", "error");
                }
            } else {
                displayModal.value = false;
                Swal.fire("Se ha producido un error", message, "error");
            }
            return false
        }
    };

    const validarTokenTitular = async (form) => {
        const { data: info } = await store.dispatch("usuarios/validarTokenTitular", form);
        // console.log(info);
        // const { message } = info;
        if (info == undefined) {
            router.push('/');
            Swal.fire("El token ha expirado", "error");
        } else {
            console.log("Token Validado");
        }
    };

    const cambiarContrasenaTitular = async (form) => {
        const { data: info } = await store.dispatch("usuarios/cambiarContrasenaTitular", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            Swal.fire("Contraseña cambiada", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha producido un error", message, "error");
        }
    };

    // Funcion para baja Usuario
    const bajaNuevoUsuario = async (form) => {
        const { data: info } = await store.dispatch("usuarios/bajaNuevoUsuario", form);
        const { status, message } = info;
        if (status === 'success') {
            displayModal.value = false;
            await getUsuarios();
            Swal.fire("El Usuario ha sido dado de Baja", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha Producido un Error al dar de Baja el Usuario", message, "error");
        }
    };

    // Validar curp usuario
    const validarCurpUsuario = async (form) => {
        const data = await store.dispatch("usuarios/validarCurpUsuario", form);
        return data;
    };

    //Validar datos del usuario logeado
    const usuarioNombre = ref();
    const usuarioCargo = ref();
    const usuarioOficina = ref();
    const usuarioAliasOficina = ref();
    const usuarioMiTitular = ref();

    const validarDatosUsuario = async () => {

        if ((usuariosinfo.value.nombre == "" || usuariosinfo.value.nombre == null || usuariosinfo.value.nombre == undefined) && (usuariosinfo.value.cargo == "" || usuariosinfo.value.cargo == null || usuariosinfo.value.cargo == undefined) && (usuariosinfo.value.oficina == "" || usuariosinfo.value.oficina == null || usuariosinfo.value.oficina == undefined) && (usuariosinfo.value.aliasOficina == "" || usuariosinfo.value.aliasOficina == null || usuariosinfo.value.aliasOficina == undefined) && (usuariosinfo.value.titular == "" || usuariosinfo.value.titular == null || usuariosinfo.value.titular == undefined)) {
            usuarioNombre.value = "No hay Datos";
            usuarioCargo.value = "No hay Datos";
            usuarioOficina.value = "No hay Datos";
            usuarioAliasOficina.value = "No hay Datos";
            usuarioMiTitular.value = "No hay titular";
        } else {
            usuarioNombre.value = usuariosinfo.value.nombre;
            usuarioCargo.value = usuariosinfo.value.cargo;
            usuarioOficina.value = usuariosinfo.value.oficina;
            usuarioAliasOficina.value = usuariosinfo.value.aliasOficina;
            usuarioMiTitular.value = usuariosinfo.value.titular;
        }
    }

    // Funcion para crear Usuario
    const cambiarAliasOficina = async (form) => {
        const { status, message } = await store.dispatch("usuarios/cambiarAliasOficina", form);

        if (status === 'success') {
            displayModal.value = false;

            await getUsuarios();
            await getUsuariosInfo();
            await validarDatosUsuario();
            Swal.fire("Alias de oficina cambiado exitosamente", message, "success");
        } else {
            displayModal.value = false;
            Swal.fire("Se ha producido un error al cambiar la oficina", message, "error");
        }
    };


    return {
        crearNuevoUsuario,
        bajaNuevoUsuario,
        validarCurpUsuario,
        cambiarAliasOficina,
        validarDatosUsuario,

        usuarioNombre,
        usuarioCargo,
        usuarioOficina,
        usuarioAliasOficina,
        usuarioMiTitular,


        displayModal,

        getUsuariosInfo,
        crearNuevoTitular,
        recuperarContrasenaTitular,
        validarTokenTitular,
        cambiarContrasenaTitular,
    };
};

export default useDialogCrearUsuarios;
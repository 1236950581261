<template>

    <ButtonVue 
      icon="fa-solid fa-circle-info fa-bounce"
      style="--fa-animation-iteration-count: 5; --fa-animation-duration: 2.5s;"
      v-tooltip.top="'Ayuda para Archivar los Oficios'"   
      class="p-button-rounded p-button-secondary p-button-text botonAyuda"
      @click="openModal" 
    />
  
    <DialogVue 
      header="Pasos para turnar oficios" 
      v-model:visible="displayModal" 
      :modal="true" 
      :closable="true"
      class="flex align-items-center justify-content-center surface-0 shadow-8"
      :breakpoints="{ '1560px': '75vw', '640px': '90vw' }" 
      :style="{ width: '50vw' }">
  
      <div class="grid">
  
      <!-- Tablas -->
      <div class="col-12">
        <TabViewVue
          class="tabview-custom tabsTablas" 
          ref="tabview4"
        >
  
  <!-- Tabla de Turnados Recibidos -->
  <TabPanelVue>
    <template #header>
      <div class="grid titulosPestanas">
        <div class="col-12 text-center">
          <i 
          class="fa-solid fa-folder-open fa-bounce mr-2"
          style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
          ></i>
          <span>Oficios que te turnaron</span>
        </div>
      </div>
    </template>
  
    <div class="grid">
  
      <!-- Paso 1 -->
      <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
        <DividerVue align="center">
          <span class="pr-2">Oficios que te han turnado</span>
        </DividerVue>
        
        <ButtonVue
          label="Oficio turnado recibido"
          icon="fa-solid fa-paperclip fa-shake"
          style="--fa-animation-duration: 5s;"
          class="p-button-sm p-button-sm p-button-secondary splitRecepcionEstado10 m-2 w-full" 
        />
  
        <ButtonVue
          label="Oficio turnado recibido"
          icon="fa-solid fa-file-circle-exclamation fa-shake"
          style="--fa-animation-duration: 5s;"
          class="p-button-sm p-button-secondary splitRecepcionEstado10 m-2 w-full" 
        />
  
        <hr/>
        
        <p class="textHelp">
          Cuando alguien te ha turnado un oficio se mostrará en esta pestaña, puedes contestarlo con un oficio adjunto o creandolo, o turnarlo nuevamente si no te corresponde.
        </p>
  
      </div>
      <!-- Paso 1 -->
  
      <!-- Paso Adicional -->
      <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
        <DividerVue align="center">
          <span class="pr-2">Opción A:</span>
        </DividerVue>
        
        <ButtonVue
          label="Oficio turnado nuevamente"
          icon="fa-solid fa-share-from-square fa-flip"
          style="--fa-animation-duration: 5s;"
          class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
        />
  
        <ButtonVue
          label="Oficio turnado nuevamente"
          icon="fa-regular fa-share-from-square fa-flip"
          style="--fa-animation-duration: 5s;"
          class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
        />
  
        <hr/>
        
        <p class="textHelp">
          Turnaste el oficio a otra persona, ahora le aparecerá en su bandeja correspondiente.
        </p>
  
      </div>
      <!-- Paso Adicional -->
  
       <!-- Paso Adicional -->
       <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
        <DividerVue align="center">
          <span class="pr-2">Opción B:</span>
        </DividerVue>
        
        <ButtonVue
          label="Oficio contestado"
          icon="fa-solid fa-check-to-slot fa-bounce"
          style="--fa-animation-duration: 15s;"
          class="p-button-sm p-button-secondary splitGeneracionEstado8 m-2 w-full" 
        />
  
        <ButtonVue
          label="Oficio contestado"
          icon="fa-solid fa-file-circle-check fa-bounce"
          style="--fa-animation-duration: 15s;"
          class="p-button-sm p-button-secondary splitGeneracionEstado8 m-2 w-full" 
        />
  
        <hr/>
        
        <p class="textHelp">
          Cuando contestas el oficio turnado ahora solo deberás repetir los pasos para firmar el oficio.
        </p>
  
      </div>
      <!-- Paso Adicional -->
  
    </div>
  
  </TabPanelVue>
  <!-- Tabla de Turnados Recibidos -->
        
        </TabViewVue>
      </div>
      <!-- Tablas -->
      </div>
  
    </DialogVue>
  
  </template>
  
  <script>
  import { ref } from 'vue';
  export default {
  
    setup(){
      const displayModal = ref(false);
  
      const openModal = () => {
          displayModal.value = true;
      };
  
      const closeModal = () => {
          displayModal.value = false;
      };
  
    
  
      return {
        displayModal,
        openModal,
        closeModal,
      }
    }
  
    
  }
  
  </script>
  
  <style scoped lang="scss">
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:13px;
      }
    }
  .coloraux1 {
    background: rgb(209, 165, 19);
    font-weight: lighter;
  }
  
  ::v-deep(.botonGobColor){ 
    background-color:#4a001f;
    border-color: #80245f;
    transition: ease 1s;
    .p-button-icon{
      color:rgb(255, 255, 255);
    }
    .p-button-label{
      font-size:15px;
    }
  }
  
  ::v-deep(.botonGobColor:hover){ 
    background-color:#80245f;
    border-color: #4a001f;
    transition: ease 1s;
    .p-button-icon{
      color:rgb(255, 255, 255);
    }
    .p-button-label{
      font-size:15px;
    }
  }
  
  ::v-deep(.botonGobColorCerrar){ 
    background-color:#6d807f;
    border-color: #454b4b;
    transition: ease 1s;
    .p-button-icon{
      color:rgb(255, 255, 255);
    }
    .p-button-label{
      font-size:15px;
    }
  }
  
  ::v-deep(.botonGobColorCerrar:hover){ 
    background-color:#454b4b;
    border-color: #6d807f;
    transition: ease 1s;
    .p-button-icon{
      color:rgb(255, 255, 255);
    }
    .p-button-label{
      font-size:15px;
    }
  }
  
  ::v-deep(.pasoNumber1){
    background: #6c757d;
  }
  
  ::v-deep(.textHelp){
    font-size:12px;
  }
  
  
  //Split Button para los Estados
  ::v-deep(.splitRecepcionEstado7){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
      
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
    
    
  }
  
  ::v-deep(.splitRecepcionEstado7:hover){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
    
      background-color: #5b3caf!important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    
  }
  
  ::v-deep(.splitRecepcionEstado15){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
      
      background-color: #393939 !important;
      border-color: #393939  !important;
      transition: ease 1s;
    
    
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
    
      background-color: #131313  !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    
  }
  
  ::v-deep(.splitRecepcionEstadoDesarchivado){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
      
      background-color: #22669d !important;
      border-color: #22669d  !important;
      transition: ease 1s;
    
    
  }
  
  ::v-deep(.splitRecepcionEstadoDesarchivado:hover){
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
    
      background-color: #133957  !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado8){
  
  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;
  
  background-color: #406BD6 !important;
  border-color: #406BD6 !important;
  transition: ease 1s;


}

::v-deep(.splitGeneracionEstado8:hover){

  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #274181 !important;
  border-color: #ffffff !important;
  border-style: dotted;
  transition: ease 1s;

}
  
  @media only screen and (max-width: 600px){
    .titulosPestanas{
    height: 55px;
    width: 93px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:10.5px;
        transition: ease 1s;
      }
  }
  }
  
  
  </style>
  <template>

    <div class="card p-3">
      <div class="grid">
  
        <!-- Titulo Usuarios -->
        <div class="col-12 xl:col-3 lg:col-3 md:col-4 sm:col-7 p-2 flex justify-content-start">
          <h5 class="p-2 mt-2 tablaTitle">
            <i 
            class="fa-solid fa-users px-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
            ></i>
            Usuarios
          </h5>
        </div>
  
        <!-- Titulo Usuarios -->

        <!-- Tabs -->
        <div class="col-12 p-2">
          <div class="card">
  
              <TabViewVue 
                class="tabview-custom tabsTablas" 
                ref="tabview4"
              >
  
  <!-- Tabla de datos personales -->
  <TabPanelVue>
      <template #header>
  
        <div class="grid titulosPestanas" @click="getDatosPersonales">
          <div class="col-12 text-center">
            <i 
            class="fa-solid fa-user-tie fa-bounce mr-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
            ></i>
            <span>Datos Personales</span>
          </div>
        </div>
         
      </template>

      <div class="grid flex align-items-center justify-content-center">
        <div class="col-2 mt-5 text-center">
          <i class="fa-solid fa-circle-user fa-6x"
          ></i>
        </div>

        <div class="col-10">
          <h3 class="p-3">{{ usuarioNombre }}</h3>
          <h4 class="p-3">{{ usuarioCargo }}</h4>

          <h4 v-if="noesTitular" class="p-3"
            >
            <span>
              <i class="fa-solid fa-user-tie pr-2"></i>
              Jefe de Departamento:</span>
            {{ usuarioMiTitular }}
          </h4>

          <div class="p-inputgroup mx-1 my-2">
            <ChipVue label="Oficina:" icon="fa-solid fa-briefcase" />
            <p class="pt-3 px-5">{{ usuarioOficina }}</p>
          </div>

          <div class="p-inputgroup mx-1 my-2">
            <ChipVue label="Alias Oficina:" icon="fa-solid fa-briefcase" />
            <p class="pt-3 px-5">{{ usuarioAliasOficina }}</p>
            <dialogCambiarAlias v-if="esTitular"/>
          </div>

        </div>
      </div>
 
  </TabPanelVue>
  <!-- Tabla de datos personales -->
  
  <!-- Tabla de Usuarios -->
  <TabPanelVue v-if="esTitular">
      <template #header>
  
        <div class="grid titulosPestanas" @click="getDatosListaUsuarios">
          <div class="col-12 text-center">
            <i 
            class="fa-solid fa-users-line fa-bounce mr-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
            ></i>
            <span>Gestión de Usuarios</span>
          </div>
        </div>
          
      </template>
  
  <div class="card p-1">
    <DataTable 
        :value="usuarioslista" 
        responsiveLayout="scroll" 
        v-model:filters="filtersUsuarios" stripedRows
        :paginator="true"
        :rows="10" 
        paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]" 
        removableSort 
        dataKey="numeroOficio" 
        class="p-datatable-sm"
        style="width: 100%">
  
        <template #header>
          <div class="grid">

            <div class="col-6 p-1">
              <dialogCrearUsuario />
            </div>
  
            <div class="col-6 p-1">
              <div class="flex justify-content-center">
                <span class="p-input-icon-left m-2">
                  <i class="pi pi-search" />
                  <InputTextV 
                    v-model="filtersUsuarios['global'].value" 
                    placeholder="Buscar"
                    class="p-inputtext-sm" 
                  />
                </span>
              </div>
            </div>
            
          </div>
  
        </template>
  
        <template #empty>
  
          <InlineMessageVue severity="info" class="border-dashed border-300">
            No se encontraron resultados.
          </InlineMessageVue>
  
        </template>
  
        <template #loading>
          <InlineMessageVue severity="info" class="border-dashed border-300">
            Cargando....
          </InlineMessageVue>
        </template>
  
        <ColumnVue 
          v-for="col of columnasUsuarios" 
          :field="col.field" 
          :header="col.header" 
          :key="col.field" 
          :sortable="true"
          :hidden="col.hidden" 
          class="border-dashed border-300 tablaOficiosData"
        >
        </ColumnVue>
  
        <ColumnVue 
          :exportable="false" 
          style="width: 18rem" 
          header="Opciones"
          class="tablaOficiosData"
         
        >
          <template #body="slotProps">
            
            <SplitButtonVue
              
              icon="fa-solid fa-user" 
              style="--fa-animation-duration: 4s;"
              class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
              label="Opciones Usuario"
              v-tooltip.top="'Datos del Usuario'" :model="[

                {
                  label: 'Dar Usuario de Baja',
                  icon: 'pi pi-user-minus',
                  command: () => {
                    openDialogBajaUsuario(
                      'UsuarioBaja',
                      slotProps.data
                    );
                  },
                },
              
              ]" />
  
          </template>
        </ColumnVue>
    </DataTable>
  </div>
  
  </TabPanelVue>
  <!-- Tabla de Usuarios -->


  
              </TabViewVue>
          </div>
        </div>
        <!-- Tabs -->
  
      </div>
    </div>

    <dialogUsuarioBaja v-if="hasRole('UsuarioBaja')" />
  
  </template>
  
  <script>
  import { ref, onMounted, defineAsyncComponent, onUpdated, onBeforeUpdate, onBeforeUnmount} from 'vue';
  import DataTable from "primevue/datatable";
  import useUsuariosLista from "../composables/useUsuariosLista";
  import Swal from "sweetalert2";
  import dialogCrearUsuario from "../components/dialogUsuarioAlta";
  import dialogCambiarAlias from "../components/dialogCambiarAlias";
  import useOpenDialogs from "../composables/useOpenDialogs";
  import store from "@/store";//Store para la validacion

  import useDialogCrearUsuarios from "../composables/useDialogCrearUsuarios";
  
  
  export default {
  
      components:{
        DataTable,
        dialogCrearUsuario,
        dialogCambiarAlias,
        dialogUsuarioBaja: defineAsyncComponent(() =>
        import("../components/dialogUsuarioBaja.vue")
        ),
      },
  
    setup(){
  
          const { 
            openDialog,
            openDialogBajaUsuario,
            hasRole, 
            toggleDialog 
          } = useOpenDialogs();
          
          const variables = ref();
  
          //Tabla de para consumir
          const {
            columnasUsuarios,
            filtersUsuarios,
            getUsuarios,
            getUsuariosInfo,
            usuarioslista,
            usuariosinfo,

            } = useUsuariosLista();

            const {
              
              validarDatosUsuario,
              usuarioNombre,
              usuarioCargo,
              usuarioOficina,
              usuarioAliasOficina,
              usuarioMiTitular,
            } = useDialogCrearUsuarios();

           //Checar si es titular 
            const esTitular = ref();
            const validarTitular = async () => {
                if(store.state.auth.user.titular == 1){
                  esTitular.value = true;
                }else{
                  esTitular.value = false;
                }
            }

            const noesTitular = ref();
            const validarnoesTitular = async () => {
              if(store.state.auth.user.titular == 0){
                  noesTitular.value = true;
                }else{
                  noesTitular.value = false;
                }
            }

           const getDatosPersonales = async() =>{
                new Swal({
                title: "Cargando...",
                text: "Actualizando datos del usuario",
                allowOutsideClick: false,
                });

              Swal.showLoading();
              const { ok, message } = await getUsuariosInfo();
              if (!ok) Swal.fire("Error", message, "error");
              else {
                Swal.close();
              }
           }

           const getDatosListaUsuarios = async() =>{
                new Swal({
                title: "Cargando...",
                text: "Actualizando lista de usuarios",
                allowOutsideClick: false,
                });

              Swal.showLoading();
              const { ok, message } = await getUsuarios();
              if (!ok) Swal.fire("Error", message, "error");
              else {
                Swal.close();
              }
           }

          onUpdated(async () =>{
            await getUsuariosInfo();
            await validarDatosUsuario();
          })

          onBeforeUpdate(async () =>{
            await getUsuariosInfo();
          })

          onBeforeUnmount(async () =>{
            await getUsuariosInfo();
          })


          onMounted(async () => {
      
            new Swal({
              title: "Cargando...",
              text: "Actualizando Información de Usuarios",
              allowOutsideClick: false,
            });
            Swal.showLoading();

            await getUsuarios();
            await getUsuariosInfo();
            await validarTitular();
            await validarnoesTitular();
            await validarDatosUsuario();
            Swal.close();
          })
  
      return{
          openDialog,
          openDialogBajaUsuario,
          hasRole,
          toggleDialog,
          columnasUsuarios,
          filtersUsuarios,
          getUsuarios,
          getUsuariosInfo,
          usuarioslista,
          usuariosinfo,
          variables,
          dialogCrearUsuario,
          esTitular,
          validarTitular,
          validarDatosUsuario,

          usuarioNombre,
          usuarioCargo,
          usuarioOficina,
          usuarioAliasOficina,
          usuarioMiTitular,
          getDatosPersonales,
          getDatosListaUsuarios,
          noesTitular,
          validarnoesTitular,


      }
    },
  
  }
  </script>
  
  <style scoped lang="scss">
  
    .coloraux1{
     background: #4a001f;
     font-size: 13px;
     font-weight: lighter;
    }
  
    .coloraux2{
     background: #919191;
     font-size: 12px;
     font-weight: lighter;
    }
  
    .buttonAbrirOficio{
      background-color:#4a001f;
    }
  
    ::v-deep(.cardEntrada){
      background-color: rgb(185, 185, 185);
      .p-card-title{
        font-size:14px;
      }
      .p-card-content{
        font-size:12px;
      }
    }
  
    ::v-deep(.tablaOficiosData){
    font-size:13px;
    .p-column-title{
      font-size:13px
    }
  }
  
    ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:13px;
      }
    }
  
   .titleaccordion {
    font-size:13px
   }
  
  
    ::v-deep(.columnasOcultas){
      font-size:1px;
      padding-bottom: 1px;
      display:block;
      color:red;
      .p-datatable-thead{
        color:red;
        display:block;
      }
    }
  
    ::v-deep(.botonAyuda){
      .p-button-icon{
        font-size:25px;
        transition: ease 1s;
      }
    }
  
    ::v-deep(.botonAyuda:hover){
      .p-button-icon{
        font-size:25px;
        color:rgb(77, 77, 77);
        transition: ease 1s;
      }
    }
  
  @media only screen and (max-width: 600px) {
  
  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 15px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 15px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 10px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 12px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  }
  
  @media only screen and (min-width: 600px) {
  
  .tablaTitle {
    font-size: 18px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 55px;
    width: 85px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 18px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 18px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 13px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  }
  
  @media only screen and (min-width: 768px) {
  
  .tablaTitle {
    font-size: 14px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 20px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 20px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 13px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  }
  
  @media only screen and (min-width: 992px) {
  
  .tablaTitle {
    font-size: 16px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 21px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 21px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 13px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  
  }
  
  @media only screen and (min-width: 1200px) {
  
  
  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 50px;
    width: 180px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 22px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 22px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 16px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 16px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  
  }
  
  @media only screen and (min-width: 1515px) {
  
  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }
  
  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }
  
  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 25px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 25px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }
  
  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;
  
    .p-column-title {
      font-size: 13px
    }
  }
  
  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;
  
    }
  }
  
  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;
  
      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }
  
  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
  }
  
  ::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
  }
  
  }
  
  
  </style>
export default {
  component: () => import("@/layouts/MainLayout.vue"),
  props: () => {
    return {
      menuItems: [

        {
          label: "Usuarios",
          icon: "fa-solid fa-file-contract",
          name: "usuariosoficialia",
          to: "/usuarios",
          component: "@/modules/usuario/views/oficialiaUsuario",
        },

      ],
    };
  },
  children: [
    {
      path: "",
      component: () => import("@/modules/usuario/views/oficialiaUsuario.vue"),
    },
    {
      path: "historico",
      component: () => import("@/modules/usuario/views/oficialiaHistorico.vue"),
    },
    
  ],
};

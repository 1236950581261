<template>


      <ButtonVue
        icon="fa-solid fa-pen-to-square fa-bounce" 
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;" 
        severity="secondary"  
        size="small"
        v-tooltip.top="'Editar Alias de Oficina'"
        @click="openModal" 
      />

  
    <DialogVue 
      header="Alias de la oficina" 
      :visible="displayModal" 
      :modal="true" 
      :closable="false"
      class="flex align-items-center justify-content-center surface-0 shadow-8"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }" 
      :style="{ width: '75vw' }">
  
      <div class="grid mb-6">

        <!-- Divider -->
        <div class="col-12 mb-5">
          <DividerVue align="center">
            <span class="p-tag coloraux1 mx-2 px-2">
              <i class="fa-solid fa-building pr-2"></i>
              Datos de la oficina
            </span>
          </DividerVue>
        </div>
        <!-- Divider -->

        <!-- Nombre del alias de la oficina -->
        <div class="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 p-1">
          <div class="p-inputgroup mx-1 my-2">
            <ChipVue label="Alias oficina actual:" icon="fa-solid fa-briefcase" />
            <p class="pt-1 px-5">{{ usuarioAliasOficina }}</p>
          </div>
        </div>
        <!-- Nombre del alias de la oficina -->

        <!-- Nombre de la oficina -->
        <div class="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 p-1">
          <div class="p-inputgroup mx-1 my-2">
            <ChipVue label="Nombre de la oficina:" icon="fa-solid fa-briefcase" />
            <p class="pt-1 px-5">{{ usuarioOficina }}</p>
          </div>
        </div>
        <!-- Nombre de la oficina -->

        <!-- Form del Alias -->
        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 p-1 py-5">
          <div class="p-inputgroup mx-1">
              <ChipVue label="Nuevo alias:" icon="fa-solid fa-plus" class="chipsForm" />
              <InputTextV 
                type="text" 
                style="text-transform:uppercase"
                v-model="aliasOficinaRef" 
              />
          </div>

          <InlineMessageVue 
            v-model:hidden="validaAlias"
            class="msgValidaciones"
          >
          <i 
            class="fa-solid fa-circle-exclamation fa-bounce"
            style="--fa-animation-duration: 1.5s;"
          ></i>
          <span class="px-2">Por favor Ingresa el Alias de la Oficina</span>
          </InlineMessageVue>
        </div>
        <!-- Form del Alias -->


        <div class="col-12 mb-3 fixed-top flex align-items-center justify-content-end">
          <ButtonVue 
            icon="fa-solid fa-xmark" 
            @click="closeModal"
            class="p-button-rounded p-button-text p-button-secondary" 
          />
        </div>
  
        <!-- Boton para crear el Usuario -->
        <div class="col-12 mt-3 fixed-bottom flex align-items-center justify-content-center">
          <ButtonVue 
            label="Cambiar Alias" 
            icon="fa-solid fa-square-plus" 
            v-model:disabled="isLoading" 
            @click="cambiarNuevoAliasForm"
            class="botonGobColorVB scalein animation-duration-1000" 
          />
        </div>
        <!-- Boton para crear el Usuario -->
  
      </div>
  
    </DialogVue>
  
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import useUsuariosLista from "../composables/useUsuariosLista";
  import useDialogCrearUsuarios from "../composables/useDialogCrearUsuarios";
  


 

  
  export default {
  
    setup() {
    
     const displayModal = ref(false);
     //const usuarioOficina = ref();  
     //const usuarioAliasOficina = ref();

     const {
            getUsuariosInfo,
        } = useUsuariosLista();

      const {
        cambiarAliasOficina,
        validarDatosUsuario,
        usuariosinfo,
        usuarioOficina,
        usuarioAliasOficina,
      } = useDialogCrearUsuarios();

 
      /* Propiedades del Modal */
      const openModal = () => {
        displayModal.value = true;
        
      };
  
      //funcion para cerrar modal
      const closeModal = () => {
        limpiarForm();
        getUsuariosInfo();
        validarDatosUsuario();
        displayModal.value = false;
      }

      //Datos para el Form
      const aliasOficinaRef = ref();

      //Validacion
      const validaAlias = ref(true);

     

      const limpiarForm = () => {
        validaAlias.value = true;
        aliasOficinaRef.value = [];
      }

  

      const validarForm = () => {

        if(aliasOficinaRef.value === undefined || aliasOficinaRef.value === ""){
          validaAlias.value = false;
        } else {
          validaAlias.value = true;
        }

      }

      const isLoading = ref(false);//Variable para bloquear el submit
    
      const liberarBoton = () => {
        isLoading.value = false;
      }
      
      const bloquearBoton = () => {
        isLoading.value = true;
      }

      const cambiarNuevoAliasForm = async () => {
        validarForm();
        if( validaAlias.value === true ) {
          bloquearBoton();//Validar boton
          let formData = new FormData();
          formData.append("alias", aliasOficinaRef.value);
          //console.log(aliasOficinaRef.value);
          //console.log(formData);
          await cambiarAliasOficina(formData)
          liberarBoton();//Validar boton
          await getUsuariosInfo();
          await validarDatosUsuario();
          closeModal();
        }
      }

      onMounted(async () => {
        await getUsuariosInfo();
        await validarDatosUsuario();
      })
      
      return {
        displayModal, openModal, closeModal,
        
        isLoading,
        bloquearBoton,
        liberarBoton,

        aliasOficinaRef,
        validaAlias,
        cambiarNuevoAliasForm,
        cambiarAliasOficina,

        usuarioOficina,
        usuarioAliasOficina,
        usuariosinfo,
        getUsuariosInfo,
        validarDatosUsuario,

      }
    }
  
  }
  </script>
  
  <style scoped lang="scss">
  .buttonDialog {
    color: gray;
  }
  
  .p-button {
    margin: 0.3rem .5rem;
    min-width: 10rem;
  }
  
  p {
    margin: 0;
  }

  ::v-deep(.msgValidaciones){
  .p-inline-message-icon{
    display: none;
  }

  background-color: #fff1d4;
  color:rgb(187, 0, 0);
  font-size: 10px;
  
}

  
  .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  ::v-deep(.chipsForm){
  .p-chip-text{
    font-size: 15px !important;
  }
}

  .p-dialog .p-button {
    min-width: 6rem;
  }
  
  .gobcolor1 {
    color: #ffff;
    background-color: #4a001f;
  }
  
  .gobcolor1:hover {
    color: #ffff;
    background-color: #80245f;
  }
  
  
  /* Clases para las Chips de Asistentes */
  ::v-deep(.vistobuenochip) {
    .p-chip-text {
      color: white;
      font-size: 13px;
    }
  }
  
  .vistobuenochip {
    color: #53bdeb;
    background: #005c4b;
  }
  
  
  ::v-deep(.concopiaparachip) {
    .p-chip-text {
      color: white;
      font-size: 13px;
    }
  }
  
  .concopiaparachip {
    color: #8e9497;
    background: #005c4b;
  }
  
  ::v-deep(.firmantechip) {
    .p-chip-text {
      color: white;
      font-size: 13px;
    }
  }
  
  ::v-deep(.firmantechipMenu) {
    .p-chip-text {
      font-size: 13px;
    }
  }
  
  .firmantechip {
    color: yellow;
    background: #005c4b;
  }
  
  ::v-deep(.enviadoparachip) {
    .p-chip-text {
      color: white;
      font-size: 13px;
    }
  }
  
  ::v-deep(.enviadoparachipMenu) {
    .p-chip-text {
      font-size: 13px;
    }
  }
  
  .enviadoparachip {
    color: rgb(133, 255, 225);
    background: #005c4b;
  }
  
  ::v-deep(.atencionparachip) {
    .p-chip-text {
      color: white;
      font-size: 13px;
    }
  }
  
  .atencionparachip {
    color: rgb(255, 144, 80);
    background: #005c4b;
  }
  
  .fondoparachips {
    background: #0b141a;
  }
  
  /* Clases para las Chips de Asistentes */
  
  ::v-deep(.asistentestable) {
    .p-datatable {
      color: white;
      background: #0b141a;
  
      .p-column-title {
        color: white;
        background: #0b141a;
      }
  
    }
  }
  
  ::v-deep(.botonasistentes) {
    .p-button-label {
      font-size: 12px;
    }
  }
  
  .botonasistentes {
    color: white;
    background: #005c4b;
  }
  
  
  .fondoasistentes {
    background: #202c33;
  }
  
  .fondoasistentes2 {
    background: #2a3942;
  }
  
  .datosOficioEncabezado {
    color: grey;
    font-size: 12px;
  }
  
  .datosOficioEncabezadoContenido {
    color: black;
    font-size: 15px;
  }
  
  .datosOficioEncabezadoContenido2 {
    font-weight: bold;
    color: black;
    font-size: 15px;
  }
  
  .datosOficioEncabezadoContenido3 {
    color: black;
    font-size: 12px;
  }
  
  .coloraux1 {
    background: rgb(209, 165, 19);
    font-weight: lighter;
  }
  .coloraux3 {
    background: rgb(90, 90, 90);
    font-weight: lighter;
  }
  
  
  // Estilos del formulario
  ::v-deep(.inputGenerales) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: bold;
  }
  
  ::v-deep(.dropDownVistas) {
    .p-dropdown-label {
      color: black;
      font-size: 13px;
      font-weight: bold;
    }
  
    .p-dropdown-trigger {
      display: none;
    }
  }
  
  .leyendaDocumento {
    color: black;
    opacity: 0.9;
    font-size: 25px;
  }
  
  ::v-deep(.inputGeneralesCalendar) {
    .p-inputtext {
      color: black;
      font-size: 13px;
      font-weight: bold;
    }
  }
  
  ::v-deep(.editorCuerpo) {
    .p-editor-content {
      background: rgba(0, 0, 0, 0.5);
      color: black;
      font-size: 18px;
    }
  }
  
  //Responsive
  
  
  
  @media only screen and (max-width: 600px) {
  
  .coloraux1{
    font-size:8px;
    background: rgb(19, 111, 209);
    // background: #bec3c9;
    font-weight: lighter;
    }
  .titulosPestanas{
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:10.5px;
        transition: ease 1s;
      }
  }
  
  ::v-deep(.dropdownsResp){
    .p-dropdown{
      font-size: 11px !important;
    }
    .p-dropdown-label{
      font-size: 11px !important;
    }
  }
  
  ::v-deep(.chipsForm){
    .p-chip-text{
      font-size: 15px !important;
    }
  }
  
  ::v-deep(.textoForm){
    font-size: 11px !important;
    .p-inputtext{
      font-size: 11px !important;
    }
  }
  
  }
  
  @media only screen and (min-width: 600px) {
  
    .coloraux1{
      font-size:8px;
      background: rgb(19, 111, 209);
      // background: #bec3c9;
      font-weight: lighter;
     }
  
    .titulosPestanas{
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:11px;
        transition: ease 1s;
      }
  }
  
  }
  
  @media only screen and (min-width: 768px) {
  
    .coloraux1{
      font-size:10px;
      background: rgb(19, 111, 209);
      // background: #bec3c9;
      font-weight: lighter;
     }
  
    .titulosPestanas{
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:12px;
        transition: ease 1s;
      }
  }
  
  }
  
  @media only screen and (min-width: 992px) {
  
    .coloraux1{
      font-size:12px;
      background: rgb(19, 111, 209);
      // background: #bec3c9;
      font-weight: lighter;
     }
  
    .titulosPestanas{
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:11px;
        transition: ease 1s;
      }
  }
  
  }
  
  @media only screen and (min-width: 1200px) {
  
    .coloraux1{
      font-size:13px;
      background: rgb(19, 111, 209);
      // background: #bec3c9;
      font-weight: lighter;
     }
    .titulosPestanas{
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:14px;
        transition: ease 1s;
      }
  }
  
  }
  
  @media only screen and (min-width: 1515px) {
  
    .coloraux1{
      font-size:15px;
      background: rgb(19, 111, 209);
      // background: #bec3c9;
      font-weight: lighter;
     }
  
    .titulosPestanas{
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }
  
  ::v-deep(.tabsTablas){
      .p-tabview-nav{
        font-size:13px;
        transition: ease 1s;
      }
  }
  
  }
  
  
  /*
  @media only screen and (max-width: 600px) {
  
  
  }
  
  @media only screen and (min-width: 600px) {
  
  
  }
  
  @media only screen and (min-width: 768px) {
  
  
  }
  
  @media only screen and (min-width: 992px) {
  
  
  }
  
  @media only screen and (min-width: 1200px) {
  
  
  }
  
  @media only screen and (min-width: 1515px) {
  
  
  }
  */
  
  
  
  </style>
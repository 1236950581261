<template>

  <div class="card p-3">
    <div class="grid">

      <!-- Titulo Oficios -->
      <div class="col-9 xl:col-3 lg:col-3 md:col-4 sm:col-7 p-2 flex justify-content-end">
        <h5 class="p-2 mt-2 tablaTitle">
          <i 
          class="fa-solid fa-box-archive fa-shake px-2"
          style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
          ></i>
          Oficios archivados
        </h5>
      </div>
      <!-- Titulo Oficios -->

      <div class="col-3 xl:col-2 lg:col-7 md:col-8 sm:col-5 flex justify-content-start">
        <dialogArchivarOficios/>
      </div>
      
      <div class="col-0 xl:col-7 lg:col-7 md:col-0 sm:col-0 p-2"></div>

      <!-- Tabs -->
      <div class="col-12 p-2">
        <div class="card">

            <TabViewVue 
              class="tabview-custom tabsTablas" 
              :activeIndex="parseInt(selectedTab)"
            >

<!-- Tabla de Archivados -->
<TabPanelVue>
    <template #header>

      <div class="grid titulosPestanas" @click="getFichasArchivadosRecepcion">
        <div class="col-12 text-center">
          <!-- Contador para oficios -->
          <BadgeVue 
            :value="contadorOficiosArchivadosRecepcion"
            class="titulosPestanasContador">
          </BadgeVue>
          <!-- Contador para oficios -->
          <i 
          class="fa-solid fa-folder-open fa-bounce px-2"
          style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
          ></i>
          <span>Oficios archivados de recepción</span>
        </div>
      </div>
       
    </template>

    <div class="card p-1">
      <DataTable 
          :value="fichasRecepcionArchivados" 
          responsiveLayout="scroll" 
          v-model:filters="filtersArchivados" 
          stripedRows
          sortField="numeroOficio"
          :sortOrder="-1"
          :paginator="true"
          :rows="10" 
          paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]" 
          removableSort 
          dataKey="numeroOficio" 
          class="p-datatable-sm"
          style="width: 100%">

          <template #header>
            <div class="grid">

              <div class="col-12 p-1">
                <div class="flex justify-content-center">
                  <span class="p-input-icon-left m-2">
                    <i class="pi pi-search" />
                    <InputTextV 
                      v-model="filtersArchivados['global'].value" 
                      placeholder="Buscar"
                      class="p-inputtext-sm" 
                    />
                  </span>
                </div>
              </div>
              
            </div>

          </template>

          <template #empty>

            <InlineMessageVue severity="info" class="border-dashed border-300">
              No se encontraron resultados.
            </InlineMessageVue>

          </template>

          <template #loading>
            <InlineMessageVue severity="info" class="border-dashed border-300">
              Cargando....
            </InlineMessageVue>
          </template>

          <ColumnVue 
            v-for="col of columnasArchivados" 
            :field="col.field" 
            :header="col.header" 
            :key="col.field" 
            :sortable="true"
            :hidden="col.hidden" 
            class="border-dashed border-300 tablaOficiosData"
          >
          </ColumnVue>

          <ColumnVue 
            :exportable="false" 
            style="width: 18rem" 
            header="Opciones"
            class="tablaOficiosData"
          
          >
            <template #body="slotProps">
              
              <!-- Oficios para Archivados (Adjuntado) -->
              <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1" 
                icon="fa-solid fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio archivado adjunto"
                v-tooltip.top="'El oficio ha sido archivado'" 
                :model="[
                
                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficio',
                      slotProps.data,
                    );
                  },
                },

                
                

              ]"/>
              <!-- Oficios para Archivados (Adjuntado) -->

              <!-- Oficios para Archivados (Creado) -->
              <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2" 
                icon="fa-regular fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio archivado creado"
                v-tooltip.top="'El oficio ha sido archivado'"  
                :model="[

                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficio',
                      slotProps.data,
                    );
                  },
                },

              ]"/>
              <!-- Oficios para Archivados (Creado) -->

            </template>
          </ColumnVue>
      </DataTable>
    </div>

</TabPanelVue>
<!-- Tabla de Archivados -->

<!-- Tabla de Archivados Atencion -->
<TabPanelVue>
    <template #header>

      <div class="grid titulosPestanas" @click="getFichasArchivadosAtencion">
        <div class="col-12 text-center">
          <!-- Contador para oficios -->
          <BadgeVue 
            :value="contadorOficiosArchivadosRecepcionAtencion"
            class="titulosPestanasContador">
          </BadgeVue>
          <!-- Contador para oficios -->
          <i 
          class="fa-solid fa-folder-open fa-tags px-2"
          style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
          ></i>
          <span>Oficios archivados con atención</span>
        </div>
      </div>
       
    </template>

    <div class="card p-1">
      <DataTable 
          :value="fichasRecepcionArchivadosAtencion" 
          responsiveLayout="scroll" 
          v-model:filters="filtersArchivados" 
          stripedRows
          sortField="numeroOficio"
          :sortOrder="-1"
          :paginator="true"
          :rows="10" 
          paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]" 
          removableSort 
          dataKey="numeroOficio" 
          class="p-datatable-sm"
          style="width: 100%">

          <template #header>
            <div class="grid">

              <div class="col-12 p-1">
                <div class="flex justify-content-center">
                  <span class="p-input-icon-left m-2">
                    <i class="pi pi-search" />
                    <InputTextV 
                      v-model="filtersArchivados['global'].value" 
                      placeholder="Buscar"
                      class="p-inputtext-sm" 
                    />
                  </span>
                </div>
              </div>
              
            </div>

          </template>

          <template #empty>

            <InlineMessageVue severity="info" class="border-dashed border-300">
              No se encontraron resultados.
            </InlineMessageVue>

          </template>

          <template #loading>
            <InlineMessageVue severity="info" class="border-dashed border-300">
              Cargando....
            </InlineMessageVue>
          </template>

          <ColumnVue 
            v-for="col of columnasArchivados" 
            :field="col.field" 
            :header="col.header" 
            :key="col.field" 
            :sortable="true"
            :hidden="col.hidden" 
            class="border-dashed border-300 tablaOficiosData"
          >
          </ColumnVue>

          <ColumnVue 
            :exportable="false" 
            style="width: 18rem" 
            header="Opciones"
            class="tablaOficiosData"
          
          >
            <template #body="slotProps">
              
              <!-- Oficios para Archivados (Adjuntado) -->
              <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1" 
                icon="fa-solid fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio Archivado Adjunto"
                v-tooltip.top="'El oficio ha sido Archivado'" 
                :model="[
                
                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficio',
                      slotProps.data,
                    );
                  },
                },

                

              ]"/>
              <!-- Oficios para Archivados (Adjuntado) -->

              <!-- Oficios para Archivados (Creado) -->
              <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2" 
                icon="fa-regular fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio archivado creado"
                v-tooltip.top="'El oficio ha sido archivado'"  
                :model="[

                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficio',
                      slotProps.data,
                    );
                  },
                },

                
              ]"/>
              <!-- Oficios para Archivados (Creado) -->

            </template>
          </ColumnVue>
      </DataTable>
    </div>

</TabPanelVue>
<!-- Tabla de Archivados Atencion -->

<!-- Tabla de Archivados Turnados-->
<TabPanelVue>
    <template #header>

      <div class="grid titulosPestanas" @click="getFichasArchivadosTurnados">
        <div class="col-12 text-center">
          <!-- Contador para oficios -->
          <BadgeVue 
            :value="contadorOficiosArchivadosRecepcionTurnados"
            class="titulosPestanasContador">
          </BadgeVue>
          <!-- Contador para oficios -->
          <i 
          class="fa-solid fa-arrows-turn-right fa-bounce px-2"
          style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
          ></i>
          <span>Oficios archivados turnados</span>
        </div>
      </div>
       
    </template>

    <div class="card p-1">
      <DataTable 
          :value="fichasRecepcionArchivadosTurnados" 
          responsiveLayout="scroll" 
          v-model:filters="filtersArchivados" 
          stripedRows
          sortField="numeroOficio"
          :sortOrder="-1"
          :paginator="true"
          :rows="10" 
          paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]" 
          removableSort 
          dataKey="numeroOficio" 
          class="p-datatable-sm"
          style="width: 100%">

          <template #header>
            <div class="grid">

              <div class="col-12 p-1">
                <div class="flex justify-content-center">
                  <span class="p-input-icon-left m-2">
                    <i class="pi pi-search" />
                    <InputTextV 
                      v-model="filtersArchivados['global'].value" 
                      placeholder="Buscar"
                      class="p-inputtext-sm" 
                    />
                  </span>
                </div>
              </div>
              
            </div>

          </template>

          <template #empty>

            <InlineMessageVue severity="info" class="border-dashed border-300">
              No se encontraron resultados.
            </InlineMessageVue>

          </template>

          <template #loading>
            <InlineMessageVue severity="info" class="border-dashed border-300">
              Cargando....
            </InlineMessageVue>
          </template>

          <ColumnVue 
            v-for="col of columnasArchivados" 
            :field="col.field" 
            :header="col.header" 
            :key="col.field" 
            :sortable="true"
            :hidden="col.hidden" 
            class="border-dashed border-300 tablaOficiosData"
          >
          </ColumnVue>

          <ColumnVue 
            :exportable="false" 
            style="width: 18rem" 
            header="Opciones"
            class="tablaOficiosData"
          
          >
            <template #body="slotProps">
              
              <!-- Oficios para Archivados (Adjuntado) -->
              <SplitButtonVue v-if="slotProps.data.status == 10 && slotProps.data.adjuntado == 1" 
                icon="fa-solid fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio archivado adjunto"
                v-tooltip.top="'El oficio ha sido archivado'" 
                :model="[
                
                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio turnado',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficioTurnado',
                      slotProps.data,
                    );
                  },
                },

               
                

              ]"/>
              <!-- Oficios para Archivados (Adjuntado) -->

              <!-- Oficios para Archivados (Creado) -->
              <SplitButtonVue v-if="slotProps.data.status == 10 && slotProps.data.adjuntado == 2" 
                icon="fa-regular fa-file fa-bounce"
                style="--fa-animation-duration: 5s;"
                class="p-button-sm p-button-secondary splitRecepcionEstado15 w-full"
                label="Oficio archivado creado"
                v-tooltip.top="'El oficio ha sido archivado'"  
                :model="[

                {
                  label: 'Ver oficio archivado',
                  icon: 'pi pi-eye',
                  command: () => {
                    openDialog(
                      'VisualizarPDF',
                      slotProps.data.idOficio,
                    );
                  },
                },

                {
                  label: 'Desarchivar oficio turnado',
                  icon: 'pi pi-link',
                  command: () => {
                    openDialogDesarchivar(
                      'DesarchivarOficioTurnado',
                      slotProps.data,
                    );
                  },
                },

              
              ]"/>
              <!-- Oficios para Archivados (Creado) -->

            </template>
          </ColumnVue>
      </DataTable>
    </div>

</TabPanelVue>
<!-- Tabla de Archivados Turnados-->


            </TabViewVue>
        </div>
      </div>
      <!-- Tabs -->

    </div>
  </div>


<dialogVisualizarPDFBuzon v-if="hasRole('VisualizarPDF')" />
<dialogDesarchivarOficio v-if="hasRole('DesarchivarOficio')"/>
<dialogDesarchivarOficioTurnado v-if="hasRole('DesarchivarOficioTurnado')"/>

</template>

<script>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import DataTable from "primevue/datatable";
import useOficiosRecepcion from "../composables/useOficiosRecepcion";
import dialogArchivarOficios from "../components/oficiosLibres/dialogAyudaArchivarOficios";
import Swal from "sweetalert2";
//Tabla Buzon
import useOpenDialogs from "../composables/useOpenDialogs";
//Tabla Buzon
import {  useRoute } from "vue-router";
export default {

    components:{
      //OficiosPendientes
      DataTable,
      dialogArchivarOficios,
      dialogVisualizarPDFBuzon: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarPDF.vue")
      ),
      dialogDesarchivarOficio: defineAsyncComponent(() =>
        import("../components/oficiosLibres/oficiosArchivados/dialogDesarchivarOficio.vue")
      ),
      dialogDesarchivarOficioTurnado: defineAsyncComponent(() =>
        import("../components/oficiosLibres/oficiosArchivados/dialogDesarchivarOficioTurnado.vue")
      ),
      //OficiosPendientes
    },

  setup(){
        /* const route = useRoute(); */
        /* const { code } = route.query; */
        const route = useRoute();
        const selectedTab = ref(route.params.tab);


        const { 
          openDialog,  
          openDialogDesarchivar,
          hasRole, 
          toggleDialog 
        } = useOpenDialogs();

        //Tabla de para consumir
        const {
          columns,
          columnasArchivados,
          filtersArchivados,
          fichasRecepcionArchivados,
          fichasRecepcionArchivadosAtencion,
          fichasRecepcionArchivadosTurnados,
          getFichasRecepcionArchivados,
          getFichasRecepcionArchivadosAtencion,
          getFichasRecepcionArchivadosTurnados,
          fichas,

          contadorOficiosArchivadosRecepcion,
          contadorOficiosArchivadosRecepcionAlt,
          barraOficiosArchivadosRecepcion,
          
          contadorOficiosArchivadosRecepcionAtencion,
          contadorOficiosArchivadosRecepcionAtencionAlt,
          barraOficiosArchivadosRecepcionAtencion,

          contadorOficiosArchivadosRecepcionTurnados,
          contadorOficiosArchivadosRecepcionTurnadosAlt,
          barraOficiosArchivadosRecepcionTurnados,
        } = useOficiosRecepcion();

        const messages = ref([]);
        const count = ref(0);
        const addMessages = () => {
          messages.value = [
            {
              icon: 'pi pi-search',
              severity: 'info',
              content: 'Dynamic Info Message',
              id: count.value++
            },
          ]
        };

        const getFichasArchivadosRecepcion = async () => {
          new Swal({
            title: "Cargando...",
            text: "Actualizando Información de Oficios Archivados",
            allowOutsideClick: false,
          });
          Swal.showLoading();
          const { ok, message } = await getFichasRecepcionArchivados();
          if (!ok) Swal.fire("Error", message, "error");
          else {
            Swal.close();
          }

          await barraOficiosArchivadosRecepcion();
          await barraOficiosArchivadosRecepcionAtencion();
          await barraOficiosArchivadosRecepcionTurnados();
        }

        const getFichasArchivadosAtencion = async () => {
          new Swal({
            title: "Cargando...",
            text: "Actualizando Información de Oficios Archivados con Atencion",
            allowOutsideClick: false,
          });
          Swal.showLoading();
          const { ok, message } = await getFichasRecepcionArchivadosAtencion();
          if (!ok) Swal.fire("Error", message, "error");
          else {
            Swal.close();
          }

          await barraOficiosArchivadosRecepcion();
          await barraOficiosArchivadosRecepcionAtencion();
          await barraOficiosArchivadosRecepcionTurnados();
        }

        const getFichasArchivadosTurnados = async () => {
          new Swal({
            title: "Cargando...",
            text: "Actualizando Información de Oficios Archivados Turnados",
            allowOutsideClick: false,
          });
          Swal.showLoading();
          const { ok, message } = await getFichasRecepcionArchivadosTurnados();
          if (!ok) Swal.fire("Error", message, "error");
          else {
            Swal.close();
          }

          await barraOficiosArchivadosRecepcion();
          await barraOficiosArchivadosRecepcionAtencion();
          await barraOficiosArchivadosRecepcionTurnados();
        }



        onMounted(async () => {
      
          new Swal({
            title: "Cargando...",
            text: "Actualizando Información del Directorio de Archivados de Oficios",
            allowOutsideClick: false,
          });
          Swal.showLoading();

          await getFichasRecepcionArchivados();
          await getFichasRecepcionArchivadosAtencion();
          await getFichasRecepcionArchivadosTurnados();

          Swal.close();

          await barraOficiosArchivadosRecepcion();
          await barraOficiosArchivadosRecepcionAtencion();
          await barraOficiosArchivadosRecepcionTurnados();

        })

    return{
        fichasRecepcionArchivados,
        fichasRecepcionArchivadosAtencion,
        fichasRecepcionArchivadosTurnados,
        columns,
        columnasArchivados,
        filtersArchivados,
        fichas,
        addMessages, 
        hasRole,
        openDialog,
        openDialogDesarchivar,
        toggleDialog,
        dialogArchivarOficios,
        getFichasArchivadosRecepcion,
        getFichasArchivadosAtencion,
        getFichasArchivadosTurnados,

        contadorOficiosArchivadosRecepcion,
        contadorOficiosArchivadosRecepcionAlt,
        barraOficiosArchivadosRecepcion,
        
        contadorOficiosArchivadosRecepcionAtencion,
        contadorOficiosArchivadosRecepcionAtencionAlt,
        barraOficiosArchivadosRecepcionAtencion,

        contadorOficiosArchivadosRecepcionTurnados,
        contadorOficiosArchivadosRecepcionTurnadosAlt,
        barraOficiosArchivadosRecepcionTurnados,
        selectedTab,

    }
  },

}
</script>

<style scoped lang="scss">
::v-deep(.titulosPestanasContador){
  font-size: 9px;
  background-color: rgb(18, 95, 168);
  // background-color: #bec3c9;
}
  .coloraux1{
   background: #4a001f;
   font-size: 13px;
   font-weight: lighter;
  }

  .coloraux2{
   background: #919191;
   font-size: 12px;
   font-weight: lighter;
  }

  .buttonAbrirOficio{
    background-color:#4a001f;
  }

  ::v-deep(.cardEntrada){
    background-color: rgb(185, 185, 185);
    .p-card-title{
      font-size:14px;
    }
    .p-card-content{
      font-size:12px;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:13px;
  .p-column-title{
    font-size:13px
  }
}

  ::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:13px;
    }
  }

 .titleaccordion {
  font-size:13px
 }


  ::v-deep(.columnasOcultas){
    font-size:1px;
    padding-bottom: 1px;
    display:block;
    color:red;
    .p-datatable-thead{
      color:red;
      display:block;
    }
  }

  ::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:25px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

@media only screen and (max-width: 600px){

  .tablaTitle{
  font-size: 15px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 45px;
  width: 85px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:15px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:15px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:10px;
  transition: ease 1s;
  .p-column-title{
    font-size:12px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:10.5px;
      transition: ease 1s;
    }
}


::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 600px){
  .tablaTitle{
  font-size: 18px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 55px;
  width: 85px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:18px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:18px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:12px;
  transition: ease 1s;
  .p-column-title{
    font-size:13px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:11px;
      transition: ease 1s;
    }
}


::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 768px){
  
.tablaTitle{
  font-size: 14px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 55px;
  width: 125px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:20px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:20px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:12px;
  transition: ease 1s;
  .p-column-title{
    font-size:13px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:12px;
      transition: ease 1s;
    }
}
 

::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 992px){
  
  .tablaTitle{
  font-size: 16px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 35px;
  width: 155px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:21px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:21px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:13px;
  transition: ease 1s;
  .p-column-title{
    font-size:13px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:11px;
      transition: ease 1s;
    }
}


::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 1200px){
  
  .tablaTitle{
  font-size: 20px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 50px;
  width: 180px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:22px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:22px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:16px;
  transition: ease 1s;
  .p-column-title{
    font-size:16px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:14px;
      transition: ease 1s;
    }
}
  
::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 1515px){
  
  .tablaTitle{
  font-size: 20px;
  transition: ease 1s;
}

.titulosPestanas{
  height: 40px;
  width: 175px;
  transition: ease 1s;
}

::v-deep(.botonAyuda){
    .p-button-icon{
      font-size:25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover){
    .p-button-icon{
      font-size:25px;
      color:rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData){
  font-size:13px;
  transition: ease 1s;
  .p-column-title{
    font-size:13px
    
  }
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:13px;
      transition: ease 1s;
    }
}

  
::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #22669d !important;
    border-color: #22669d  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstadoDesarchivado:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #133957  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}


</style>
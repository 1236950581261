<template>
  <router-view />
</template>

<script>

export default {
  
}


</script>

<style>
svg{
  color: #bec3c9 !important;
  background-color: transparent !important;
  stroke: #bec3c9 !important;
}

</style>

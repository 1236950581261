<template>
  <div class="card p-1">
    <div class="grid">

      <!-- Titulo Oficios -->
      <div class="col-9 xl:col-3 lg:col-3 md:col-3 sm:col-5 p-2 flex justify-content-end">
        <h5 class="p-2 mt-3 tablaTitle">
          <i class="fa-solid fa-file-contract fa-flip"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
          <span class="px-2">
            Creación de oficios
          </span>
        </h5>
      </div>
      <!-- Titulo Oficios -->

      <div class="col-3 xl:col-2 lg:col-2 md:col-2 sm:col-2 flex justify-content-start">
        <dialogAyudaCreacionOficios />
      </div>

      <div class="col-12 xl:col-7 lg:col-7 md:col-7 sm:col-5 p-2 text-center">
        <dialogCrearFichaTabla />
      </div>

      <!-- Tabs -->
      <div class="col-12 p-1 mb-8">
        <div class="card">
          <TabViewVue class="tabview-custom tabsTablas" :activeIndex="parseInt(selectedTab)">

            <!-- Tabla de Creados -->
            <TabPanelVue >
              <template #header>

                <div class="grid titulosPestanas" @click="getFichasCreacion">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosNuevos"
                     class="titulosPestanasContador"
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->

                    <i class="fa-solid fa-file fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                    </i>
                    <span class="">Oficios nuevos</span>

                    
                  </div>
                </div>

              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichas" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersNuevos" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersNuevos['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>
                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios nuevos.
                    </InlineMessageVue>
                  </template>

                  <ColumnVue v-for="col of columns" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue 
                    field="vobo_ccp" 
                    :sortable="true" 
                    :exportable="false" 
                    style="min-width: 8rem"
                    header="Opciones" 
                    class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->

                      <!-- Oficio Creado Estado 1 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.anexo == 0)"
                        label="Elegir una opción" 
                        icon="fa-solid fa-question fa-beat"
                        style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1 w-full"
                        v-tooltip.top="'La ficha ha sido creada, puedes añadir vistos buenos opcionalmente, después crear el oficio o adjuntarlo.'"
                        :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },
                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

    
                        ]" />
                      <!-- Oficio Creado Estado 1 -->

                      <!-- Oficio Creado con CCP Estado 1 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 1 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 0)"
                        label="C.C.P. añadido" 
                        icon="fa-solid fa-question fa-beat" 
                        style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1CCP w-full"
                        v-tooltip.top="'La ficha ha sido creada y se han añadido vistos buenos.'" :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado con CCP Estado 1 -->

                      <!-- Oficio Adjunto Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 0 && slotProps.data.anexo == 0)"
                        label="Oficio adjunto" 
                        icon="fa-solid fa-paperclip fa-beat" 
                        style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitGeneracionEstado2 w-full"
                        v-tooltip.top="'Se adjunto un archivo, modificalo si es necesario.'" :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Adjunto Estado 2 -->

                      <!-- Oficio Creado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 0 && slotProps.data.anexo == 0)"
                        label="Oficio creado" icon="fa-solid fa-file-code fa-beat" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2 w-full"
                        v-tooltip.top="'Creaste un oficio desde correspondencia, modifica los datos si es necesario.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado Estado 2 -->

                      <!-- Oficio Adjunto Contestado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 1 && slotProps.data.anexo == 0)"
                        label="Oficio contestado" icon="fa-solid fa-check-to-slot fa-bounce"
                        style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full"
                        v-tooltip.top="'Contestaste un oficio adjunto, modifica los datos si es necesario.'" :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },
                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },
                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Adjunto Contestado Estado 2 -->

                      <!-- Oficio Creado Contestado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 1 && slotProps.data.anexo == 0)"
                        label="Oficio contestado" icon="fa-solid fa-file-circle-check fa-bounce"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full"
                        v-tooltip.top="'Contestaste un oficio creado desde correspondencia, modifica los datos si es necesario.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },
                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },
                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado Contestado Estado 2 -->

                      <!-- Oficio Creado Estado 1 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 1 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 0"
                        label="Visto bueno añadido" icon="fa-solid fa-question fa-beat"
                        style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1VB w-full"
                        v-tooltip.top="'Añadiste vistos buenos, ahora adjunta o crea un oficio.'" :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Adjunto Estado 2 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 0"
                        label="Oficio adjunto con visto bueno" 
                        icon="fa-solid fa-paperclip fa-beat"
                        style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitGeneracionEstado2VB w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de enviarlo a revisión con vistos buenos.'"
                        :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Enviar oficio adjunto a revisión',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogEnviarOficioVB(
                                'EnviarOficioVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Creado Estado 2 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 0"
                        label="Oficio creado con visto bueno" icon="fa-solid fa-file-code fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2VB w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de enviarlo a revisión con vistos buenos.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Enviar oficio creado a revisión',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogEnviarOficioVB(
                                'EnviarOficioVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Ajuntado pero solo con CCP -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 0"
                        label="Oficio adjunto con C.C.P." icon="fa-solid fa-calendar-check fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2CCP w-full"
                        v-tooltip.top="'Añadiste personas con copia para, ahora adjunta o crea un oficio.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Ajuntado pero solo con CCP -->

                      <!-- Oficio Creado pero solo con CCP -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 0"
                        label="Oficio creado con C.C.P." icon="fa-regular fa-calendar-check fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2CCP w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de pasarlo a firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado pero solo con CCP -->

                      <!-- #########################################################SIN ANEXO################################################################ -->


                      <!-- #########################################################CON ANEXO################################################################ -->

                      <!-- Oficio Creado Estado 1 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.anexo == 1)"
                        label="Elegir una opción-" icon="fa-solid fa-question fa-beat"
                        style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1 w-full"
                        v-tooltip.top="'La ficha ha sido creada, puedes añadir vistos buenos opcionalmente, después crear el oficio o adjuntarlo.'"
                        :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },


                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado Estado 1 -->

                      <!-- Oficio Creado con CCP Estado 1 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 1 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 1)"
                        label="C.C.P. añadido-" icon="fa-solid fa-question fa-beat" style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1CCP w-full"
                        v-tooltip.top="'La ficha ha sido creada y se han añadido vistos buenos.'" :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado con CCP Estado 1 -->

                      <!-- Oficio Adjunto Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 0 && slotProps.data.anexo == 1)"
                        label="Oficio adjunto-" icon="fa-solid fa-paperclip fa-beat" style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitGeneracionEstado2 w-full"
                        v-tooltip.top="'Se adjunto un archivo, modificalo si es necesario.'" :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Adjunto Estado 2 -->

                      <!-- Oficio Creado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 0 && slotProps.data.anexo == 1)"
                        label="Oficio creado-" icon="fa-solid fa-file-code fa-beat" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2 w-full"
                        v-tooltip.top="'Creaste un oficio desde correspondencia, modifica los datos si es necesario.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado Estado 2 -->

                      <!-- Oficio Adjunto Contestado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 1 && slotProps.data.anexo == 1)"
                        label="Oficio contestado-" icon="fa-solid fa-check-to-slot fa-bounce"
                        style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full"
                        v-tooltip.top="'Contestaste un oficio adjunto, modifica los datos si es necesario.'" :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },
                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },
                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Adjunto Contestado Estado 2 -->

                      <!-- Oficio Creado Contestado Estado 2 -->
                      <SplitButtonVue v-if="(slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 0 && slotProps.data.contestacion == 1 && slotProps.data.anexo == 1)"
                        label="Oficio contestado-" icon="fa-solid fa-file-circle-check fa-bounce"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full"
                        v-tooltip.top="'Contestaste un oficio creado desde correspondencia, modifica los datos si es necesario.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },
                          {
                            label: 'Añadir vistos buenos y con copia para',
                            icon: 'pi pi-plus-circle',
                            command: () => {
                              openDialogVoBo(
                                'AnadirVB',
                                slotProps.data,
                              );
                            },
                          },
                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado Contestado Estado 2 -->

                      <!-- Oficio Creado Estado 1 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 1 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 1"
                        label="Visto bueno añadido-" icon="fa-solid fa-question fa-beat"
                        style="--fa-animation-duration: 1.5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado1VB w-full"
                        v-tooltip.top="'Añadiste vistos buenos, ahora adjunta o crea un oficio.'" :model="[
                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Crear oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialog(
                                'CrearPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,

                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,

                                slotProps.data.cargoFirmante,
                                slotProps.data.firmante,
                              );
                            },
                          },
                          {
                            label: 'Adjuntar oficio',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'AdjuntarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Adjunto Estado 2 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 1"
                        label="Oficio adjunto con visto bueno-" icon="fa-solid fa-paperclip fa-beat"
                        style="--fa-animation-duration: 5s; "
                        class="p-button-sm p-button-secondary splitGeneracionEstado2VB w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de enviarlo a revisión con vistos buenos.'"
                        :model="[
                          {
                            label: 'Visualizar archivo adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Enviar oficio adjunto a revisión',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogEnviarOficioVB(
                                'EnviarOficioVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Creado Estado 2 con Visto Bueno -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 1 && slotProps.data.anexo == 1"
                        label="Oficio creado con visto bueno-" icon="fa-solid fa-file-code fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2VB w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de enviarlo a revisión con vistos buenos.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,

                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Enviar oficio creado a revisión',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogEnviarOficioVB(
                                'EnviarOficioVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- aa -->

                      <!-- Oficio Ajuntado pero solo con CCP -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 1 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 1"
                        label="Oficio adjunto con C.C.P.-" icon="fa-solid fa-calendar-check fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2CCP w-full"
                        v-tooltip.top="'Añadiste personas con copia para, ahora adjunta o crea un oficio.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Ajuntado pero solo con CCP -->

                      <!-- Oficio Creado pero solo con CCP -->
                      <SplitButtonVue v-if="slotProps.data.status == 2 && slotProps.data.adjuntado == 2 && slotProps.data.vobo_ccp == 2 && slotProps.data.anexo == 1"
                        label="Oficio creado con C.C.P.-" icon="fa-regular fa-calendar-check fa-beat"
                        style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado2CCP w-full"
                        v-tooltip.top="'Modifica algún dato si es necesario, antes de pasarlo a firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio,

                                slotProps.data.destinatario,
                                slotProps.data.cargoDestinatario,
                                slotProps.data.oficinaDestinatario,
                                slotProps.data.atencionA,
                                slotProps.data.cargoAtencionA,
                                slotProps.data.firmante,
                                slotProps.data.cargoFirmante,
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            separator: true
                          },

                          {
                            label: 'Pasar a firmar',
                            icon: 'pi pi-directions',
                            command: () => {
                              openDialogEnviarOficio(
                                'EnviarOficiosinVB',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Oficio Creado pero solo con CCP -->

                      <!-- #########################################################CON ANEXO################################################################ -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

              <!-- Tabla de estatus -->
              <!-- <div class="barraEstados text-center">
                <div class="card">
                  <div class="grid">

                    <div class="col-2">
                      <i 
                        v-badge="contadorOficiosNuevos" 
                        class="pi pi-book p-overlay-badge tablaEstatus1 mx-2 mt-3" 
                        style="font-size: 2rem" 
                      />

                      <p class="fontBarraEstados mx-2 mt-1">Oficios totales</p>
                    </div>

                  </div>
                </div>
              </div> -->
              <!-- Tabla de estatus -->

            </TabPanelVue>
            <!-- Tabla de Creados -->

            <!-- Tabla de Oficios en Espera -->
            <TabPanelVue >
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasEspera">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosEspera"
                     class="titulosPestanasContador"
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-clock fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span class="">Oficios en espera</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichas" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersEnviados" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersEnviados['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios en espera.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Enviado Oficio Adjunto Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.status == 3 && slotProps.data.adjuntado == 1"
                        label="Enviado a visto bueno" icon="fa-solid fa-paper-plane fa-flip"
                        style="--fa-animation-duration: 10s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado3 w-full"
                        v-tooltip.top="'El oficio adjunto ha sido enviado y está siendo aprobado por los participantes'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        ]" />
                      <!-- Enviado Oficio Adjunto Estado 3 -->

                      <!-- Enviado Oficio Creado Estado 3 -->
                      <SplitButtonVue v-if="slotProps.data.status == 3 && slotProps.data.adjuntado == 2"
                        label="Enviado a visto bueno" icon="fa-regular fa-paper-plane fa-flip"
                        style="--fa-animation-duration: 10s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado3 w-full"
                        v-tooltip.top="'El oficio creado está siendo aprobado por los participantes'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        ]" />
                      <!-- Enviado Oficio Creado Estado 3 -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios en Espera -->

            <!-- Tabla de Oficios con Observación -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasObservacion">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosObservacion"
                     class="titulosPestanasContador"
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-triangle-exclamation fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios con observación</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichas" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersObservacion" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersObservacion['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios con observación.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" tyle="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->
                      <!-- Revision Oficio Adjunto Estado 4 -->
                      <SplitButtonVue v-if="slotProps.data.status == 4 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0"
                        label="Revisar oficio" icon="fa-solid fa-paperclip fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado4 w-full"
                        v-tooltip.top="'El oficio adjunto requiere de una revisión'" :model="[

                          {
                            label: 'Observaciones del oficio',
                            icon: 'pi pi-comment',
                            command: () => {
                              openMsgVB(
                                'NotaOficio',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Reenviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              reenviarOficios(slotProps.data.idOficio);
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Revision Oficio Adjunto Estado 4 -->

                      <!-- Revision Oficio Creado Estado 4 -->
                      <SplitButtonVue v-if="slotProps.data.status == 4 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0"
                        label="Revisar oficio" icon="fa-solid fa-file-circle-exclamation fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado4 w-full"
                        v-tooltip.top="'El oficio creado está siendo aprobado por los participantes'" :model="[

                          {
                            label: 'Observaciones del oficio',
                            icon: 'pi pi-comment',
                            command: () => {
                              openMsgVB(
                                'NotaOficio',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Reenviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              reenviarOficios(slotProps.data.idOficio);
                            },
                          },

                          {
                            label: 'Añadir Anexo',
                            icon: 'pi pi-folder',
                            command: () => {
                              openDialogAnexar(
                                'AnadirAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Revision Oficio Creado Estado 4 -->
                      <!-- #########################################################SIN ANEXO################################################################ -->

                      <!-- #########################################################CON ANEXO################################################################ -->
                      <!-- Revision Oficio Adjunto Estado 4 -->
                      <SplitButtonVue v-if="slotProps.data.status == 4 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1"
                        label="Revisar oficio-" icon="fa-solid fa-paperclip fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado4 w-full"
                        v-tooltip.top="'El oficio adjunto requiere de una revisión'" :model="[

                          {
                            label: 'Observaciones del oficio',
                            icon: 'pi pi-comment',
                            command: () => {
                              openMsgVB(
                                'NotaOficio',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio adjunto',
                            icon: 'pi pi-file-import',
                            command: () => {
                              openDialogMFicha(
                                'ModificadoAdjuntoPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Reenviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              reenviarOficios(slotProps.data.idOficio);
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Revision Oficio Adjunto Estado 4 -->

                      <!-- Revision Oficio Creado Estado 4 -->
                      <SplitButtonVue v-if="slotProps.data.status == 4 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1"
                        label="Revisar oficio-" icon="fa-solid fa-file-circle-exclamation fa-beat-fade"
                        style="--fa-animation-duration: 1s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado4 w-full"
                        v-tooltip.top="'El oficio creado está siendo aprobado por los participantes'" :model="[

                          {
                            label: 'Observaciones del oficio',
                            icon: 'pi pi-comment',
                            command: () => {
                              openMsgVB(
                                'NotaOficio',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Modificar ficha',
                            icon: 'pi pi-file-edit',
                            command: () => {
                              openDialogMFicha(
                                'ModificarFicha',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Modificar oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogMOficio(
                                'ModificarPDF',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Reenviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              reenviarOficios(slotProps.data.idOficio);
                            },
                          },

                          {
                            label: 'Modificar Anexo',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialogAnexarModificar(
                                'ModificarAnexos',
                                slotProps.data
                              );
                            },
                          },

                        ]" />
                      <!-- Revision Oficio Creado Estado 4 -->
                      <!-- #########################################################CON ANEXO################################################################ -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios con Observación -->

            <!-- Tabla de Oficios para Firmar para Titulares -->
            <TabPanelVue v-if="esTitular">
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirma">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmar"
                     class="titulosPestanasContador" 
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-pencil fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Firmar mis oficios nuevos</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmaC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirma" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirma['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios para firmar.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Firmar Oficio Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 1"
                        label="Oficio aprobado" icon="fa-solid fa-signature fa-shake" style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio adjunto',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'AdjuntoFirmadoPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },
                        ]" />
                      <!-- Firmar Oficio Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 2"
                        label="Oficio aprobado" icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio creado fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                          {
                            label: 'Firmar oficio creado',
                            icon: 'pi pi-pencil',
                            command: () => {
                              openDialog(
                                'FirmarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.fechaCreacion,
                                slotProps.data.fechaOficio,
                                slotProps.data.dependenciaFirmante,
                                slotProps.data.subdependenciaFirmante,
                                slotProps.data.oficinaFirmante,
                                slotProps.data.numeroOficio
                              );
                            },
                          },
                        ]" />
                      <!-- Firmar Oficio Autor Creado Estado 5 -->

                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 1"
                        label="Oficio aprobado" icon="fa-solid fa-signature fa-shake" style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue enviado para que lo firme el autor.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 2"
                        label="Oficio Aprobado" icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio creado fue enviado para que lo firme el autor.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios para Firmar para Titulares -->

            <!-- Tabla de Oficios para Firmar para NO Titulares -->
            <TabPanelVue v-if="noesTitular">
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirma">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmar3"
                     class="titulosPestanasContador"
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-file-circle-exclamation fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Mis oficios en espera de firma</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmaC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirma" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirma['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios para firmar.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Firmar Oficio Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 1"
                        label="Oficio aprobado" icon="fa-solid fa-file-powerpoint fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 2"
                        label="Oficio aprobado" icon="fa-solid fa-file-powerpoint fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio creado fue aprobado por los VB. y CCP. está listo para firmar'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio Autor Creado Estado 5 -->

                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 1"
                        label="Oficio aprobado" icon="fa-solid fa-signature fa-shake" style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio adjunto fue enviado para que lo firme el autor.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio No es Autor Adjunto Estado 5 -->

                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->
                      <SplitButtonVue v-if="slotProps.data.status == 5 && slotProps.data.idCaso == 2 && slotProps.data.adjuntado == 2"
                        label="Oficio Aprobado" icon="fa-solid fa-file-contract fa-shake"
                        style="--fa-animation-duration: 3s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado5 w-full"
                        v-tooltip.top="'El oficio creado fue enviado para que lo firme el autor.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- Firmar Oficio No es Autor Creado Estado 5 -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios para Firmar para NO Titulares -->

            <!-- Tabla de Oficios Firmados -->
            <TabPanelVue v-if="esTitular">
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirmadas">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmados"
                     class="titulosPestanasContador"
                    >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-square-check fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios firmados</span>
                  </div>
                </div>

              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmadosC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirmados"
                  stripedRows 
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirmados['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios firmados.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Firmado Oficio Adjunto Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.status == 6 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 1"
                        label="Oficio firmado" icon="fa-solid fa-check-to-slot fa-bounce"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'El oficio adjunto ha sido firmado, ahora puede enviarse.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Enviar oficio adjunto',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogMOficio(
                                'EnviarPDF',
                                slotProps.data
                              );
                            },
                          },
                        ]" />
                      <!-- Firmado Oficio Adjunto Estado 6 -->

                      <!-- Firmado Oficio Creado Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.status == 6 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 2"
                        label="Oficio firmado" icon="fa-solid fa-file-circle-check fa-bounce"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'El oficio creado ha sido firmado, ahora puede enviarse.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Enviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogMOficio(
                                'EnviarPDF',
                                slotProps.data
                              );
                            },
                          },
                        ]" />
                      <!-- Firmado Oficio Creado Estado 6 -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios Firmados -->

            <!-- Tabla de Oficios Firmados -->
            <TabPanelVue v-if="noesTitular">
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasFirmadas">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosFirmados"
                     class="titulosPestanasContador"
                     >
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-square-check fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios ya firmados</span>
                  </div>
                </div>

              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasFirmadosC" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersFirmados"
                  stripedRows 
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersFirmados['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios firmados.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columnsAlt" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Firmado Oficio Adjunto Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.status == 6 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 1"
                        label="Oficio firmado" icon="fa-solid fa-check-to-slot fa-bounce"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'El oficio adjunto ha sido firmado, ahora puede enviarse.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Enviar oficio adjunto',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogMOficio(
                                'EnviarPDF',
                                slotProps.data
                              );
                            },
                          },
                        ]" />
                      <!-- Firmado Oficio Adjunto Estado 6 -->

                      <!-- Firmado Oficio Creado Estado 6 -->
                      <SplitButtonVue v-if="slotProps.data.status == 6 && slotProps.data.idCaso == 0 && slotProps.data.adjuntado == 2"
                        label="Oficio firmado" icon="fa-solid fa-file-circle-check fa-bounce"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado6 w-full"
                        v-tooltip.top="'El oficio creado ha sido firmado, ahora puede enviarse.'" :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Enviar oficio creado',
                            icon: 'pi pi-send',
                            command: () => {
                              openDialogMOficio(
                                'EnviarPDF',
                                slotProps.data
                              );
                            },
                          },
                        ]" />
                      <!-- Firmado Oficio Creado Estado 6 -->

                    </template>
                  </ColumnVue>
                </DataTable>

              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios Firmados -->

            <!-- Tabla de Oficios Enviados -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasEnviadas">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosEnviados"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-paper-plane fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Oficios enviados</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichas" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersEnviadosRecepcion"
                  stripedRows 
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-2">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersEnviadosRecepcion['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue class="msgTablas">
                      <i class="fa-solid fa-triangle-exclamation fa-beat px-2" style="--fa-animation-duration: 1.5s">
                      </i>
                      No se encontraron oficios enviados.
                    </InlineMessageVue>

                  </template>

                  <ColumnVue v-for="col of columns" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- EnviadoR. Oficio Adjunto Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1"
                        label="Enviado a recepción" icon="fa-solid fa-paper-plane fa-flip"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado7 w-full"
                        v-tooltip.top="'El oficio adjunto fue enviado a recepción, ahora puedes visualizar tu acuse.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },


                        ]" />
                      <!-- EnviadoR. Oficio Adjunto Estado 7 -->

                      <!-- EnviadoR. Oficio Creado Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2"
                        label="Enviado a recepción" icon="fa-regular fa-paper-plane fa-flip"
                        style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitGeneracionEstado7 w-full"
                        v-tooltip.top="'El oficio creado fue enviado a recepción, ahora puedes visualizar tu acuse.'"
                        :model="[
                          {
                            label: 'Visualizar oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },

                        ]" />
                      <!-- EnviadoR. Oficio Creado Estado 7 -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de Oficios Enviados -->

          </TabViewVue>
        </div>
      </div>
      <!-- Tabs -->

    </div>
  </div>


  <dialogEnviarOficioVB v-if="hasRole('EnviarOficioVB')" />
  <dialogEnviarOficio v-if="hasRole('EnviarOficiosinVB')" />

  <!-- Ficha General -->
  <dialogCrearFicha v-if="hasRole('CrearFicha')" />
  <dialogModificarFicha v-if="hasRole('ModificarFicha')" />
  <dialogNotaOficio v-if="hasRole('NotaOficio')" />
  <dialogAnadirVB v-if="hasRole('AnadirVB')" />
  <dialogAnadirAnexos v-if="hasRole('AnadirAnexos')" />
  <dialogModificarAnexos v-if="hasRole('ModificarAnexos')" />
  <!-- Ficha General -->

  <!-- Oficios Creados -->
  <dialogCrearPDF v-if="hasRole('CrearPDF')" />
  <dialogFirmarPDF v-if="hasRole('FirmarPDF')" />
  <dialogVisualizarPDF v-if="hasRole('VisualizarPDF')" />
  <dialogVisualizarAcuse v-if="hasRole('VisualizarAcuse')" />
  <dialogModificarPDF v-if="hasRole('ModificarPDF')" />
  <!--  <dialogReenviarPDF v-if="hasRole('ReenviarPDF')" /> -->
  <!-- Oficios Creados -->

  <!-- Oficios Adjunto -->
  <dialogAdjuntarPDF v-if="hasRole('AdjuntarPDF')" />
  <dialogFirmarAdjuntoPDF v-if="hasRole('AdjuntoFirmadoPDF')" />
  <dialogModificadoAdjuntoPDF v-if="hasRole('ModificadoAdjuntoPDF')" />
  <!-- Oficios Adjunto -->

  <!-- Oficios Firmados -->
  <dialogEnviarOficiosCreado v-if="hasRole('EnviarPDF')" />
  <!-- Oficios Firmados -->
</template>

<script>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import DataTable from "primevue/datatable";
import useOficiosList from "../composables/useOficiosCreacion";
import useOpenDialogs from "../composables/useOpenDialogs";
import dialogCrearFichaTabla from "../components/oficiosLibres/dialogCrearFicha";
import dialogAyudaCreacionOficios from "../components/oficiosLibres/dialogAyudaCreacionOficios";
import dialogDescargarAnexo from "../composables/compDialogs/useDialogDescargarAnexo";
import useOficiosRecepcion from "../composables/useOficiosRecepcion";
import useDialogVByCPP from '@/modules/oficialia/composables/compDialogs/useDialogVByCCP';
import Swal from "sweetalert2";
import store from "@/store";
import {  useRoute } from "vue-router";


/* import { useRoute } from 'vue-router'; */


export default {
  components: {
    DataTable,
    dialogCrearFichaTabla,
    dialogAyudaCreacionOficios,

    //Ficha General
    dialogCrearFicha: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogCrearFicha.vue")
    ),
    dialogModificarFicha: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogModificarFicha.vue")
    ),
    dialogNotaOficio: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogNotaOficio.vue")
    ),
    dialogAnadirVB: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogAnadirVistosBuenos.vue")
    ),
    //Ficha General

    //Oficios Creados
    dialogCrearPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogCrearPDF.vue")
    ),
    dialogFirmarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogFirmarPDF.vue")
    ),
    dialogEnviarOficio: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogEnviarCreadoF.vue")
    ),
    dialogVisualizarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarPDF.vue")
    ),
    dialogVisualizarAcuse: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarAcuse.vue")
    ),
    dialogModificarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogModificarPDF.vue")
    ),

    /*  dialogReenviarPDF: defineAsyncComponent(() =>
       import("../components/oficiosLibres/oficiosCreados/dialogReenviarPDF.vue")
     ), */

    //Oficios Creados

    //Oficios Adjuntos
    dialogAdjuntarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosAdjuntos/dialogAdjuntarPDF.vue")
    ),
    dialogEnviarOficioVB: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogEnviarOficioVB.vue")
    ),
    dialogFirmarAdjuntoPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosAdjuntos/dialogFirmarAdjuntoPDF.vue")
    ),
    dialogModificadoAdjuntoPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosAdjuntos/dialogAdjuntoModificadoPDF.vue")
    ),
    //Oficios Adjuntos

    //Oficios Firmados
    dialogEnviarOficiosCreado: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogEnviarOficioCreado.vue")
    ),

    //Anexos
    dialogAnadirAnexos: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogAnadirAnexos.vue")
    ),
    dialogModificarAnexos: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogModificarAnexos.vue")
    ),

  },

  setup() {
    // const router = useRouter();
    const route = useRoute();
    const selectedTab = ref(route.params.tab);
 


    const {
      downloadAnexo,
      openDialogAnexoDescargar,
    } = dialogDescargarAnexo();

    const {
        getVoBoyCcp,
    } = useDialogVByCPP();

    const {

      getFichasFirmadasR,
      getFichasRecepcionPendientes,
      getFichasRecepcionAtencionA,
      getFichasRecepcionTurnadosDestinatario,
      getFichasRecepcionTurnadosUsuario,

    } = useOficiosRecepcion();

    const {
      openDialog,
      openDialogEnviarOficio,
      openDialogEnviarOficioVB,
      openDialogVoBo,
      openDialogMFicha,
      openDialogMOficio,
      openMsgVB,
      openDialogAnexar,
      openDialogAnexarModificar,
      hasRole,
      toggleDialog
    } = useOpenDialogs();

    const {
      columns,
      columnsAlt,
      filtersNuevos,
      filtersEnviados,
      filtersObservacion,
      filtersFirma,
      filtersFirmados,
      filtersEnviadosRecepcion,
      fichas,
      fichasFirmaC,
      fichasFirmadosC,
      getFichas,
      reenviarOficio,
      getFichasFirmaC,
      getFichasFirmadasC,

      
      contadorOficiosNuevos,
      contadorOficiosNuevosAlt,
      barraOficiosNuevos,

      contadorOficiosEspera,
      contadorOficiosEsperaAlt,
      barraOficiosEspera,

      contadorOficiosObservacion,
      contadorOficiosObservacionAlt,
      barraOficiosObservacion,

      contadorOficiosFirmar,
      contadorOficiosFirmarAlt,
      barraOficiosFirmar,

      contadorOficiosFirmar3,
      contadorOficiosFirmar3Alt,
      barraOficiosFirmar3,

      contadorOficiosFirmados,
      contadorOficiosFirmadosAlt,
      barraOficiosFirmados,

      contadorOficiosEnviados,
      contadorOficiosEnviadosAlt,
      barraOficiosEnviados,

    } = useOficiosList();



    const messages = ref([]);
    const count = ref(0);
    const addMessages = () => {
      messages.value = [
        {
          icon: 'pi pi-search',
          severity: 'info',
          content: 'Dynamic Info Message',
          id: count.value++
        },
      ]
    };

    const reenviarOficios = async (idOficio) => {
      Swal.fire({
        title: 'Advertencia',
        text: '¿Reenviar Oficio a Revision?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Enviar',
        confirmButtonText: 'Cancelar',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6'
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDismissed) {
          await reenviarOficio(idOficio);
          toggleDialog();
        } else if (result.isConfirmed) {
          Swal.close();
        }
      })
    };

    const getFichasCreacion = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Creados.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichas();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    const getFichasEspera = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios en Espera.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichas();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    const getFichasObservacion = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios con Observación.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichas();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    const getFichasFirma = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios para Firmar.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasFirmaC();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    const getFichasFirmadas = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Firmados.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasFirmadasC();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    const getFichasEnviadas = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Enviados.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichas();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }
      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();
    }

    //checar si es titular
    const esTitular = ref();
    const noesTitular = ref();

    const validarTitular = async () => {
      if (store.state.auth.user.titular == 1) {
        esTitular.value = true;
        noesTitular.value = false;
      } else {
        esTitular.value = false;
        noesTitular.value = true;
      }
    }



    onMounted(async () => {


      new Swal({
        title: "Cargando...",
        text: "Actualizando Información del Directorio de Creación de Oficios.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichas();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      
      await validarTitular();

      await getFichas();
      await getFichasFirmaC();
      await getFichasFirmadasC();

      await barraOficiosNuevos();
      await barraOficiosEspera();
      await barraOficiosObservacion();
      await barraOficiosFirmar();
      await barraOficiosFirmar3();
      await barraOficiosFirmados();
      await barraOficiosEnviados();

      await getFichasFirmadasR();
      await getFichasRecepcionPendientes();
      await getFichasRecepcionAtencionA();
      await getFichasRecepcionTurnadosDestinatario();
      await getFichasRecepcionTurnadosUsuario();

      await getVoBoyCcp();
    })

    return {
      columns,
      columnsAlt,
      filtersNuevos,
      filtersEnviados,
      filtersObservacion,
      filtersFirmados,
      filtersFirma,
      filtersEnviadosRecepcion,
      addMessages,
      messages,
      hasRole,
      openDialog,
      openDialogEnviarOficio,
      openDialogEnviarOficioVB,
      openDialogMFicha,
      openDialogMOficio,
      openDialogVoBo,
      openMsgVB,
      getFichasFirma,
      getFichasFirmadas,
      getFichasEnviadas,
      getFichasCreacion,
      getFichasEspera,
      getFichasObservacion,
      fichas,
      fichasFirmaC,
      fichasFirmadosC,
      dialogCrearFichaTabla,
      dialogAyudaCreacionOficios,
      reenviarOficios,
      esTitular,
      noesTitular,
      validarTitular,
      openDialogAnexar,
      openDialogAnexarModificar,
      openDialogAnexoDescargar,
      downloadAnexo,

      barraOficiosNuevos,
      contadorOficiosNuevos,
      contadorOficiosNuevosAlt,

      contadorOficiosEspera,
      contadorOficiosEsperaAlt,
      barraOficiosEspera,

      contadorOficiosObservacion,
      contadorOficiosObservacionAlt,
      barraOficiosObservacion,

      contadorOficiosFirmar,
      contadorOficiosFirmarAlt,
      barraOficiosFirmar,

      contadorOficiosFirmar3,
      contadorOficiosFirmar3Alt,
      barraOficiosFirmar3,

      contadorOficiosFirmados,
      contadorOficiosFirmadosAlt,
      barraOficiosFirmados,

      contadorOficiosEnviados,
      contadorOficiosEnviadosAlt,
      barraOficiosEnviados,

      getFichasFirmadasR,
      getFichasRecepcionPendientes,
      getFichasRecepcionAtencionA,
      getFichasRecepcionTurnadosDestinatario,
      getFichasRecepcionTurnadosUsuario,

      getVoBoyCcp,

      selectedTab,

    }
  }

}
</script>

<style lang="scss" scoped>

::v-deep(.tablaEstatus1){

  .p-overlay-badge{
    background-color: gray !important;
  }

  .p-badge{
    background-color: gray !important;
  }
}

::v-deep(.titulosPestanasContador){
  font-size: 9px;
  background-color: rgb(18, 95, 168);
  // background-color: #bec3c9;
}


::v-deep(.msgTablas) {
  font-size: 10px;
  color: #3d1ada;
  background-color: #e3e3e3;

  .p-inline-message-icon {
    display: none;
  }
}

.modalBackground {
  background-color: #f0f0f0;
}

.modalTitle {
  background-color: white;
}

.gobcolor1 {
  color: #ffff;
  background-color: #4a001f;
}

.gobcolor1:hover {
  color: #ffff;
  background-color: #80245f;
}

::v-deep(.primerPasoOficio) {
  color: red;
  background-color: red;

  .p-splitbutton {
    color: red;
    background-color: red;
  }
}

.primerPasoOficio {
  color: red;
  background-color: red;
}

::v-deep(.botonGobColor:hover) {
  background-color: #80245f;
  border-color: #80245f;
  transition: ease 1s;

  .p-button-icon {
    color: rgb(255, 255, 255);
  }

  .p-button-label {
    font-size: 15px;
  }
}

::v-deep(.botonAyuda) {
  .p-button-icon {
    font-size: 25px;
    transition: ease 1s;
  }
}

::v-deep(.botonAyuda:hover) {
  .p-button-icon {
    font-size: 25px;
    color: rgb(77, 77, 77);
    transition: ease 1s;
  }
}

//Split Button para los Estados Responsivos

@media only screen and (max-width: 600px) {

  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 75px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 15px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 15px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 10px;
    transition: ease 1s;

    .p-column-title {
      font-size: 12px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }


  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 13px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 13px;
    }
  }

  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #644597 !important;
      border-color: #644597 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 600px) {

  .tablaTitle {
    font-size: 18px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 85px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 18px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 18px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 13px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 15px;
    }
  }

  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #644597 !important;
      border-color: #644597 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 8px !important;
      height: 55px !important;
      max-height: 55px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 768px) {

  .tablaTitle {
    font-size: 14px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 95px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 20px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 20px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 13px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 15px;
    }
  }

  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 10px !important;
      height: 65px !important;
      max-height: 65px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 65px !important;
      max-height: 65px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 10px !important;
      height: 65px !important;
      max-height: 65px !important;

      background-color: #644597 !important;
      border-color: #644597 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 10px !important;
      height: 65px !important;
      max-height: 65px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 992px) {

  .tablaTitle {
    font-size: 16px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 35px;
    width: 135px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 21px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 21px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 13px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 15px;
    }
  }

  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }


  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 10px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1200px) {

  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 50px;
    width: 180px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 22px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 22px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 16px;
    transition: ease 1s;

    .p-column-title {
      font-size: 16px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 17px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 17px;
    }
  }


  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }

  }

  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 15px !important;
      height: 60px !important;
      max-height: 60px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 60px !important;
      max-height: 60px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 15px !important;
      height: 60px !important;
      max-height: 60px !important;

      background-color: #644597 !important;
      border-color: #644597 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 15px !important;
      height: 60px !important;
      max-height: 60px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 45px !important;
      max-height: 45px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}

@media only screen and (min-width: 1515px) {

  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 40px;
    width: 180px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 25px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonGobColorFicha) {
    background-color: #4a001f;
    // background-color: #8d949e;
    border-color: #4a001f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 17px;
    }
  }

  ::v-deep(.botonGobColorFicha:hover) {
    background-color: #80245f;
    // background-color: #8d949e;
    border-color: #80245f;
    // border-color: #8d949e;
    transition: ease 1s;

    .p-button-icon {
      color: rgb(255, 255, 255);
    }

    .p-button-label {
      font-size: 17px;
    }
  }

  ::v-deep(.splitGeneracionEstado1) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6d807f !important;
      border-color: #6d807f !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #333b3b !important;
      border-color: #333b3b !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado1CCP) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #2a4645 !important;
      border-color: #2a4645 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1CCP:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #172d2c !important;
      border-color: #172d2c !important;
      border-style: dotted;
      transition: ease 1s;
    }

  }

  ::v-deep(.splitGeneracionEstado1VB) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7F43DE !important;
      border-color: #7F43DE !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #3f00a3 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2VB) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #644597 !important;
      border-color: #644597 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado2CCP:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #403551 !important;
      border-color: #403551 !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado3) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #374BDB !important;
      border-color: #374BDB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado3:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #090996 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #BA2D20 !important;
      border-color: #BA2D20 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #741d15 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado4VB) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #751b13 !important;
      border-color: #751b13 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado4VB:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4a100b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado5) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #41A1DB !important;
      border-color: #41A1DB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #002e7e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado6) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #37DBC7 !important;
      border-color: #37DBC7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado6:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #096f94 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitGeneracionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #42DB77 !important;
      border-color: #42DB77 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitGeneracionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #007466 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

}


//Split Button para los Estados Responsivos
</style>
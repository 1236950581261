export default () => ({
  firmantes: null,
  dependenciesVBCCP: null,
  // officesVBCCP: null,
  fichas: null,
  fichasRecepcionPendientes: null,
  fichasRecepcionAtencionA: null,
  fichasRecepcionTurnados: null,
  fichasRecepcionArchivados: null,
  fichasRecepcionArchivadosAtencion: null,
  fichasRecepcionArchivadosTurnados: null,
  fichasRecepcionUsuarioTurnados: null,
  fichasRecepcionDestinatarioTurnados: null,
  fichasFirmarC: null,
  fichasFirmadasC: null,
  fichasFirmarR: null,
  fichasFirmadasR: null,
  dependencias: null,
  oficios: null,
  leyendas: null,
  titulares: null,
  funcionarios: null,
  turnados: null,
  comentariosVB: null,
});

<template>

  <ButtonVue 
    icon="fa-solid fa-circle-info fa-bounce"
    style="--fa-animation-iteration-count: 5; --fa-animation-duration: 2.5s;"
    v-tooltip.top="'Ayuda para la Creación de Oficios'"   
    class="p-button-rounded p-button-secondary p-button-text botonAyuda mt-2"
    @click="openModal" 
  />

  <DialogVue 
    header="Pasos para la creación de oficios" 
    v-model:visible="displayModal" 
    :modal="true" 
    :closable="true"
    class="surface-0 shadow-8 "
    :style="{ width: '70vw' }">

    <div class="grid">

    <TabViewVue
      class="tabview-custom tabsTablas" 
      ref="tabview4"
    >

      <!-- Con Visto Bueno -->
      <TabPanelVue>
        <template #header>
          <div class="grid titulosPestanas">
            <div class="col-12 text-center">
              <i 
              class="fa-solid fa-clipboard-check px-2"
              style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
              ></i>
              <span>Oficio con vistos buenos o CCP.</span>
            </div>
          </div>
        </template>

      <div class="grid">

        <!-- Paso 1 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
          </DividerVue>

          <ButtonVue
            label="Crear ficha"
            icon="fa-solid fa-square-plus fa-spin"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm botonGobColor mx-2 my-3" 
          />

          <hr/>
          
          <p class="textHelp">
            Crea una ficha para la oficio.
          </p>

        </div>
        <!-- Paso 1 -->

        <!-- Paso 2 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Elegir una opción"
            icon="fa-solid fa-question fa-beat"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado1 m-2 w-full" 
          />

          <ButtonVue
            label="Visto bueno añadido"
            icon="fa-solid fa-question fa-beat"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado1VB m-2 w-full" 
          />

          <ButtonVue
            label="C.C.P. añadido"
            icon="fa-solid fa-question fa-beat"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado1CCP m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            Modifica la ficha si es necesario, adjunta un oficio en PDF o crealo desde la Plataforma, si añadiste vistos buenos o con copia para, el botón se verá de otra forma.
          </p>

        </div>
        <!-- Paso 2 -->

        <!-- Paso 3 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio adjunto con visto bueno"
            icon="fa-solid fa-paperclip fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2VB m-2 w-full" 
          />

          <ButtonVue
            label="Oficio creado con visto bueno"
            icon="fa-solid fa-file-code fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2VB m-2 w-full" 
          />

          <ButtonVue
            label="Oficio adjunto con C.C.P"
            icon="fa-solid fa-calendar-check fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2CCP m-2 w-full" 
          />

          <ButtonVue
            label="Oficio creado con C.C.P."
            icon="fa-regular fa-calendar-check fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2CCP m-2 w-full" 
          />


          <hr/>
          
          <p class="textHelp">
            Asegurate de que la ficha y tu oficio contengan los datos correctos, modificalos si es necesario y envialos para sus vistos buenos.
          </p>

        </div>
        <!-- Paso 3 -->

        <!-- Paso 4 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="4" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Enviado a visto bueno"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 10s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado3 m-2 w-full" 
          />

          <ButtonVue
            label="Enviado a visto bueno"
            icon="fa-regular fa-paper-plane fa-flip"
            style="--fa-animation-duration: 10s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado3 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio fue enviado a los vistos buenos para su aprobación, si se aceptan todos, podrá firmarse, de lo contrario se tendrán que hacer las correcciones necesarias.
          </p>

        </div>
        <!-- Paso 4 -->

        <!-- Paso 4.5 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Opción:</span>
            <BadgeVue value="Se rechaza el oficio" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Revisar oficio"
            icon="fa-solid fa-paperclip fa-beat-fade"
            style="--fa-animation-duration: 1s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado4 m-2 w-full" 
          />

          <ButtonVue
            label="Revisar oficio"
            icon="fa-solid fa-file-circle-exclamation fa-beat-fade"
            style="--fa-animation-duration: 1s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado4 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio tiene observaciones, será necesario modificar ficha, archivo adjunto o el oficio, una vez hecho esto, reenviar al destinatario y vistos buenos nuevamente.
          </p>

        </div>
        <!-- Paso 4.5 -->

        <!-- Paso 5 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="5" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio aprobado"
            icon="fa-solid fa-signature fa-shake"
            style="--fa-animation-duration: 3s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado5 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio aprobado"
            icon="fa-solid fa-file-contract fa-shake"
            style="--fa-animation-duration: 3s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado5 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio fue aprobado por todos los vistos buenos, ahora puede proceder a firmarse con la firma electrónica.
          </p>

        </div>
        <!-- Paso 5 -->

        <!-- Paso 6 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="6" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-check-to-slot fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-file-circle-check fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio ha sido firmado y ahora deberás enviarlo a su destinatario.
          </p>

        </div>
        <!-- Paso 6 -->

        <!-- Paso 7 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="7" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio ya está firmado y ha sido enviado a la bandeja de recepción.
          </p>

        </div>
        <!-- Paso 7 -->

      </div>        
      
      </TabPanelVue>
      <!-- Con Visto Bueno -->

      <!-- Con Sin Visto Bueno -->
      <TabPanelVue>
        <template #header>
          <div class="grid titulosPestanas">
            <div class="col-12 text-center">
              <i 
              class="fa-solid fa-file-import px-2"
              style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
              ></i>
              <span>Oficio sin vistos buenos y CCP.</span>
            </div>
          </div>
            
        </template>

      <div class="grid">

        <!-- Paso 1 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
          </DividerVue>

          <ButtonVue
            label="Crear ficha"
            icon="fa-solid fa-square-plus fa-spin"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm botonGobColor mx-2 my-3" 
          />

          <hr/>
          
          <p class="textHelp">
            Crea una ficha para la oficio.
          </p>

        </div>
        <!-- Paso 1 -->

        <!-- Paso 2 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Elegir una opción"
            icon="fa-solid fa-question fa-beat"
            style="--fa-animation-duration: 1.5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado1 mx-2 my-4 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            Modifica la ficha si es necesario, adjunta un oficio en PDF o crealo desde la plataforma.
          </p>

        </div>
        <!-- Paso 2 -->

        <!-- Paso 3 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio adjunto"
            icon="fa-solid fa-paperclip fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio creado"
            icon="fa-solid fa-file-code fa-beat"
            style="--fa-animation-duration: 5s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado2 mx-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            Asegurate de que la ficha y tu oficio contengan los datos correctos, modificalos si es necesario para poder firmarlo.
          </p>

        </div>
        <!-- Paso 3 -->

        <!-- Paso 4 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="4" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio aprobado"
            icon="fa-solid fa-signature fa-shake"
            style="--fa-animation-duration: 3s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado5 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio aprobado"
            icon="fa-solid fa-file-contract fa-shake"
            style="--fa-animation-duration: 3s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado5 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio no contiene vistos buenos, ahora puedes proceder a firmarlo con tu firma electrónica
          </p>

        </div>
        <!-- Paso 4 -->
        

        <!-- Paso 5 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="5" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-check-to-slot fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-file-circle-check fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio ha sido firmado y ahora deberás enviarlo a su destinatario.
          </p>

        </div>
        <!-- Paso 5 -->

        <!-- Paso 6 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="6" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El Oficio ya está firmado y ha sido enviado a la bandeja de recepción.
          </p>

        </div>
        <!-- Paso 6 -->

      </div>        
      
      </TabPanelVue>
      <!-- Con Sin Visto Bueno -->

      <!-- Oficios para Contestar -->
      <TabPanelVue>
        <template #header>
          <div class="grid titulosPestanas">
            <div class="col-12 text-center">
              <i 
              class="fa-solid fa-file px-2"
              style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
              ></i>
              <span>Oficios contestados</span>
            </div>
          </div>
            
        </template>

      <div class="grid">

        <!-- Paso 1 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
          </DividerVue>

          <ButtonVue
            label="Oficio contestado"
            icon="fa-solid fa-check-to-slot fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado8 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio contestado"
            icon="fa-solid fa-file-circle-check fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado8 m-2 w-full" 
          />
          
          <hr/>
          
          <p class="textHelp">
            Se recibe un oficio que ha sido contestado
          </p>

        </div>
        <!-- Paso 1 -->

       

        <!-- Paso 5 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-check-to-slot fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <ButtonVue
            label="Oficio firmado"
            icon="fa-solid fa-file-circle-check fa-bounce"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado6 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio ha sido firmado y ahora deberás enviarlo a su destinatario.
          </p>

        </div>
        <!-- Paso 5 -->

        <!-- Paso 6 -->
        <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
          <DividerVue align="center">
            <span class="pr-2">Paso:</span>
            <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
          </DividerVue>
          
          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <ButtonVue
            label="Enviado a recepción"
            icon="fa-solid fa-paper-plane fa-flip"
            style="--fa-animation-duration: 15s;"
            class="p-button-sm p-button-secondary splitGeneracionEstado7 m-2 w-full" 
          />

          <hr/>
          
          <p class="textHelp">
            El oficio ya está firmado y ha sido enviado a la bandeja de recepción.
          </p>

        </div>
        <!-- Paso 6 -->

      </div>        
      
      </TabPanelVue>
      <!-- Oficios para Contestar -->

    </TabViewVue>

    </div>

  </DialogVue>

</template>

<script>
import { ref } from 'vue';
export default {

  setup(){
    const displayModal = ref(false);

    const openModal = () => {
        displayModal.value = true;
    };

    const closeModal = () => {
        displayModal.value = false;
    };

  

    return {
      displayModal,
      openModal,
      closeModal,
    }
  }

  
}

</script>

<style scoped lang="scss">


::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:13px;
    }
  }

.coloraux1 {
  background: rgb(209, 165, 19);
  font-weight: lighter;
}

::v-deep(.botonGobColor){ 
  background-color:#4a001f;
  border-color: #80245f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColor:hover){ 
  background-color:#80245f;
  border-color: #4a001f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar){ 
  background-color:#6d807f;
  border-color: #454b4b;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar:hover){ 
  background-color:#454b4b;
  border-color: #6d807f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.pasoNumber1){
  background: #6c757d;
}

::v-deep(.textHelp){
  font-size:12px;
}

//Split Button para los Estados

::v-deep(.splitGeneracionEstado1){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #6d807f !important;
    border-color: #6d807f !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado1:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #333b3b !important;
    border-color: #333b3b !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado1CCP){
  
  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;
  
  background-color: #2a4645 !important;
  border-color: #2a4645 !important;
  transition: ease 1s;


}

::v-deep(.splitGeneracionEstado1CCP:hover){

  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #172d2c !important;
  border-color: #172d2c !important;
  border-style: dotted;
  transition: ease 1s;

}

::v-deep(.splitGeneracionEstado2){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #7F43DE !important;
    border-color: #7F43DE !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado1VB) {
   
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #474747 !important;
      border-color: #474747 !important;
      transition: ease 1s;

    
  }

  ::v-deep(.splitGeneracionEstado1VB:hover) {
    
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #232323 !important;
      border-color: #232323 !important;
      border-style: dotted;
      transition: ease 1s;
    
  }

::v-deep(.splitGeneracionEstado2:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #3f00a3 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado2VB) {
  
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #491e8e !important;
      border-color: #491e8e !important;
      transition: ease 1s;

    
  }

  ::v-deep(.splitGeneracionEstado2VB:hover) {
   
      font-size: 13px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #220e42 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    
  }

  ::v-deep(.splitGeneracionEstado2CCP) {
  
  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #644597 !important;
  border-color: #644597 !important;
  transition: ease 1s;


}

::v-deep(.splitGeneracionEstado2CCP:hover) {

  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #403551 !important;
  border-color: #ffffff !important;
  border-style: dotted;
  transition: ease 1s;

}

::v-deep(.splitGeneracionEstado3){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #374BDB !important;
    border-color: #374BDB !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado3:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #090996 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
 
}

::v-deep(.splitGeneracionEstado4){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #BA2D20 !important;
    border-color: #BA2D20 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado4:hover){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #741d15 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado4VB){
  
  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;
  
  background-color: #751b13 !important;
  border-color: #751b13 !important;
  transition: ease 1s;


}

::v-deep(.splitGeneracionEstado4VB:hover){

  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #4a100b !important;
  border-color: #ffffff !important;
  border-style: dotted;
  transition: ease 1s;

}

::v-deep(.splitGeneracionEstado5){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #41A1DB !important;
    border-color: #41A1DB !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado5:hover){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #002e7e !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado6){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #37DBC7 !important;
    border-color: #37DBC7 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado6:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #096f94 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado7){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #42DB77 !important;
    border-color: #42DB77 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitGeneracionEstado7:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #007466 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitGeneracionEstado8){
  
  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;
  
  background-color: #406BD6 !important;
  border-color: #406BD6 !important;
  transition: ease 1s;


}

::v-deep(.splitGeneracionEstado8:hover){

  font-size: 13px !important;
  height: 35px !important;
  max-height: 35px !important;

  background-color: #274181 !important;
  border-color: #ffffff !important;
  border-style: dotted;
  transition: ease 1s;

}

@media only screen and (max-width: 600px){
  .titulosPestanas{
  height: 55px;
  width: 65px;
  transition: ease 1s;
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:10.5px;
      transition: ease 1s;
    }
}
}


</style>
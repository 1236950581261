import { ref } from "vue";
//import useOpenDialogs from "../useOpenDialogs";
import useDialogOficiosAdjuntos from "../compDialogs/useDialogOficiosAdjuntos"

const useDialogDescargarAnexo = () => {

    //console.log( useOpenDialogs());
    // const { oficioAnexoDescargar } = useOpenDialogs();
    //Para modificar los anexos de los oficios
    const oficioAnexoDescargar = ref([]);

    const { descargarArchivoAnexo } = useDialogOficiosAdjuntos();


    const downloadAnexo = async (datos) => {
      oficioAnexoDescargar.value = datos;
      // console.log(oficioAnexoDescargar);

      const tipo = {
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/pdf',
        'txt': 'text/plain',
        'zip': 'application/zip',
        'rar': 'application/vnd.rar',
        'tar': 'application/x-tar',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'epub': 'application/epub+zip',
        'azw': 'application/vnd.amazon.ebook',
        'png': 'image/png',
        'jpg': 'image/jpeg',
        'JPG': 'image/jpeg',
        'bmp': 'image/bmp',
        'jpeg': 'image/jpeg',
        'csv': 'text/csv',
        'tsv': 'text/tab-separated-values',
        'ppt': 'text/tab-separated-values',
        'pps': 'text/tab-separated-values',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      }


        let formData = new FormData();
        formData.append("idOficio", oficioAnexoDescargar.value.idOficio);
        formData.append("tipo", 1);
  
        const resp = await descargarArchivoAnexo(formData);

        const contentType = tipo[resp.extensionArchivo];
        const nombre = resp.nombreArchivo;
        const linkSource = `data:${contentType};base64,${resp.archivo}`;
        const link = document.createElement("a");
        link.href = linkSource;
        link.download = `${nombre}.${resp.extensionArchivo}`
        link.click();
  
    }

  return {
    downloadAnexo,
  }
};

export default useDialogDescargarAnexo;
import { computed, ref } from "vue";
import { useStore } from "vuex";


import store from "@/store";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useTitularesLista = () => {
  const storage = useStore();


  const columnasTitulares = ref([
    { field: "user", header: "Nombre del Titular" },
    { field: "oficina", header: "Oficina" },
    { field: "dependencia", header: "Dependencia" },
  ]);

  // const columnasHistorial = ref([
  //   { field: "nombre", header: "Número de oficio" },
  //   { field: "apellido_paterno", header: "Control I." },
  //   { field: "apellido_materno", header: "Control A." },
  //   { field: "nombre", header: "Institución que gira" },
  //   { field: "nombre", header: "En bandeja de" },
  //   { field: "nombre", header: "Quien Envia" },
  //   { field: "nombre", header: "Solicitante" },
  //   { field: "nombre", header: "Asunto" },
  //   { field: "nombre", header: "Prioridad" },
  //   { field: "nombre", header: "Fecha de Recepción" },
  //   { field: "nombre", header: "Evento" },
  //   { field: "nombre", header: "Fecha de Evento" },
  //   { field: "nombre", header: "Tipo de Oficio" },
  //   { field: "nombre", header: "Comentarios" },
  //   { field: "nombre", header: "# Adjuntos" },
  // ]);

  const filtersTitulares = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficina: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependencia: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },    
  });

  const getTitulares = async () => {
    const resp = await storage.dispatch(
      "admin/getTitulares",
      store.state.auth.user.idUsuario
    );
    return resp;
  }
  const getDashboard = async () => {
    const resp = await storage.dispatch(
      "admin/getDashboard",
      store.state.dashboard
    );
    return resp;
  }

  const getTitularesInfo = async () => {
    const resp = await storage.dispatch(
      "admin/getTitularesInfo",
      //store.state.auth.user.idUsuario
    );
    return resp;
  }



 

  return {

    getTitulares,
    titulareslista: computed(
      () => storage.getters["admin/getTitulares"]
    ),

    getTitularesInfo,
    titularesinfo: computed(
      () => storage.getters["admin/getTitulares"]
    ),
    getDashboard,
    dashboard: computed(
      () => storage.getters["admin/getDashboard"]
    ),

    columnasTitulares,
    filtersTitulares,

  };
};

export default useTitularesLista;

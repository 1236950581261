<template class="fondogob">
  <NavComponent />
  <FormComponent />
  <FooterComponent />
</template>

<script>

import { defineAsyncComponent, onMounted } from "vue";
export default {
  components: {
    NavComponent: defineAsyncComponent(() =>
      import("../components/NavComponent.vue")
    ),
    FormComponent: defineAsyncComponent(() =>
      import("../components/PwComponent.vue")
    ),
    FooterComponent: defineAsyncComponent(() =>
      import("../components/FooterComponent.vue")
    ),
  },

  setup() {

    onMounted(async () => {
  });

    return {

    };
  },
};
</script>

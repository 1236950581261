import { ref } from "vue";

const dialog = ref({
  role: [],
});

const projectId = ref(null);
const project_name = ref(null);
const projectFolio = ref(null);

//Baja Usuario
const bajaUsuarioR = ref([])
const altaUsuarioR = ref([])
const editUsuario = ref([])

const useOpenDialogs = () => {
  const toggleDialog = () => {
    dialog.value.role.pop();
    projectId.value = null;
    project_name.value = null;
    projectFolio.value = null;
  };

  const openDialog = (role = "", project, id, name) => {
    projectId.value = project;
    project_name.value = name;
    projectFolio.value = id;

    dialog.value.role.push({ type: role, isOpen: true });
  };

  const openDialogEditarUsuario = (role, datos) => {
    editUsuario.value = datos;
    dialog.value.role.push({ type: role, isOpen: true});
  }

  const openDialogBajaUsuario = (role, datos) => {
    bajaUsuarioR.value = datos;
    dialog.value.role.push({ type: role, isOpen: true});
  }

  const openDialogAltaUsuario = (role, datos) => {
    altaUsuarioR.value = datos;
    dialog.value.role.push({ type: role, isOpen: true});
  }

  const hasRole = (role = "") => {
    if (role === "") return false;
    const findRole = dialog.value.role.find((currentRole) =>
      currentRole.type === "" ? null : currentRole.type === role
    );
    if (findRole === undefined) return false;

    return findRole.type === role && findRole.isOpen === true ? true : false;
  };

  return {
    toggleDialog,
    openDialog,
    hasRole,
    projectId,
    project_name,
    projectFolio,

    openDialogBajaUsuario,
    bajaUsuarioR,
    openDialogAltaUsuario,
    altaUsuarioR,
    openDialogEditarUsuario,
    editUsuario,

  };
};

export default useOpenDialogs;

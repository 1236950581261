import { computed, ref } from "vue";
import { useStore } from "vuex";


import store from "@/store";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useUsuariosLista = () => {
  const storage = useStore();


  const columnasUsuarios = ref([
    { field: "nombre", header: "Nombre del Usuario" },
    { field: "apellido_paterno", header: "Apellido Paterno" },
    { field: "apellido_materno", header: "Apellido Materno" },
  ]);

  const columnasHistorial = ref([
    { field: "nombre", header: "Número de oficio" },
    { field: "apellido_paterno", header: "Control I." },
    { field: "apellido_materno", header: "Control A." },
    { field: "nombre", header: "Institución que gira" },
    { field: "nombre", header: "En bandeja de" },
    { field: "nombre", header: "Quien Envia" },
    { field: "nombre", header: "Solicitante" },
    { field: "nombre", header: "Asunto" },
    { field: "nombre", header: "Prioridad" },
    { field: "nombre", header: "Fecha de Recepción" },
    { field: "nombre", header: "Evento" },
    { field: "nombre", header: "Fecha de Evento" },
    { field: "nombre", header: "Tipo de Oficio" },
    { field: "nombre", header: "Comentarios" },
    { field: "nombre", header: "# Adjuntos" },
  ]);

  const filtersUsuarios = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    apellido_paterno: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    apellido_materno: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },    
  });

  const getUsuarios = async () => {
    const resp = await storage.dispatch(
      "usuarios/getUsuarios",
      store.state.auth.user.idUsuario
    );
    return resp;
  }

  const getUsuariosInfo = async () => {
    const resp = await storage.dispatch(
      "usuarios/getUsuariosInfo",
      //store.state.auth.user.idUsuario
    );
    return resp;
  }



 

  return {

    getUsuarios,
    usuarioslista: computed(
      () => storage.getters["usuarios/getUsuarios"]
    ),

    getUsuariosInfo,
    usuariosinfo: computed(
      () => storage.getters["usuarios/getUsuariosInfo"]
    ),

    columnasUsuarios,
    columnasHistorial,
    filtersUsuarios,

  };
};

export default useUsuariosLista;

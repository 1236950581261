export const getTitulares = (state) => {
  return state.titulareslista;
};
export const getDashboard = (state) => {
  return state.dashboard;
};

export const getTitularesInfo = (state) => {
  return state.titularesinfo;
};

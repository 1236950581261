import { ref } from "vue";
//import useDialogDownload from  "./compDialogs/useDialogDescargarAnexo";

// const {
//   downloadAnexo
// } = useDialogDownload()

const dialog = ref({
  role: [],
});

//Para Creacion de Oficios
const oficioidOficioRef = ref(null);
const oficioFechaRef = ref(null);
const oficioFechaFormatoRef = ref(null)

const oficioDependenciaFirmanteRef = ref(null);
const oficioSubDependenciaFirmanteRef = ref(null);
const oficioOficinaFirmanteRef = ref(null);
const oficioNumeroRef = ref(null);

const oficioDestinatarioRef = ref(null);
const oficioCargoDestinatarioRef = ref(null);
const oficioOficinaDestinatarioRef = ref(null);

const oficioAtencionARef = ref(null);
const oficioCargoAtencionARef = ref(null);

const oficioCargoFirmanteRef = ref(null);
const oficioFirmanteRef = ref(null);
//Para Creacion de Oficios


//Para Editar Ficha
const fichaM = ref([]);

//Para Modificar Oficios
const oficioM = ref([]);

//Para Añadir Vistos Buenos y Con Copia Para
const idOficioVoBo = ref([]);

//Para Enviar Oficio a Visto Bueno
const oficioEnviadoVB = ref([])

//Para Enviar el Oficio sin Visto Bueno
const oficioEnviar = ref([]);

//Para Constestar un Oficio del Remitente
const oficioContestarR = ref([]);

//Para Constestar un Oficio del Atencion
const oficioContestarA = ref([]);

//Para Ver mensajes de Vistos Buenos Rechazados
const comentariosdeVB = ref([]);

//Para Archivar Oficios
const oficioArchivado = ref([]);

//Para desarchivar Oficios
const oficioDesarchivado = ref([]);

//Para Turnar un oficio
const oficioTurnado = ref([]);

//Para anexar a los oficios
const oficioAnexo = ref([]);

//Para modificar los anexos de los oficios
const oficioAnexoModificar = ref([]);

//Para modificar los anexos de los oficios
// const oficioAnexoDescargar = ref([]);

const useOpenDialogs = () => {
  const toggleDialog = () => {
    dialog.value.role.pop();

    //Para Creacion de Oficio
    oficioidOficioRef.value = null;
    oficioFechaRef.value = null;
    oficioFechaFormatoRef.value = null;

    oficioDependenciaFirmanteRef.value = null;
    oficioSubDependenciaFirmanteRef.value = null;
    oficioOficinaFirmanteRef.value = null;
    oficioNumeroRef.value = null;

    oficioDestinatarioRef.value = null;
    oficioCargoDestinatarioRef.value = null;

    oficioAtencionARef.value = null;
    oficioCargoAtencionARef.value = null;

    oficioCargoFirmanteRef.value = null;
    oficioFirmanteRef.value = null;
    //Para Creacion de Oficio 

  };

  const openDialog = (role, idOficio = null, fechaCreacion = null, fechaOficio = null, dependenciaFirmante = null, subdependenciaFirmante = null, oficinaFirmante = null, numeroOficio = null, destinatario = null, cargoDestinatario = null,oficinaDestinatario = null, atencionA = null, cargoAtencionA = null, cargoFirmante = null, firmante = null ) => {

    oficioidOficioRef.value = idOficio
    oficioFechaRef.value = fechaCreacion
    oficioFechaFormatoRef.value = fechaOficio

    oficioDependenciaFirmanteRef.value = dependenciaFirmante
    oficioSubDependenciaFirmanteRef.value = subdependenciaFirmante
    oficioOficinaFirmanteRef.value = oficinaFirmante
    oficioNumeroRef.value = numeroOficio

    oficioDestinatarioRef.value = destinatario
    oficioCargoDestinatarioRef.value = cargoDestinatario
    oficioOficinaDestinatarioRef.value = oficinaDestinatario

    oficioAtencionARef.value = atencionA
    oficioCargoAtencionARef.value = cargoAtencionA

    oficioCargoFirmanteRef.value = cargoFirmante
    oficioFirmanteRef.value = firmante
  
    dialog.value.role.push({ type: role, isOpen: true });
  };

  //Para Contestar un Oficio de un Remitente
  const openDialogContestar = (role, datos) =>{
    oficioContestarR.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }

  //Para Contestar un Oficio de un Atencion
  const openDialogContestarA = (role, datos) =>{
    oficioContestarA.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }
  

  //Para Modificar Ficha
  const openDialogMFicha = (role, datos) => {
    fichaM.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }

  //Para Modificar Oficios
  const openDialogMOficio = (role, datos) => {
    oficioM.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }

  //Para Enviar Oficio a Visto Bueno
  const openDialogEnviarOficioVB = (role, datos) => {
    oficioEnviadoVB.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }

  //Para Enviar el Oficio sin Vistos Buenos
  const openDialogEnviarOficio = (role, datos) =>{
    oficioEnviar.value = datos;
    dialog.value.role.push({ type: role, isOpen: true });
  }

  //Para abrir los Dialog
  const openDialogVoBo = (role, datos) =>{
    idOficioVoBo.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  const openMsgVB = (role, datos) =>{
    comentariosdeVB.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  //Para Archivar Oficios
  const openDialogArchivar = (role, datos) => {
    oficioArchivado.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  // Para desarchivar Oficios
  const openDialogDesarchivar = (role, datos) => {
    oficioDesarchivado.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  // Para Turnar un Oficio
  const openDialogTurnar = (role, datos) => {
    oficioTurnado.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  // Para Anexar los oficios
  const openDialogAnexar = (role, datos) => {
    oficioAnexo.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  // Para modificar los anexos de los oficios
  const openDialogAnexarModificar = (role, datos) => {
    oficioAnexoModificar.value = datos;
    dialog.value.role.push ({ type: role, isOpen: true });
  }

  // const openDialogAnexoDescargar = (datos) => {
  //   oficioAnexoDescargar.value = datos;

  // }
  

  const hasRole = (role = "") => {
    if (role === "") return false;
    const findRole = dialog.value.role.find((currentRole) =>
      currentRole.type === "" ? null : currentRole.type === role
    );
    if (findRole === undefined) return false;

    return findRole.type === role && findRole.isOpen === true ? true : false;
  };



 
  return {
    toggleDialog,
    openDialog,
    openDialogContestar,
    hasRole,

    openDialogMOficio,

    oficioidOficioRef,
    oficioNumeroRef,
    oficioFechaRef,
    oficioFechaFormatoRef,

    oficioFirmanteRef,
    oficioDependenciaFirmanteRef,
    oficioSubDependenciaFirmanteRef,
    oficioOficinaFirmanteRef,
    oficioCargoFirmanteRef,
    
    oficioDestinatarioRef,
    oficioCargoDestinatarioRef,
    oficioOficinaDestinatarioRef,
    
    oficioAtencionARef,
    oficioCargoAtencionARef,

    //Para modificar ficha
    fichaM,
    oficioM,

    openDialogMFicha,

    //Para Enviar el Oficio a Vistos Buenos
    oficioEnviadoVB,
    openDialogEnviarOficioVB,

    //Para Enviar el Oficio sin Vistos Buenos
    oficioEnviar,
    openDialogEnviarOficio,

    //Para Agregar Vistos Buenos
    idOficioVoBo,
    openDialogVoBo,

    //Contestar Remitente
    oficioContestarR,

    //Contestar Atencion
    oficioContestarA,
    openDialogContestarA,

    //ComentariosVB
    comentariosdeVB,
    openMsgVB,

    //Archivar Oficios
    oficioArchivado,
    openDialogArchivar,

    //Desarchivar Oficios
    oficioDesarchivado,
    openDialogDesarchivar,

    //Para Turnar el oficio
    oficioTurnado,
    openDialogTurnar,

    //Para anexar el oficio
    openDialogAnexar,
    oficioAnexo,

    //Para modificar los anexos de los oficios
    openDialogAnexarModificar,
    oficioAnexoModificar,

    // openDialogAnexoDescargar,
    // oficioAnexoDescargar,

    
  };
};

export default useOpenDialogs;


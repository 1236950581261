<template>

  <ButtonVue 
    icon="fa-solid fa-circle-info fa-bounce"
    style="--fa-animation-iteration-count: 5; --fa-animation-duration: 2.5s;"
    v-tooltip.top="'Ayuda para la Recepción de Oficios'"   
    class="p-button-rounded p-button-secondary p-button-text botonAyuda"
    @click="openModal" 
  />

  <DialogVue 
    header="Pasos para la recepción de oficios" 
    v-model:visible="displayModal" 
    :modal="true" 
    :closable="true"
    class="surface-0 shadow-8"
    :breakpoints="{ '1560px': '75vw', '640px': '90vw' }" 
    :style="{ width: '50vw' }">

    <div class="grid">

    <!-- Tablas -->
    <div class="col-12">
      <TabViewVue
        class="tabview-custom tabsTablas" 
        ref="tabview4"
      >

<!-- Tabla de Pendientes -->
<TabPanelVue>
  <template #header>
    <div class="grid titulosPestanas">
      <div class="col-12 text-center">
        <i 
        class="fa-solid fa-bell fa-bounce px-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        <span>Oficios pendientes</span>
      </div>
    </div>
  </template>

  <div class="grid">

    <!-- Paso 1 -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Oficio recibido"
        icon="fa-solid fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio recibido"
        icon="fa-regular fa-paper-plane fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Se te ha enviando un oficio creado o adjunto, deberás responderlo creando otro oficio y adjuntando un oficio, o turnarlo si no es para ti.
      </p>

    </div>
    <!-- Paso 1 -->

    <!-- Paso 2 -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Oficio contestado"
        icon="fa-solid fa-check-to-slot fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado8 m-2 w-full" 
      />

      <ButtonVue
        label="Oficio contestado"
        icon="fa-solid fa-file-circle-check fa-bounce"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado8 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Una vez que creaste el oficio o lo adjuntaste, ahora deberás firmar el oficio con tu firma electrónica.
      </p>

    </div>
    <!-- Paso 2 -->

    <!-- Paso 3 -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Contestación firmada"
        icon="fa-solid fa-signature fa-shake"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado9 m-2 w-full" 
      />

      <ButtonVue
        label="Contestación firmada"
        icon="fa-solid fa-file-contract fa-shake"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado9 m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Ya que el oficio está firmado deberás enviarlo a su destinatario.
      </p>

    </div>
    <!-- Paso 3 -->

    <!-- Paso 4 -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso:</span>
        <BadgeVue value="4" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Contestación enviada"
        icon="fa-solid fa-paper-plane fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7C m-2 w-full" 
      />

      <ButtonVue
        label="Contestación enviada"
        icon="fa-regular fa-paper-plane fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado7C m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        El oficio ahora ha sido enviado, podrías esperar otra contestación o tal vez no dependiendo del asunto tratado.
      </p>

    </div>
    <!-- Paso 4 -->

    <!-- Paso Adicional -->
    <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
      <DividerVue align="center">
        <span class="pr-2">Paso Opcional:</span>
        <BadgeVue value="-" class="pasoNumber1"></BadgeVue>
      </DividerVue>
      
      <ButtonVue
        label="Oficio turnado nuevamente"
        icon="fa-solid fa-share-from-square fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
      />

      <ButtonVue
        label="Oficio turnado nuevamente"
        icon="fa-regular fa-share-from-square fa-flip"
        style="--fa-animation-duration: 5s;"
        class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
      />

      <hr/>
      
      <p class="textHelp">
        Si el oficio esta equivocado o va para otra área aparecera de esta manera cuando lo turnes a otro destinatario.
      </p>

    </div>
    <!-- Paso Adicional -->

  
  </div>

</TabPanelVue>
<!-- Tabla de Pendientes -->

<!-- Tabla de Atencion A -->
<TabPanelVue>
  <template #header>
    <div class="grid titulosPestanas">
      <div class="col-12 text-center">
        <i 
        class="fa-solid fa-tag fa-bounce px-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        <span>Oficios con atención</span>
      </div>
    </div>
  </template>

  <div class="grid">

<!-- Paso 1 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Oficio con atención a"
    icon="fa-solid fa-tag fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7A m-2 w-full" 
  />

  <ButtonVue
    label="Oficio con atención a"
    icon="fa-solid fa-tags fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7A m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Tienes un oficio creado o adjunto con atención, deberás responderlo creando otro oficio y adjuntando un oficio, o turnarlo si no es para ti.
  </p>

</div>
<!-- Paso 1 -->

<!-- Paso 2 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Atencion a. contestado"
    icon="fa-solid fa-calendar-check fa-beat"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado8A m-2 w-full" 
  />

  <ButtonVue
    label="Atencion a. contestado"
    icon="fa-regular fa-calendar-check fa-beat"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado8A m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Una vez que creaste el oficio o lo adjuntaste, ahora deberás firmar el oficio con tu firma electrónica.
  </p>

</div>
<!-- Paso 2 -->

<!-- Paso 3 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Atención a firmado"
    icon="fa-solid fa-signature fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado9A m-2 w-full" 
  />

  <ButtonVue
    label="Atención a firmado"
    icon="fa-solid fa-file-contract fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado9A m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Ya que el oficio está firmado deberás enviarlo a su destinatario.
  </p>

</div>
<!-- Paso 3 -->

<!-- Paso 4 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12  text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="4" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Atención a enviado"
    icon="fa-solid fa-paper-plane fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7A m-2 w-full" 
  />

  <ButtonVue
    label="Atención a enviado"
    icon="fa-regular fa-paper-plane fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7A m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    El oficio ahora ha sido enviado, podrías esperar otra contestación o tal vez no dependiendo del asunto tratado.
  </p>

</div>
<!-- Paso 4 -->


</div>

</TabPanelVue>
<!-- Tabla de Atencion A -->

<!-- Tabla de Turnados -->
<TabPanelVue>
  <template #header>
    <div class="grid titulosPestanas">
      <div class="col-12 text-center"> 
        <i 
        class="fa-solid fa-arrows-turn-right fa-bounce px-2"
        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"
        ></i>
        <span>Oficios turnados</span>
      </div>
    </div>
  </template>

<div class="grid">

<!-- Paso 1 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="1" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Oficio turnado recibido"
    icon="fa-solid fa-paperclip fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado10 m-2 w-full" 
  />

  <ButtonVue
    label="Oficio turnado recibido"
    icon="fa-solid fa-file-circle-exclamation fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado10 m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Se te ha turnado un oficio creado o adjunto, deberás responderlo creando otro oficio y adjuntando un oficio, o turnarlo si no es para ti.
  </p>

</div>
<!-- Paso 1 -->

<!-- Paso 2 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="2" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Oficio turnado contestado"
    icon="fa-solid fa-calendar-check fa-beat"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado11 m-2 w-full" 
  />

  <ButtonVue
    label="Oficio turnado contestado"
    icon="fa-regular fa-calendar-check fa-bounce"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado11 m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Una vez que creaste el oficio o lo adjuntaste, ahora deberás firmar el oficio con tu firma electrónica.
  </p>

</div>
<!-- Paso 2 -->

<!-- Paso 3 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="3" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Turnado firmado"
    icon="fa-solid fa-signature fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado12 m-2 w-full" 
  />

  <ButtonVue
    label="Turnado firmado"
    icon="fa-solid fa-file-contract fa-shake"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado12 m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Ya que el oficio está firmado deberás enviarlo a su destinatario.
  </p>

</div>
<!-- Paso 3 -->

<!-- Paso 4 -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso:</span>
    <BadgeVue value="4" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Turnado enviado"
    icon="fa-solid fa-paper-plane fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7T m-2 w-full" 
  />

  <ButtonVue
    label="Turnado enviado"
    icon="fa-regular fa-paper-plane fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado7T m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    El oficio ahora ha sido enviado, podrías esperar otra contestación o tal vez no dependiendo del asunto tratado.
  </p>

</div>
<!-- Paso 4 -->

<!-- Paso Adicional -->
<div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12 text-center">
  <DividerVue align="center">
    <span class="pr-2">Paso opcional:</span>
    <BadgeVue value="-" class="pasoNumber1"></BadgeVue>
  </DividerVue>
  
  <ButtonVue
    label="Oficio turnado nuevamente"
    icon="fa-solid fa-share-from-square fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
  />

  <ButtonVue
    label="Oficio turnado nuevamente"
    icon="fa-regular fa-share-from-square fa-flip"
    style="--fa-animation-duration: 5s;"
    class="p-button-sm p-button-secondary splitRecepcionEstado10T m-2 w-full" 
  />

  <hr/>
  
  <p class="textHelp">
    Si el oficio esta equivocado o va para otra área otra vez aparecera de esta manera cuando lo turnes a otro destinatario.
  </p>

</div>
<!-- Paso Adicional -->


</div>

</TabPanelVue>
<!-- Tabla de Turnados -->

      
      </TabViewVue>
    </div>
    <!-- Tablas -->
    </div>

  </DialogVue>

</template>

<script>
import { ref } from 'vue';
export default {

  setup(){
    const displayModal = ref(false);

    const openModal = () => {
        displayModal.value = true;
    };

    const closeModal = () => {
        displayModal.value = false;
    };

  

    return {
      displayModal,
      openModal,
      closeModal,
    }
  }

  
}

</script>

<style scoped lang="scss">

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:13px;
    }
  }
.coloraux1 {
  background: rgb(209, 165, 19);
  font-weight: lighter;
}

::v-deep(.botonGobColor){ 
  background-color:#4a001f;
  border-color: #80245f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColor:hover){ 
  background-color:#80245f;
  border-color: #4a001f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar){ 
  background-color:#6d807f;
  border-color: #454b4b;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.botonGobColorCerrar:hover){ 
  background-color:#454b4b;
  border-color: #6d807f;
  transition: ease 1s;
  .p-button-icon{
    color:rgb(255, 255, 255);
  }
  .p-button-label{
    font-size:15px;
  }
}

::v-deep(.pasoNumber1){
  background: #6c757d;
}

::v-deep(.textHelp){
  font-size:12px;
}

//Split Button para los Estados
::v-deep(.splitRecepcionEstado7){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #7E51F5 !important;
    border-color: #7E51F5 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #5b3caf!important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado8){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #406BD6 !important;
    border-color: #406BD6 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado8:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #274181 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado9){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #4ED1EB !important;
    border-color: #4ED1EB !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado9:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #388a9b !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado10){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #C2A9F5 !important;
    border-color: #C2A9F5 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado10:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #685a83 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado11){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #8D9ED6 !important;
    border-color: #8D9ED6 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado11:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #8D9ED6 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado7A){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #5BB7F5 !important;
    border-color: #5BB7F5 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7A:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #3d759b !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado8A){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #49D6C0 !important;
    border-color: #49D6C0 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado8A:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #31867a !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado9A){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #48c267 !important;
    border-color: #48c267 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado9A:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #37924e !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado10){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #7dafc9 !important;
    border-color: #7dafc9 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado10:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #7297aa !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado11){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #72aa9d !important;
    border-color: #72aa9d !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado11:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #65a193 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado12){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #6a9171 !important;
    border-color: #6a9171 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado12:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #68a172 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado13){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #9ac26c !important;
    border-color: #9ac26c !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado13:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #89ad60 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado13A){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #8ED63C !important;
    border-color: #8ED63C !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado13A:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #6ca031 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado13T){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #6BA32D !important;
    border-color: #6BA32D !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado13T:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #4a701e !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado15){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #6d6d6d !important;
    border-color: #6d6d6d  !important;
    transition: ease 1s;

  
}

::v-deep(.splitRecepcionEstado15:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #333333  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado3){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #505aaa !important;
    border-color: #505aaa !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado3:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #333a6d !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado4){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #BA2D20 !important;
    border-color: #BA2D20 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado4:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #741d15 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado5){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #369609 !important;
    border-color: #369609 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado5:hover){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #215e06 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado10T){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #6899b1 !important;
    border-color: #6899b1 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado10T:hover){

    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #395461 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado7C){
 
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #4A6CF0 !important;
    border-color: #4A6CF0 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7C:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #364da8 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado7A){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #4596F7!important;
    border-color: #4596F7 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7A:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #4596F7 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

::v-deep(.splitRecepcionEstado7T){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #4BB3E0 !important;
    border-color: #4BB3E0 !important;
    transition: ease 1s;
  
  
}

::v-deep(.splitRecepcionEstado7T:hover){
  
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #4BB3E0 !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  
}

@media only screen and (max-width: 600px){
  .titulosPestanas{
  height: 55px;
  width: 65px;
  transition: ease 1s;
}

::v-deep(.tabsTablas){
    .p-tabview-nav{
      font-size:10.5px;
      transition: ease 1s;
    }
}
}

</style>
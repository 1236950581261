<template>

  <div class="card p-3">
    <div class="grid">

      <!-- Titulo Oficios -->
      <div class="col-9 xl:col-3 lg:col-3 md:col-4 sm:col-7 p-2 flex justify-content-end">
        <h5 class="p-2 mt-2 tablaTitle">
          <i class="fa-solid fa-bell-concierge fa-shake px-2"
            style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
          Recepción de oficios
        </h5>
      </div>
      <!-- Titulo Oficios -->

      <div class="col-3 xl:col-2 lg:col-7 md:col-8 sm:col-5 flex justify-content-start">
        <dialogAyudaRecepcionOficios />
      </div>

      <div class="col-0 xl:col-7 lg:col-7 md:col-0 sm:col-0 p-2"></div>

      <!-- Tabs -->
      <div class="col-12 p-2">
        <div class="card">

          <TabViewVue class="tabview-custom tabsTablas" :activeIndex="parseInt(selectedTab)">

            <!-- Tabla de Pendientes -->
            <TabPanelVue>
              <template #header>

                <div class="grid titulosPestanas" @click="getFichasRecepcionPendientesTabla">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosPendientes"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-bell fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                    </i>
                    <span>Oficios pendientes</span>
                  </div>
                </div>


              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasRecepcionPendientes" 
                  responsiveLayout="scroll"
                  v-model:filters="filtersPendientes" 
                  stripedRows 
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" 
                  removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersPendientes['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue 
                    v-for="col of columnasPendientes" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" 
                    class="border-dashed border-300 tablaOficiosData"
                  >
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="width: 18rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7 w-full" label="Oficio recibido"
                        v-tooltip.top="'El remitente te ha enviado un oficio adjunto que debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },

                      
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 7 -->

                      <!-- Oficios Enviado a Recepcion (Creado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-regular fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7 w-full" label="Oficio recibido"
                        v-tooltip.top="'El remitente ha creado un oficio desde la plataforma y te lo ha enviado, debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                        ]" />
                      <!-- Oficios Enviado a Recepcion (Creado) Estado 7 -->

                      <!-- Oficios Contestados (Adjuntado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-check-to-slot fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full" label="Oficio en proceso de contestación"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios Contestados (Adjuntado) Estado 8 -->

                      <!-- Oficios Contestados (Creado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-file-circle-check fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full" label="Oficio en proceso de contestación"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        
                        ]" />
                      <!-- Oficios Contestados (Creado) Estado 8 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio recibido"
                        v-tooltip.top="'El remitente te ha enviado un oficio adjunto que debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },

                      
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                        ]" /> -->
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 10 -->

                      <!-- Oficios Enviado a Recepcion (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-regular fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio recibido"
                        v-tooltip.top="'El remitente ha creado un oficio desde la plataforma y te lo ha enviado, debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                        ]" /> -->
                      <!-- Oficios Enviado a Recepcion (Creado) Estado 10 -->

                      <!-- Oficios Contestados (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-check-to-slot fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio en proceso de contestación"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        ]" /> -->
                      <!-- Oficios Contestados (Adjuntado) Estado 10 -->

                      <!-- Oficios Contestados (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-file-circle-check fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio en proceso de contestación"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        
                        ]" /> -->
                      <!-- Oficios Contestados (Creado) Estado 10 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- #########################################################SIN ANEXO################################################################ -->

                      <!-- #########################################################CON ANEXO################################################################ -->
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7 w-full" label="Oficio recibido-"
                        v-tooltip.top="'El remitente te ha enviado un oficio adjunto que debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },

                      
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        ]" />
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 7 -->

                      <!-- Oficios Enviado a Recepcion (Creado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-regular fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7 w-full" label="Oficio recibido-"
                        v-tooltip.top="'El remitente ha creado un oficio desde la plataforma y te lo ha enviado, debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Enviado a Recepcion (Creado) Estado 7 -->

                      <!-- Oficios Contestados (Adjuntado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-check-to-slot fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full" label="Oficio en proceso de contestación-"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                      

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Contestados (Adjuntado) Estado 8 -->

                      <!-- Oficios Contestados (Creado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-file-circle-check fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8 w-full" label="Oficio en proceso de contestación-"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Contestados (Creado) Estado 8 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio recibido-"
                        v-tooltip.top="'El remitente te ha enviado un oficio adjunto que debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },

                      
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        ]" /> -->
                      <!-- Oficios Enviado a Recepcion (Adjuntado) Estado 10 -->

                      <!-- Oficios Enviado a Recepcion (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-regular fa-paper-plane fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio recibido-"
                        v-tooltip.top="'El remitente ha creado un oficio desde la plataforma y te lo ha enviado, debes contestar o turnar'"
                        :model="[
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Enviado a Recepcion (Creado) Estado 10 -->

                      <!-- Oficios Contestados (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-check-to-slot fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio en proceso de contestación-"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                      

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Contestados (Adjuntado) Estado 10 -->

                      <!-- Oficios Contestados (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-file-circle-check fa-bounce" style="--fa-animation-duration: 15s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10 w-full" label="Oficio en proceso de contestación-"
                        v-tooltip.top="'El oficio ha sido contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjunto',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestar(
                                'ContestarOficioRemitente',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Contestados (Creado) Estado 10 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- #########################################################CON ANEXO################################################################ -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de Pendientes -->

            <!-- Tabla de Atencion A -->
            <TabPanelVue>
              <template #header>
                <div class="grid titulosPestanas" @click="getFichasRecepcionAtencionATabla">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosAtencion"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-tag fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;">
                    </i>
                    <span>Oficios con atención</span>
                  </div>
                </div>
              </template>

              <div class="card p-1">
                <DataTable 
                  :value="fichasRecepcionAtencion" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersAtencionA"
                  stripedRows 
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersAtencionA['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue v-for="col of columnasAtencion" 
                    :field="col.field" 
                    :header="col.header" 
                    :key="col.field"
                    :sortable="true" 
                    :hidden="col.hidden" class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="width: 18rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- #########################################################SIN ANEXO################################################################ -->
                      <!-- Oficios Atencion A (Adjuntado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tag fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7A w-full"
                        label="Oficio con atención a" v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                    
                        ]" />
                      <!-- Oficios Atencion A (Adjuntado) Estado 7 -->

                      <!-- Oficios Atencion A (Creado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tags fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7A w-full" label="Oficio con atención"
                        v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios Atencion A (Creado) Estado 7 -->

                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8A w-full"
                        label="Atención a. contestado nuevamente"
                        v-tooltip.top="'El Oficio adjunto con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                       

                        ]" />
                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 8 -->

                      <!-- Oficios Atencion A Contestados (Creado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-regular fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8A w-full"
                        label="Atencion a. contestado nuevamente"
                        v-tooltip.top="'El Oficio creado con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          
                        
                        ]" />
                      <!-- Oficios Atencion A Contestados (Creado) Estado 8 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- Oficios Atencion A (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tag fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Oficio con atención a" v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                    
                        ]" /> -->
                      <!-- Oficios Atencion A (Adjuntado) Estado 10 -->

                      <!-- Oficios Atencion A (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tags fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full" label="Oficio con atención"
                        v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },
                        
                        ]" /> -->
                      <!-- Oficios Atencion A (Creado) Estado 10 -->

                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Atención a. contestado nuevamente"
                        v-tooltip.top="'El Oficio adjunto con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                       

                        ]" /> -->
                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 10 -->

                      <!-- Oficios Atencion A Contestados (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 0 && slotProps.data.respuesta == 1"
                        icon="fa-regular fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Atencion a. contestado nuevamente"
                        v-tooltip.top="'El Oficio creado con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          
                        
                        ]" /> -->
                      <!-- Oficios Atencion A Contestados (Creado) Estado 10 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- #########################################################SIN ANEXO################################################################ -->

                      <!-- #########################################################CON ANEXO################################################################ -->
                      <!-- Oficios Atencion A (Adjuntado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tag fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7A w-full"
                        label="Oficio con atención a-" v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Atencion A (Adjuntado) Estado 7 -->

                      <!-- Oficios Atencion A (Creado) Estado 7 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tags fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado7A w-full" label="Oficio con atención-"
                        v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Atencion A (Creado) Estado 7 -->

                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8A w-full"
                        label="Atención a. contestado nuevamente-"
                        v-tooltip.top="'El Oficio adjunto con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        } 
                        
                        ]" />
                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 8 -->

                      <!-- Oficios Atencion A Contestados (Creado) Estado 8 -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-regular fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado8A w-full"
                        label="Atencion a. contestado nuevamente-"
                        v-tooltip.top="'El Oficio creado con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" />
                      <!-- Oficios Atencion A Contestados (Creado) Estado 8 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- Oficios Atencion A (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tag fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Oficio con atención a-" v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Atencion A (Adjuntado) Estado 10 -->

                      <!-- Oficios Atencion A (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 0"
                        icon="fa-solid fa-tags fa-shake" style="--fa-animation-duration: 2.5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full" label="Oficio con atención-"
                        v-tooltip.top="'Oficio con atención.'" :model="[
                        
                          {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                          {
                            label: 'Turnar oficio',
                            icon: 'pi pi-arrow-right-arrow-left',
                            command: () => {
                              openDialogTurnar(
                                'TurnarOficio',
                                slotProps.data,
                              );
                            },
                          },

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Atencion A (Creado) Estado 10 -->

                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-solid fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Atención a. contestado nuevamente-"
                        v-tooltip.top="'El Oficio adjunto con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        } 
                        
                        ]" /> -->
                      <!-- Oficios Atencion A Contestados (Adjuntado) Estado 10 -->

                      <!-- Oficios Atencion A Contestados (Creado) Estado 10 -->
                      <!-- <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.anexo == 1 && slotProps.data.respuesta == 1"
                        icon="fa-regular fa-calendar-check fa-beat" style="--fa-animation-duration: 6s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado10T w-full"
                        label="Atencion a. contestado nuevamente-"
                        v-tooltip.top="'El Oficio creado con atención, fue contestado'" :model="[
                        
                        {
                            label: 'Ver oficio',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente con oficio adjunto',
                            icon: 'pi pi-paperclip',
                            command: () => {
                              openDialogMFicha(
                                'ContestarAdjuntoA',
                                slotProps.data,
                              );
                            },
                          },
                        
                          {
                            label: 'Contestar nuevamente creando un oficio',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                              openDialogContestarA(
                                'ContestarOficioAtencion',
                                slotProps.data
                              );
                            },
                          },

                          {
                            label: 'Archivar oficio',
                            icon: 'pi pi-reply',
                            command: () => {
                              openDialogArchivar(
                                'ArchivarOficio',
                                slotProps.data
                              );
                            },
                          },
                        
                        

                          {
                            label: 'Descargar Anexo',
                            icon: 'pi pi-download',
                            command: () => {
                              downloadAnexo(slotProps.data);
                          },
                        }
                        
                        ]" /> -->
                      <!-- Oficios Atencion A Contestados (Creado) Estado 10 -->

                      <!-- ####################################### Turnados ################################################## -->

                      <!-- #########################################################CON ANEXO################################################################ -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de Atencion A -->

            <!-- Tabla de CCP -->
            <TabPanelVue>
              <template #header>

                <div class="grid titulosPestanas" @click="getFichasCCP">
                  <div class="col-12 text-center">
                    <!-- Contador para oficios -->
                    <BadgeVue 
                     :value="contadorOficiosCCP"
                     class="titulosPestanasContador">
                    </BadgeVue>
                    <!-- Contador para oficios -->
                    <i class="fa-solid fa-check fa-bounce px-2"
                      style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                    <span>Con copia para</span>
                  </div>
                </div>

              </template>

              <div class="card p-1">
                <DataTable 
                  :value="VoBoyCcp" 
                  responsiveLayout="scroll" 
                  v-model:filters="filtersCCP" stripedRows
                  sortField="numeroOficio"
                  :sortOrder="-1"
                  :paginator="true" 
                  :rows="10"
                  paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 30]" removableSort 
                  dataKey="numeroOficio" 
                  class="p-datatable-sm"
                  style="width: 100%">

                  <template #header>
                    <div class="grid">

                      <div class="col-12 p-1">
                        <div class="flex justify-content-center">
                          <span class="p-input-icon-left m-2">
                            <i class="pi pi-search" />
                            <InputTextV v-model="filtersCCP['global'].value" placeholder="Buscar"
                              class="p-inputtext-sm" />
                          </span>
                        </div>
                      </div>

                    </div>

                  </template>

                  <template #empty>

                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      No se encontraron resultados.
                    </InlineMessageVue>

                  </template>

                  <template #loading>
                    <InlineMessageVue severity="info" class="border-dashed border-300">
                      Cargando....
                    </InlineMessageVue>
                  </template>

                  <ColumnVue v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                    :sortable="true" :hidden="col.hidden" class="border-dashed border-300 tablaOficiosData">
                  </ColumnVue>

                  <ColumnVue :exportable="false" style="width: 18rem" header="Opciones" class="tablaOficiosData">
                    <template #body="slotProps">

                      <!-- Oficios para CCP (Adjuntado)  -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.statusVoBo == 0 && slotProps.data.vobo_ccp == 1"
                        icon="fa-solid fa-square-check fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        label="Oficio de conocimiento" v-tooltip.top="'Oficio de conocimiento adjunto'" :model="[
                        
                          {
                            label: 'Ver oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios para CCP (Adjuntado)  -->

                      <!-- Oficios para CCP (Creado)  -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.statusVoBo == 0 && slotProps.data.vobo_ccp == 1"
                        icon="fa-regular fa-square-check fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        label="Oficio de conocimiento" v-tooltip.top="'Oficio de conocimiento creado'" :model="[
                        
                          {
                            label: 'Ver oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios para CCP (Creado)  -->

                       <!-- Oficios para CCP (Adjuntado)  -->
                       <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 1 && slotProps.data.statusVoBo == 0 && slotProps.data.vobo_ccp == 2"
                        icon="fa-solid fa-square-check fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        label="Oficio de conocimiento-" v-tooltip.top="'Oficio de conocimiento adjunto'" :model="[
                        
                          {
                            label: 'Ver oficio adjunto',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios para CCP (Adjuntado)  -->

                      <!-- Oficios para CCP (Creado)  -->
                      <SplitButtonVue v-if="slotProps.data.status == 7 && slotProps.data.adjuntado == 2 && slotProps.data.statusVoBo == 0 && slotProps.data.vobo_ccp == 2"
                        icon="fa-regular fa-square-check fa-bounce" style="--fa-animation-duration: 5s;"
                        class="p-button-sm p-button-secondary splitRecepcionEstado16 w-full"
                        label="Oficio de conocimiento-" v-tooltip.top="'Oficio de conocimiento creado'" :model="[
                        
                          {
                            label: 'Ver oficio creado',
                            icon: 'pi pi-eye',
                            command: () => {
                              openDialog(
                                'VisualizarPDF',
                                slotProps.data.idOficio,
                              );
                            },
                          },

                          {
                            label: 'Ver acuse del oficio',
                            icon: 'pi pi-verified',
                            command: () => {
                              openDialog(
                                'VisualizarAcuse',
                                slotProps.data.idOficio,
                                slotProps.data.id
                              );
                            },
                          },
                        
                        ]" />
                      <!-- Oficios para CCP (Creado)  -->

                    </template>
                  </ColumnVue>
                </DataTable>
              </div>

            </TabPanelVue>
            <!-- Tabla de CCP -->
         
          </TabViewVue>
        </div>
      </div>
      <!-- Tabs -->
      <Toast/>

    </div>
  </div>

  <!-- Oficios Pendientes -->
  <dialogVisualizarPDFBuzon v-if="hasRole('VisualizarPDF')" />
  <dialogContestarRemitente v-if="hasRole('ContestarOficioRemitente')" />
  <dialogContestarAtencion v-if="hasRole('ContestarOficioAtencion')" />
  <dialogContestarAdjunto v-if="hasRole('ContestarAdjunto')" />
  <dialogContestarAdjuntoA v-if="hasRole('ContestarAdjuntoA')" />


  <!-- Oficios Pendientes -->
  <dialogFirmarPDF v-if="hasRole('FirmarPDF')" />
  <dialogFirmarAdjuntoPDF v-if="hasRole('AdjuntoFirmadoPDF')" />
  <dialogArchivarOficio v-if="hasRole('ArchivarOficio')"/>
  <dialogTurnarOficio v-if="hasRole('TurnarOficio')"/>
  <dialogVisualizarAcuse v-if="hasRole('VisualizarAcuse')" />

  <dialogAnadirAnexos v-if="hasRole('AnadirAnexos')" />
  <dialogModificarAnexos v-if="hasRole('ModificarAnexos')" />

</template>

<script>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import DataTable from "primevue/datatable";
import useOficiosRecepcion from "../composables/useOficiosRecepcion";
import useDialogVByCPP from '../composables/compDialogs/useDialogVByCCP';
import dialogAyudaRecepcionOficios from "../components/oficiosLibres/dialogAyudaRecepcionOficios";
import dialogDescargarAnexo from "../composables/compDialogs/useDialogDescargarAnexo";
import Swal from "sweetalert2";
import {  useRoute } from "vue-router";

//Tabla Buzon
import useOpenDialogs from "../composables/useOpenDialogs";
//Tabla Buzon


export default {

  components: {
    //OficiosPendientes
    DataTable,
    dialogAyudaRecepcionOficios,
    dialogVisualizarPDFBuzon: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogVisualizarPDF.vue")
    ),
    dialogContestarRemitente: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosRecepcion/dialogContestarRemitente")
    ),
    dialogContestarAtencion: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosRecepcion/dialogContestarAtencion")
    ),
    dialogContestarAdjunto: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosRecepcion/dialogContestarAdjunto")
    ),
    dialogContestarAdjuntoA: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosRecepcion/dialogContestarAdjunto")
    ),
    dialogFirmarPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosCreados/dialogFirmarPDF.vue")
    ),
    dialogFirmarAdjuntoPDF: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosAdjuntos/dialogFirmarAdjuntoPDF.vue")
    ),
    dialogArchivarOficio: defineAsyncComponent(() =>
      import("../components/oficiosLibres/oficiosArchivados/dialogArchivarOficio.vue")
    ),
    dialogTurnarOficio: defineAsyncComponent(() =>
        import("../components/oficiosLibres/dialogTurnarOficio.vue")
    ),
    dialogVisualizarAcuse: defineAsyncComponent(() =>
    import("../components/oficiosLibres/oficiosCreados/dialogVisualizarAcuse.vue")
   ),
    //OficiosPendientes
    //Anexos
    dialogAnadirAnexos: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogAnadirAnexos.vue")
    ), 
    dialogModificarAnexos: defineAsyncComponent(() =>
      import("../components/oficiosLibres/dialogModificarAnexos.vue")
    ),
    
  },

  setup() {
    /* const route = useRoute(); */
    /* const { code } = route.query; */

    const route = useRoute();
    const selectedTab = ref(route.params.tab);



    const {
          downloadAnexo
        } = dialogDescargarAnexo();


    const {
      openDialog,
      openDialogContestar,
      openDialogContestarA,
      openDialogMFicha,
      openDialogArchivar,
      openDialogTurnar,
      openDialogAnexar,
      openDialogAnexarModificar,
      hasRole,
      toggleDialog
    } = useOpenDialogs();

    const {
      columns,
      filtersCCP,
      VoBoyCcp,
      getVoBoyCcp,

      contadorOficiosCCP,
      contadorOficiosCCPAlt,
      barraOficiosCCP,
    } = useDialogVByCPP();

    
    

    //Tabla de para consumir
    const {
      columnasPendientes,
      columnasAtencion,

      columnasArchivados,
      columnsVB,
      filtersPendientes,
      filtersAtencionA,
      filtersTurnados,
 
      filtersFirmados,
      filtersSalida,
      fichasRecepcionPendientes,
      fichasRecepcionAtencion,
      fichasRecepcionArchivados,

      fichasFirmadosR,

      getFichasFirmadasR,
      getFichasRecepcionPendientes,
      getFichasRecepcionAtencionA,
      getFichasRecepcionTurnadosDestinatario,
      getFichasRecepcionTurnadosUsuario,

      contadorOficiosPendientes,
      contadorOficiosPendientesAlt,
      barraOficiosPendientes,
      
      contadorOficiosAtencion,
      contadorOficiosAtencionAlt,
      barraOficiosAtencion,


    } = useOficiosRecepcion();

    const messages = ref([]);
    const count = ref(0);
    const addMessages = () => {
      messages.value = [
        {
          icon: 'pi pi-search',
          severity: 'info',
          content: 'Dynamic Info Message',
          id: count.value++
        },
      ]
    };

    const getFichasFirmadas = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Firmados.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasFirmadasR();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
    }

    const getFichasRecepcionPendientesTabla = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios Pendientes.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasRecepcionPendientes();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
    }

    const getFichasRecepcionAtencionATabla = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios de Atencion.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getFichasRecepcionAtencionA();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
    }

    const getFichasCCP = async () => {
      new Swal({
        title: "Cargando...",
        text: "Actualizando Información de Oficios de Conococimiento.",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      const { ok, message } = await getVoBoyCcp();
      if (!ok) Swal.fire("Error", message, "error");
      else {
        Swal.close();
      }

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
    }


    onMounted(async () => {

      new Swal({
        title: "Cargando...",
        text: "Actualizando Información del Directorio de Recepción de Oficios",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      await getVoBoyCcp();
      await getFichasRecepcionPendientes();
      await getFichasRecepcionAtencionA();
      await getFichasRecepcionTurnadosDestinatario();
      await getFichasRecepcionTurnadosUsuario();

      Swal.close();

      await barraOficiosPendientes();
      await barraOficiosAtencion();
      await barraOficiosCCP();
    })

    return {
      columnasPendientes,
      columnasAtencion,
  
      columnasArchivados,
      columnsVB,
      filtersPendientes,
      filtersAtencionA,
      filtersTurnados,
      filtersFirmados,

      filtersSalida,
      fichasRecepcionPendientes,
      fichasRecepcionArchivados,
      fichasRecepcionAtencion,

      fichasFirmadosR,
      addMessages,
      hasRole,
      openDialog,
      openDialogContestar,
      openDialogContestarA,
      openDialogArchivar,
      openDialogTurnar,
      openDialogAnexar,
      openDialogAnexarModificar,
      toggleDialog,
      dialogAyudaRecepcionOficios,
      openDialogMFicha,

      getFichasFirmadas,
      getFichasRecepcionPendientesTabla,
      getFichasRecepcionAtencionATabla,
      
      columns,
      filtersCCP,
      VoBoyCcp,
      getFichasCCP,
      downloadAnexo,

      contadorOficiosPendientes,
      contadorOficiosPendientesAlt,
      barraOficiosPendientes,

      contadorOficiosAtencion,
      contadorOficiosAtencionAlt,
      barraOficiosAtencion,

      contadorOficiosCCP,
      contadorOficiosCCPAlt,
      barraOficiosCCP,
      selectedTab
   
    }
  },

}
</script>

<style scoped lang="scss">

::v-deep(.titulosPestanasContador){
  font-size: 9px;
  background-color: rgb(18, 95, 168);
  // background-color: #bec3c9;
}

.coloraux1 {
  background: #4a001f;
  font-size: 13px;
  font-weight: lighter;
}

.coloraux2 {
  background: #919191;
  font-size: 12px;
  font-weight: lighter;
}

.buttonAbrirOficio {
  background-color: #4a001f;
}

::v-deep(.cardEntrada) {
  background-color: rgb(185, 185, 185);

  .p-card-title {
    font-size: 14px;
  }

  .p-card-content {
    font-size: 12px;
  }
}

.titleaccordion {
  font-size: 13px
}

::v-deep(.columnasOcultas) {
  font-size: 1px;
  padding-bottom: 1px;
  display: block;
  color: red;

  .p-datatable-thead {
    color: red;
    display: block;
  }
}

::v-deep(.botonAyuda) {
  .p-button-icon {
    font-size: 25px;
    transition: ease 1s;
  }
}

::v-deep(.botonAyuda:hover) {
  .p-button-icon {
    font-size: 25px;
    color: rgb(77, 77, 77);
    transition: ease 1s;
  }
}


@media only screen and (max-width: 600px) {

  .tablaTitle {
    font-size: 15px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 65px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 15px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 15px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 10px;
    transition: ease 1s;

    .p-column-title {
      font-size: 12px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 10.5px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 8px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 600px) {

  .tablaTitle {
    font-size: 18px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 85px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 18px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 18px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 768px) {

  .tablaTitle {
    font-size: 14px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 55px;
    width: 125px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 20px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 20px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 12px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 12px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}

@media only screen and (min-width: 992px) {

  .tablaTitle {
    font-size: 16px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 35px;
    width: 155px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 21px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 21px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 11px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 10px !important;
      height: 35px !important;
      max-height: 35px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 10px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 1200px) {


  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 50px;
    width: 180px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 22px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 22px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 16px;
    transition: ease 1s;

    .p-column-title {
      font-size: 16px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 14px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado5:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #215e06 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 50px !important;
      max-height: 50px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 15px !important;
    height: 50px !important;
    max-height: 50px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}


}

@media only screen and (min-width: 1515px) {

  .tablaTitle {
    font-size: 20px;
    transition: ease 1s;
  }

  .titulosPestanas {
    height: 40px;
    width: 175px;
    transition: ease 1s;
  }

  ::v-deep(.botonAyuda) {
    .p-button-icon {
      font-size: 25px;
      transition: ease 1s;
    }
  }

  ::v-deep(.botonAyuda:hover) {
    .p-button-icon {
      font-size: 25px;
      color: rgb(77, 77, 77);
      transition: ease 1s;
    }
  }

  ::v-deep(.tablaOficiosData) {
    font-size: 13px;
    transition: ease 1s;

    .p-column-title {
      font-size: 13px
    }
  }

  ::v-deep(.tabsTablas) {
    .p-tabview-nav {
      font-size: 13px;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7E51F5 !important;
      border-color: #7E51F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #5b3caf !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #406BD6 !important;
      border-color: #406BD6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #274181 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4ED1EB !important;
      border-color: #4ED1EB !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #388a9b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #C2A9F5 !important;
      border-color: #C2A9F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #685a83 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8D9ED6 !important;
      border-color: #8D9ED6 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8D9ED6 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #5BB7F5 !important;
      border-color: #5BB7F5 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #3d759b !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado8A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #49D6C0 !important;
      border-color: #49D6C0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado8A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #31867a !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado9A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #48c267 !important;
      border-color: #48c267 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado9A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #37924e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7dafc9 !important;
      border-color: #7dafc9 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #7297aa !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado11) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #72aa9d !important;
      border-color: #72aa9d !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado11:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #65a193 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado12) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6a9171 !important;
      border-color: #6a9171 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado12:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #68a172 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #9ac26c !important;
      border-color: #9ac26c !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #89ad60 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #8ED63C !important;
      border-color: #8ED63C !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6ca031 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado13T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6BA32D !important;
      border-color: #6BA32D !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado13T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4a701e !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado10T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #6899b1 !important;
      border-color: #6899b1 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado10T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #395461 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7C) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4A6CF0 !important;
      border-color: #4A6CF0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7C:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #364da8 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7A) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4596F7 !important;
      border-color: #4596F7 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7A:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4596F7 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado7T) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4BB3E0 !important;
      border-color: #4BB3E0 !important;
      transition: ease 1s;

    }
  }

  ::v-deep(.splitRecepcionEstado7T:hover) {
    .p-component {
      font-size: 15px !important;
      height: 40px !important;
      max-height: 40px !important;

      background-color: #4BB3E0 !important;
      border-color: #ffffff !important;
      border-style: dotted;
      transition: ease 1s;
    }
  }

  ::v-deep(.splitRecepcionEstado15){
  .p-component{
    font-size: 13px !important;
    height: 35px !important;
    max-height: 35px !important;
    
    background-color: #393939 !important;
    border-color: #393939  !important;
    transition: ease 1s;
  
  }
}

::v-deep(.splitRecepcionEstado15:hover){
  .p-component{
    font-size: 8px !important;
    height: 35px !important;
    max-height: 35px !important;
  
    background-color: #131313  !important;
    border-color: #ffffff !important;
    border-style: dotted;
    transition: ease 1s;
  }
}

}
</style>
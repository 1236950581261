import router from "@/router";
import axios from "axios";

const journalApi = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});


journalApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

journalApi.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.clear();
      router.push({ name: "login" });
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        localStorage.clear();
        router.push({ name: "login" });
        error.response.data.message = "Tu Sesión Expiró, Vuelve a Iniciar Sesión";
      } else if (status === 404) {
        error.response.data.message = "Recurso no encontrado";
      } else if (status >= 500) {
        error.response.data.message = "Error interno del servidor";
      }
    }
    return Promise.reject(error);
  }
);

export default journalApi;
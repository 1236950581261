import api from "@/api/laravelApi";


export const getUsuarios = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/admin/getUsersByOffice`);

    const { status, data, message  } = info;

    if (status != "success") return { ok: false, message };
    commit("setUsuarios", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const getUsuariosInfo = async ({ commit }) => {
  try {
    const { data: info } = await api.get(`/directorio/getUser`);

    const { status, data, message  } = info;

    if (status != "success") return { ok: false, message };
    commit("setUsuariosInfo", { data });
    return { ok: true, message };
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.data.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};


export const crearNuevoUsuario = async (_, form) => {
  try {
    const data = await api.post(`/admin/registraUsuario`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const crearNuevoTitular = async (_, form) => {
  try {
    const data = await api.post(`/auth/registroTitular`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const recuperarContrasenaTitular = async (_, form) => {
  try {
    const data = await api.post(`/users/recuperarPassword`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg, error:err.response.data };
  }
};

export const validarTokenTitular = async (_, form) => {
  try {
    const data = await api.post(`/users/validarCode`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const cambiarContrasenaTitular = async (_, form) => {
  try {
    const data = await api.post(`/users/validarPassword`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};

export const bajaNuevoUsuario = async (_, form) => {
  try {
    const data = await api.post(`/admin/bajaUsuario`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};



export const validarCurpUsuario = async (_, form) => {
  try {
    const data = await api.post(`/admin/validateCurpAdmin`, form);
    return data;
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};


export const cambiarAliasOficina = async (_, form) => {
  try {
    const { data } = await api.post(`/directorio/updateAlias`, form);
    const {message, status} = data;
 
    return {message, status};
  } catch (err) {
    let msg;
    if (err.response) {
      msg = err.response.message;
    } else if (err.request) {
      msg = err.request;
    } else {
      msg = err.message;
    }
    return { ok: false, message: msg };
  }
};



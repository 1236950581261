import { computed, ref } from "vue";
import { useStore } from "vuex";
import store from "@/store";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Swal from "sweetalert2";
const useProyectosConsejoList = () => {
  const storage = useStore();

  
    //Funciones para obtener los contadores de los status
    //################ Consume de fichas ######################
    const contadorOficiosNuevos = ref(0);
    const contadorOficiosNuevosAlt = ref(0);
    const barraOficiosNuevos = async () => {
      let arregloOficiosNuevos = store.state.oficialia.fichas
      //console.log(arregloOficiosNuevos);

      const oficiosNuevosAlt = limpiarArray => limpiarArray.status == 1 || limpiarArray.status == 2;
      const arrayLimpio = arregloOficiosNuevos.map(oficiosNuevosAlt);
      // console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      // console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosNuevos.value = siHayEstatus;
      contadorOficiosNuevosAlt.value = noHayEstatus;

    }

    const contadorOficiosEspera = ref(0);
    const contadorOficiosEsperaAlt = ref(0);
    const barraOficiosEspera = async () => {
      let arregloOficiosEspera = store.state.oficialia.fichas
      // console.log(arregloOficiosEspera);

      const oficiosEsperaAlt = limpiarArray => limpiarArray.status == 3;
      const arrayLimpio = arregloOficiosEspera.map(oficiosEsperaAlt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      // console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosEspera.value = siHayEstatus;
      contadorOficiosEsperaAlt.value = noHayEstatus;

    }

    const contadorOficiosObservacion = ref(0);
    const contadorOficiosObservacionAlt = ref(0);
    const barraOficiosObservacion = async () => {
      let arregloOficiosObservacion = store.state.oficialia.fichas
      // console.log(arregloOficiosEspera);

      const oficiosObservacionAlt = limpiarArray => limpiarArray.status == 4;
      const arrayLimpio = arregloOficiosObservacion.map(oficiosObservacionAlt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      // console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosObservacion.value = siHayEstatus;
      contadorOficiosObservacionAlt.value = noHayEstatus;

    }

    const contadorOficiosEnviados = ref(0);
    const contadorOficiosEnviadosAlt = ref(0);
    const barraOficiosEnviados = async () => {
      let arregloOficiosEnviados = store.state.oficialia.fichas
      //  console.log(arregloOficiosEnviados);

      const oficiosEnviadosAlt = limpiarArray => limpiarArray.status == 7;
      const arrayLimpio = arregloOficiosEnviados.map(oficiosEnviadosAlt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosEnviados.value = siHayEstatus;
      contadorOficiosEnviadosAlt.value = noHayEstatus;

    }
    //################ Consume de fichas ######################

    //################ Consume de fichasFirmaC ######################
    const contadorOficiosFirmar = ref(0);
    const contadorOficiosFirmarAlt = ref(0);
    const barraOficiosFirmar = async () => {

      let arregloOficiosFirmar = store.state.oficialia.fichasFirmarC
        // console.log(arregloOficiosFirmar);

      const oficiosFirmarAlt = limpiarArray => (limpiarArray.status == 5 && limpiarArray.idCaso == 0 ) || (limpiarArray.status == 5 && limpiarArray.idCaso == 2 );
      const arrayLimpio = arregloOficiosFirmar.map(oficiosFirmarAlt);
        //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmar.value = siHayEstatus;
      contadorOficiosFirmarAlt.value = noHayEstatus;

    }

    const contadorOficiosFirmar3 = ref(0);
    const contadorOficiosFirmar3Alt = ref(0);
    const barraOficiosFirmar3 = async () => {

      let arregloOficiosFirmar3 = store.state.oficialia.fichasFirmarC
        // console.log(arregloOficiosFirmar);

      const oficiosFirmar3Alt = limpiarArray => (limpiarArray.status == 5 && limpiarArray.idCaso == 0 ) || (limpiarArray.status == 5 && limpiarArray.idCaso == 2 );
      const arrayLimpio = arregloOficiosFirmar3.map(oficiosFirmar3Alt);
        //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      //  console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmar3.value = siHayEstatus;
      contadorOficiosFirmar3Alt.value = noHayEstatus;

    }
    //################ Consume de fichasFirmaC ######################

    //################ Consume de fichasFirmadosC ######################
    const contadorOficiosFirmados = ref(0);
    const contadorOficiosFirmadosAlt = ref(0);
    const barraOficiosFirmados = async () => {

      let arregloOficiosFirmados = store.state.oficialia.fichasFirmadasC
      // console.log(arregloOficiosFirmados);

      const oficiosFirmadosAlt = limpiarArray => limpiarArray.status == 6 && limpiarArray.idCaso == 0;
      const arrayLimpio = arregloOficiosFirmados.map(oficiosFirmadosAlt);
      //  console.log(arrayLimpio);

      let arrayLimpioAlt = Object.values(arrayLimpio);
      // console.log(arrayLimpioAlt);
      let siHayEstatus = 0;
      let noHayEstatus = 0;
      for(let i = 0; i < arrayLimpioAlt.length; i++){
    
        if( arrayLimpioAlt[i] == true){
         siHayEstatus = siHayEstatus + 1;
        }else{
         noHayEstatus = noHayEstatus + 1;
        }
      }

      contadorOficiosFirmados.value = siHayEstatus;
      contadorOficiosFirmadosAlt.value = noHayEstatus;

    }
    //################ Consume de fichasFirmadosC ######################


  const columns = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },
    { field: "firmante", header: "Firmante" },
    { field: "destinatario", header: "Destinatario"},
    { field: "dependenciaDestinatario", header: "Dependencia destinatario" },
    { field: "oficinaDestinatario", header: "Oficina destinatario" },
    { field: "vobo_ccp", header: "VistoBueno", hidden: true },
  ]);

  const columnsAlt = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },
    { field: "destinatario", header: "Destinatario"},
    { field: "dependenciaDestinatario", header: "Dependencia destinatario" },
    { field: "oficinaDestinatario", header: "Oficina destinatario" },
    { field: "idCaso", header: "idCaso", hidden: true },
  ]);

  const columnsVB = ref([
    { field: "numeroOficio", header: "Número oficio" },
    { field: "fechaCreacion", header: "Fecha creación" },
    { field: "asunto", header: "Asunto" },

    { field: "fechaOficio", header: "Fecha Formato", hidden: true },
    { field: "ciudadFecha", header: "Fecha Municipio", hidden: true },
    { field: "nota", header: "Nota", hidden: true },
    { field: "expediente", header: "Expediente", hidden: true },
    { field: "idLeyenda", header: "Leyenda", hidden: true },
    { field: "cuerpo", header: "Cuerpo", hidden: true },

    { field: "status", header: "id_estado", hidden: true },
    { field: "idOficio", header: "idOficio", hidden: true },
    { field: "adjuntado", header: "adjuntado", hidden: true },

    {
      field: "dependenciaFirmante",
      header: "dependenciaFirmante",
      hidden: true,
    },
    {
      field: "subdependenciaFirmante",
      header: "subdependenciaFirmante",
      hidden: true,
    },
    { field: "oficinaFirmante", header: "oficinaFirmante", hidden: true },
    { field: "firmante", header: "firmante", hidden: true },
    { field: "cargoFirmante", header: "cargoFirmante", hidden: true },

    { field: "dependenciaDestinatario", header: "Dependencia destinatario" },
    {
      field: "subdependenciaDestinatario",
      header: "subdependenciaDestinatario",
      hidden: true,
    },
    { field: "oficinaDestinatario", header: "Oficina destinatario" },
    { field: "destinatario", header: "destinatario", hidden: true },
    { field: "cargoDestinatario", header: "cargoDestinatario", hidden: true },

    {
      field: "DependenciaAtencionA",
      header: "DependenciaAtencionA",
      hidden: true,
    },
    {
      field: "SubdependenciaAtencionA",
      header: "SubdependenciaAtencionA",
      hidden: true,
    },
    { field: "OficinaAtencionA", header: "OficinaAtencionA", hidden: true },
    { field: "atencionA", header: "atencionA", hidden: true },
    { field: "cargoAtencionA", header: "cargoAtencionA", hidden: true },

    { field: "stringStatus", header: "Estado", hidden: true },
  ]);

  const filtersNuevos = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: 1, matchMode: FilterMatchMode.EQUALS },
        { value: 2, matchMode: FilterMatchMode.EQUALS },],
    },
    vobo_ccp: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

  });

  const filtersEnviados = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 3, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const filtersObservacion = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 4, matchMode: FilterMatchMode.EQUALS }],
    },

  });

  const filtersFirma = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idCaso: {
      operator: FilterOperator.OR,
      constraints: [{ value: 0, matchMode: FilterMatchMode.EQUALS },
                    { value: 2, matchMode: FilterMatchMode.EQUALS },
                    ],
    }


  });

  const filtersFirmados = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    idCaso: {
      operator: FilterOperator.OR,
      constraints: [{ value: 0, matchMode: FilterMatchMode.EQUALS }],
    }

  });

  const filtersEnviadosRecepcion = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numeroOficio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    fechaCreacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asunto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmante: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dependenciaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    oficinaDestinatario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: 7, matchMode: FilterMatchMode.EQUALS }],
    },

    
  });


  const getFichas = async () => {
    let formData = new FormData();
    formData.append("idUsuario", store.state.auth.user.idUsuario);
    const resp = await storage.dispatch("oficialia/getFichas", formData);
    return resp;
  };

  const getFichasFirmaC = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasFirmaC",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  const getFichasFirmadasC = async () => {
    const resp = await storage.dispatch(
      "oficialia/getFichasFirmadasC",
      store.state.auth.user.oficina_id
    );
    return resp;
  };

  //funcion para obtencion de token a traves de llaveMich
  const getLlaveToken = async (code) => {
    const usr = process.env.VUE_APP_USR;
    const psw = process.env.VUE_APP_PSW;
    const redirectUri = process.env.VUE_APP_REDIRECT_URL;
    const clientId = process.env.VUE_APP_CLIENT_ID;
    const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
    const oauth_url = process.env.VUE_APP_OAUTH_URL;

    const axios = require("axios");
    const data = JSON.stringify({
      grantType: "authorization_code",
      code: code,
      redirectUri: redirectUri,
      clientId: clientId,
      clientSecret: clientSecret,
    });

    const config = {
      method: "POST",
      url: oauth_url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      auth: {
        username: usr,
        password: psw,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        response
      })
      .catch(function (error) {
       error
      });
  };
  //funcion para obtener los datos del usr logeado con llavemich
  const getUserLlave = async (token) => {
    const uriUser = process.env.VUE_APP_USUARIO_URL;
    const usr = process.env.VUE_APP_USR;
    const psw = process.env.VUE_APP_PSW;

    let axios = require("axios");

    let config = {
      method: "GET",
      url: uriUser,
      headers: {
        accessToken: token,
      },
      auth: {
        username: usr,
        password: psw,
      },
    };

    axios(config)
      .then(function (response) {
        response
      })
      .catch(function (error) {
      error
      });
  };

  const reenviarOficio = async (idOficio) => {
    const { data: info } = await storage.dispatch(
      "oficialia/reenviarOficio",
      idOficio
    );
    const { status, message } = info;
    if (status === "success") {
      await getFichas();
      Swal.fire("Oficio Reenviado", message, "success");
    } else {
      Swal.fire("Error", message, "error");
    }
  };

  const cerrarSesion = async (form) => {
    const { data: info } = await store.dispatch("oficialia/cerrarsesion", form);
    const { status, message } = info;
    if (status === "success") {
      //await getVoBoyCcp();
      Swal.fire("Proceso Completado", message, "success");
    } else {
      //await getVoBoyCcp();
      Swal.fire("Error", message, "error");
    }
  };




  return {
    getFichas,
    getFichasFirmaC,
    getFichasFirmadasC,
    fichas: computed(() => storage.getters["oficialia/getFichas"]),
    fichasFirmaC: computed(() => storage.getters["oficialia/getFichasFirmaC"]),
    fichasFirmadosC: computed(
      () => store.getters["oficialia/getFichasFirmadasC"]
    ),
    filtersNuevos,
    filtersEnviados,
    filtersObservacion,
    filtersFirma,
    filtersFirmados,
    filtersEnviadosRecepcion,
    columns,
    columnsAlt,
    columnsVB,
    getLlaveToken,
    getUserLlave,
    reenviarOficio,
    cerrarSesion,

    barraOficiosNuevos,
    contadorOficiosNuevos,
    contadorOficiosNuevosAlt,

    contadorOficiosEspera,
    contadorOficiosEsperaAlt,
    barraOficiosEspera,

    contadorOficiosObservacion,
    contadorOficiosObservacionAlt,
    barraOficiosObservacion,

    contadorOficiosFirmar,
    contadorOficiosFirmarAlt,
    barraOficiosFirmar,

    contadorOficiosFirmar3,
    contadorOficiosFirmar3Alt,
    barraOficiosFirmar3,

    contadorOficiosFirmados,
    contadorOficiosFirmadosAlt,
    barraOficiosFirmados,

    contadorOficiosEnviados,
    contadorOficiosEnviadosAlt,
    barraOficiosEnviados,
  };
};

export default useProyectosConsejoList;

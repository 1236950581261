export default {
    component: () => import("@/layouts/MainLayout.vue"),
    props: () => {
      return {
        menuItems: [

          {
            label: "Usuarios",
            icon: "fa-solid fa-file-contract",
            name: "usuariosoficialia",
            to: "/usuarios",
            component: "@/modules/usuario/views/oficialiaUsuario",
          },
  
          {
            label: "Administrador",
            icon: "fa-solid fa-file-contract",
            name: "usuariosAdmin",
            to: "/administrador",
            component: "@/modules/admin/views/adminUsuarios",
          },
  
        ],
      };
    },
    children: [
      {
        path: "",
        component: () => import("@/modules/admin/views/adminUsuarios.vue"),
      },      
      
    ],
  };

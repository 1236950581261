import { createRouter, createWebHashHistory } from "vue-router";
import NoPageFound from "@/views/NoPageFoundView.vue";

import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";

import OficialiaUsuarios from "../modules/usuario/views/oficialiaUsuario.vue";
import OficialiaUsuariosRouter from "../modules/usuario/router/index";
import OficialiaPrincipal from "../modules/oficialia/views/oficialiaOficios";
import OficialiaVBCCP from "../modules/oficialia/views/oficialiaVBCCP";
import OficialiaFirmar from "../modules/oficialia/views/oficialiaFirmaOficios";
import OficialiaOficios from "../modules/oficialia/views/oficialiaRecepcionOficios.vue";
import OficialiaArchivados from "../modules/oficialia/views/oficialiaArchivados";
import OficialiaMenu from "../modules/oficialia/views/oficialiaMenu";
import OficialiaTurnados from "../modules/oficialia/views/oficialiaTurnados";
import OficialiaRouter from "../modules/oficialia/router/index";
import OficialiaPassword from "../modules/auth/views/ChangePwView";
import UsuariosAdmin from "../modules/admin/views/adminUsuarios.vue";
import AdminRouter from "../modules/admin/router/index"

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/modules/auth/views/LoginView.vue"),
  },
  {
    path: "/pw/:token",
    name: "pw",
    component: OficialiaPassword,
    props: route => ({ token: route.query.token })
  },
  {
    path: "/pw/",
    name: "pwalt",
    component: NoPageFound,
  },
  {
    path: "/oficialia",
    component: OficialiaMenu,
    beforeEnter: [isAuthenticatedGuard],
    ...OficialiaRouter,
    children: [
      {name:'menugeneral', path: "menugeneral", component: OficialiaMenu, },
      {name:'creacion', path: "creacion/:tab", component: OficialiaPrincipal, props: route => ({ tab: route.query.tab }) },
      {name:'vbccp', path: "vbccp/:tab", component: OficialiaVBCCP, props: route => ({ tab: route.query.tab }) },
      {name:'firmas', path: "firmas/:tab", component: OficialiaFirmar, props: route => ({ tab: route.query.tab }) },
      {name:'recepcion', path: "recepcion/:tab", component: OficialiaOficios, props: route => ({ tab: route.query.tab }) },
      {name:'turnados', path: "turnados/:tab", component: OficialiaTurnados, props: route => ({ tab: route.query.tab }) },
      {name:'archivados', path: "archivados/:tab", component: OficialiaArchivados, props: route => ({ tab: route.query.tab }) },
    ],
  },
  {
    path: "/usuarios",
    component: OficialiaUsuarios,
    beforeEnter: [isAuthenticatedGuard],
    ...OficialiaUsuariosRouter,
  },
  {
    path: "/administrador",
    component: UsuariosAdmin,
    beforeEnter: [isAuthenticatedGuard],
    ...AdminRouter,
  },
  {
    path: "/:pathMatch(.)",
    component: NoPageFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;